import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Grid, TextField} from '@material-ui/core';
import {Search} from '@material-ui/icons';
import {Pagination} from '@material-ui/lab';
import {useNavigate} from 'react-router-dom';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {useSearchParams} from '@front-libs/core';
import qs from 'qs';
import {useSetAtom} from 'jotai';

import {NewRegistrationDialog} from '../../Settings/Place/Dialogs/NewWardRegistrationDialog';
import {useSettingsContentTemplate} from '@templates/ContentLayout/InnerSidebarContentLayout';
import {TableLayout, useTableLayout} from '@modules/table_layout/hooks/useTableLayout';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {HospitalWard} from '@modules/hospital_places/types';
import {HospitalWardsParams, useFetchHospitalWards} from '@modules/hospital_places/api';
import {useDebounceState} from '@front-libs/core';
import {createHospitalWard} from '@modules/hospital_wards/api';
import {useStyles} from '@Apps/Settings/Place/styles';
import {Column} from '@molecules/Table/props';
import {Table} from '@molecules/Table';
import {NewEditationDialog} from '@Apps/Settings/Place/Dialogs/NewWardEditationDialog';
import {deleteHospitalWard, updateHospitalWard} from '@modules/hospital_wards/api';
import {openSnackBar} from '@molecules/SnackBar';
import {AlertDialog} from '@molecules/Dialogs/AlertDialog';
import {hospitalWardsAtom, tabAtom} from './state';

export const HospitalWards = () => {
  const queryParams = useSearchParams();
  const [searchName, setSearchName] = useDebounceState<string | null>((queryParams.name as string) || null, 500);
  const navigate = useNavigate();

  const setValue = useSetAtom(tabAtom);
  const setHospitalWardsValue = useSetAtom(hospitalWardsAtom);

  useEffect(() => {
    setSearchName((queryParams.name as string) || null);
  }, [queryParams.name, setSearchName]);

  const [orderKey, setOrderKey] = useState<string | null>(null);
  const [page, setPage] = useState<number>(Number(queryParams.page) || 1);
  const pageSize = 20;

  const params = useMemo(() => {
    const _p: HospitalWardsParams = {
      page: page - 1,
      perPage: 20,
      order: orderKey ?? undefined,
    };
    if (searchName) _p.name = searchName;
    return _p;
  }, [page, orderKey, searchName]);

  const {myInfo} = useMyInfo();
  const queryWard = useFetchHospitalWards(myInfo.hospitalHashId, params);

  const totalPageWards = useMemo(() => {
    return Math.ceil(queryWard.totalCount / pageSize);
  }, [queryWard.totalCount, pageSize]);

  const templateClasses = useSettingsContentTemplate();
  const classes = useStyles();

  const handleChangePage = useCallback(
    (event: React.ChangeEvent<unknown>, p: number) => {
      const resultQuery = qs.stringify({...queryParams, page: p}, {arrayFormat: 'brackets'});
      navigate({search: resultQuery});
      setPage(p);
    },
    [navigate, queryParams]
  );

  const handleClickRegistration = async () => {
    const newHospitalWard = await dialogHandler.open(NewRegistrationDialog, {});
    await createHospitalWard(myInfo.hospitalHashId, newHospitalWard);
    await queryWard.refetch();
  };

  const [tableLayout] = useTableLayout('hospitalWardList');

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onDeleteHospitalWard = useCallback(async () => {
    await queryWard.refetch();
  }, [queryWard]);

  const handleClickEdit = useCallback(
    async (e: React.MouseEvent, data: HospitalWard) => {
      e.stopPropagation();
      const editHospitalWard = await dialogHandler.open(NewEditationDialog, {
        ward: data,
      });

      try {
        await updateHospitalWard(myInfo.hospitalHashId, data.hashId, editHospitalWard);

        openSnackBar('大エリアを更新しました。');
        await queryWard.refetch();
      } catch (err) {
        openSnackBar('大エリアの更新に失敗しました。', 'left', 'bottom', 'error');
      }
    },
    [myInfo, queryWard]
  );

  const handleClickView = useCallback(
    (e: React.MouseEvent, data: HospitalWard) => {
      e.stopPropagation();

      setHospitalWardsValue([data.hashId]);
      setValue(1);
    },
    [setHospitalWardsValue, setValue]
  );

  const handleClickDelete = useCallback(
    async (e: React.MouseEvent, data: HospitalWard) => {
      e.stopPropagation();

      await dialogHandler.open(AlertDialog, {
        title: '大エリアを削除しますか？',
        content:
          'この大エリアに紐づく小エリアは削除されるのではなく、割り当て解除されます。大エリアを削除すると元に戻せません。',
        positiveButtonLabel: '大エリアを削除',
      });

      try {
        await deleteHospitalWard(myInfo.hospitalHashId, data.hashId);

        openSnackBar('大エリアを削除しました。');
        await queryWard.refetch();
      } catch (err) {
        openSnackBar('大エリアの削除に失敗しました。', 'left', 'bottom', 'error');
      }
    },
    [myInfo, queryWard]
  );

  const serializedTableColumn = useMemo(() => {
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    const tableColumn = Object.assign<Column<any>[], TableLayout[]>([], tableLayout?.currentLayout);

    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    return tableColumn.map<Column<any>>((item) => {
      if (item.field === 'name') {
        item.render = (hospitalWard) => {
          return (
            <Grid container className={classes.title} onClick={(e) => handleClickView(e, hospitalWard)}>
              {hospitalWard.name}
            </Grid>
          );
        };
      }
      item.noBodyWrap = true;
      return item;
    });
  }, [classes.title, handleClickView, tableLayout]);

  const handleOrderChange = (columnIndex: number, orderDirection: 'asc' | 'desc') => {
    if (columnIndex === -1) {
      setOrderKey(null);
    } else {
      setOrderKey(`${orderDirection === 'desc' ? '-' : ''}${String(serializedTableColumn[columnIndex].field)}`);
    }
  };

  return (
    <>
      <Grid container className={classes.tableContainer}>
        <div className={templateClasses.flex} />
        <Grid item sm={4} md={4} style={{marginRight: '32px'}}>
          <TextField
            label={'大エリアで検索'}
            variant={'outlined'}
            fullWidth
            size={'small'}
            InputProps={{
              endAdornment: <Search />,
            }}
            className={classes.searchText}
            // biome-ignore lint/suspicious/noExplicitAny: <explanation>
            onChange={(e: any) => {
              setSearchName(e.target.value);
            }}
          />
        </Grid>
        <Button variant={'contained'} color={'primary'} onClick={handleClickRegistration}>
          大エリアを作成
        </Button>
      </Grid>
      <Grid container className={classes.tableContainer}>
        <Table<HospitalWard>
          columns={serializedTableColumn}
          isLoading={queryWard.isLoading}
          data={queryWard.data}
          onOrderChange={handleOrderChange}
          // onRowClick={handleClickEdit}
          rowActions={useMemo(
            () => [
              {
                type: 'button',
                label: '編集',
                onClick: handleClickEdit,
              },
              {
                type: 'button',
                label: '小エリアを表示',
                onClick: handleClickView,
              },
              {
                type: 'button',
                label: '削除',
                onClick: handleClickDelete,
              },
            ],
            [handleClickEdit, handleClickView, handleClickDelete]
          )}
        />
      </Grid>
      <Grid container justifyContent={'center'}>
        <Pagination page={page} count={totalPageWards} color={'primary'} shape="rounded" onChange={handleChangePage} />
      </Grid>
    </>
  );
};
