import React, {useCallback, useState, useEffect} from 'react';
import {MobileFileListItem} from '@molecules/MobileFileListItem';
import {useFetchHospitalProductFiles} from '@modules/hospital_products/api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {FileIndex} from '@modules/files/types';
import {MobileUnderBox} from '@components/molecules/MobileUnderMenu';
import {ListItemType, MobileMenuList} from '@components/molecules/MobileUnderMenu/MobileMenuList';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import {dialogHandler} from '@components/molecules/Dialogs/DialogHandler';
import {FilePreviewDialog} from '@components/molecules/Dialogs/sp/FilePreviewDialog';
import {atom, useAtom, useSetAtom} from 'jotai';
import {openSnackBar} from '@components/molecules/SnackBar';
import {MobileNotFileText} from '@components/organisms/HospitalProductFileList/MobileNotFileText';
export const showHeaderFileUploadButtonAtom = atom(false);
export const refetchAtom = atom(false);

export type HospitalProductFileListProps = {
  hospitalProductHashId: string;
  search?: string;
  elementHeight: number;
};

type FileMenuButtonType = 'preview';

const menuButtonListItem: ListItemType<FileMenuButtonType>[] = [
  {type: 'preview', primary: 'プレビュー', icon: <OpenInNewIcon />},
];

export const HospitalProductFileList = (props: HospitalProductFileListProps) => {
  const {hospitalProductHashId, search, elementHeight} = props;
  const {myInfo} = useMyInfo();
  const [openMenu, setOpenMenu] = useState(false);
  const [selectedFile, setSelectedFile] = useState<FileIndex>();
  const setShowHeaderFileUploadButtonAtom = useSetAtom(showHeaderFileUploadButtonAtom);
  // ヘッダーからファイルアップロード完了を受け取る
  const [isRefetch, setIsRefetch] = useAtom(refetchAtom);
  const {
    data: files,
    refetch,
    // isLoading,
  } = useFetchHospitalProductFiles(myInfo.hospitalHashId, hospitalProductHashId, {
    fileName: search,
    order: '-createdAt',
    perPage: 50,
  });

  useEffect(() => {
    setShowHeaderFileUploadButtonAtom(true);
    return () => {
      setShowHeaderFileUploadButtonAtom(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isRefetch) {
      refetch();
      openSnackBar('ファイルをアップロードしました');
      setIsRefetch(false);
    }
  }, [isRefetch, refetch, setIsRefetch]);

  const closeListMenu = useCallback(() => {
    setSelectedFile(undefined);
    setOpenMenu(false);
  }, []);

  const onClickListMenu = useCallback(
    (fileHashId: string) => {
      setSelectedFile(files?.find((v) => v.hashId === fileHashId));
      setOpenMenu(true);
    },
    [files]
  );

  /** プレビューダイアログ表示 */
  const handleClickPreview = useCallback(
    async (data: FileIndex) => {
      await dialogHandler.open(FilePreviewDialog, {
        fileName: data.fileName,
        fileType: data.fileType,
        title: data.fileName,
        url: data.url,
      });
      closeListMenu();
    },
    [closeListMenu]
  );

  const onClickFileMenuList = useCallback(
    (buttonType: FileMenuButtonType) => {
      if (!selectedFile) return;
      switch (buttonType) {
        case 'preview':
          handleClickPreview(selectedFile);
          break;
        default:
          break;
      }
    },
    [handleClickPreview, selectedFile]
  );

  return (
    <>
      {openMenu && selectedFile && (
        <MobileUnderBox close={closeListMenu}>
          <MobileMenuList<FileMenuButtonType>
            buttonListItem={menuButtonListItem}
            onClick={onClickFileMenuList}
            fileName={selectedFile.fileName}
            ariaLabel="開く ファイル名変更 ファイル削除"
          />
        </MobileUnderBox>
      )}
      <div>
        {files?.length === 0 && <MobileNotFileText height={elementHeight} />}
        {files?.map((item, index) => (
          <MobileFileListItem
            key={`MobileFileListItem_${index}`}
            fileHashId={item.hashId}
            fileName={item.fileName}
            createdAt={item.createdAt}
            createdBy={item.createdBy}
            onClickMenu={onClickListMenu}
          />
        ))}
      </div>
    </>
  );
};
