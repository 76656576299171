import React, {useMemo} from 'react';
import {CardContent, makeStyles, Theme} from '@material-ui/core';
import {Card, RadioProps, CardActionArea} from '@material-ui/core';
import {HospitalWard} from '@modules/hospital_wards/types';
import clsx from 'clsx';
import {HospitalRoom} from '@modules/hospital_places/types';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    borderRadius: '25px',
    boxShadow: '0px 8px 0px 0px rgba(209, 216, 245, 1)',
    '&:active': {
      color: theme.palette.rent.light,
    },
    [theme.breakpoints.up('tabletH')]: {
      borderRadius: '45px',
    },
  },
  content: {
    maxWidth: '144px',
    height: '80px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '20px',
    fontWeight: 'bold',
    textAlign: 'center',
    margin: 'auto',
    color: theme.palette.rent.main,
    [theme.breakpoints.up('tabletH')]: {
      fontSize: '32px',
      maxWidth: '180px',
      height: '132px',
    },
    [theme.breakpoints.up('laptop')]: {
      fontSize: '40px',
      maxWidth: '200px',
      height: '140px',
    },
  },
  contentSmall: {
    maxWidth: '112px',
    height: '72px',
    fontSize: '16px',
    [theme.breakpoints.up('tabletH')]: {
      fontSize: '20px',
      maxWidth: '180px',
      height: '88px',
    },
  },
}));

type Props = {
  label: string;
  itemsCount: number;
  value: HospitalWard | HospitalRoom;
  onChange: (value: HospitalWard | HospitalRoom) => void;
} & Omit<RadioProps, 'onChange'>;

export const RentalCard: React.FC<Props> = ({label, itemsCount, value, onChange}) => {
  const classes = useStyles();
  const handleClick = () => {
    onChange(value);
  };
  const itemsOverEight = useMemo(() => {
    return itemsCount > 8;
  }, [itemsCount]);

  return (
    <Card className={classes.card} style={itemsOverEight ? {borderRadius: 25} : undefined}>
      <CardActionArea onClick={handleClick} data-testid={'rental-card'}>
        <CardContent className={clsx(classes.content, itemsOverEight ? classes.contentSmall : undefined)}>
          {label}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
