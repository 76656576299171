import React, {useState, useCallback, useEffect} from 'react';
import {Formik, useFormikContext} from 'formik';
import {createStyles, Grid, makeStyles, Theme} from '@material-ui/core';
import {useNavigate, useParams} from 'react-router-dom';
import {useBackPrevious, useOnlyOnce, withSuspense} from '@front-libs/core';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {Header} from '@Apps/Inspection/Header';
import {getDefaultSection, tabAtom} from '@Apps/Inspection/states';
import {ItemsForm} from '@Apps/Inspection/ItemsForm';
import {useResetAtom} from 'jotai/utils';
import {useAtomValue} from 'jotai';
import {ProductsForm} from '@Apps/Inspection/ProductsForm';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {MessageDialog} from '@molecules/Dialogs/MessageDialog';
import {updateInspection, getInspection, UpdateInspectionParam} from '@modules/inspections/api';
import {openSnackBar} from '@molecules/SnackBar';
import {useAsyncEffect} from '@front-libs/core';
import {usePrompt} from '@front-libs/core';
import {InnerLoading} from '@molecules/Loading';
import {InspectionIndex} from '@modules/inspections/types';
import {formValueToInspection, inspectionTableToSections} from './mapper';
import {FormValue} from './types';
import {FormSchema} from './validator';
import {InspectionStatus} from '@modules/inspections/enum';
import {HospitalProductsForm} from './HospitalProductsForm';
import {isNullish} from '@front-libs/helpers';
import {useChangeToDraft} from '@modules/inspection/hooks';
import {Preview} from './Preview';
import {InspectionScheduleConfigurationNavigateDialog} from '@organisms/InspectionScheduleConfigurationNavigateDialog';
import {InspectionUpdateAlertDialog} from '@organisms/InspectionUpdateAlertDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      alignContent: 'flex-start',
    },
    pageTitle: {
      fontWeight: 'bold',
      color: theme.palette.primary.dark,
    },
    flex: {
      flexGrow: 1,
    },
    mainContent: {
      height: '100%',
      width: '100%',
      padding: '24px 0px',
    },
  })
);

const AUTO_SAVE_PERIOD = 1000 * 5;

const useAutoSave = (
  hospitalHashId: string,
  hashId: string,
  status: InspectionStatus,
  period: number,
  autoSaveEnabled: boolean
) => {
  const context = useFormikContext<FormValue>();
  const {dirty, isValid, values, isSubmitting} = context;
  const [unsaved, setUnsaved] = useState(false);

  const navigate = useNavigate();
  usePrompt(!isSubmitting && unsaved, '点検表が未保存ですが、別のページへ移動しますか？');

  const saveAutoInspection = useCallback(async () => {
    try {
      const updateParam = formValueToInspection(values, status);
      await updateInspection(hospitalHashId, hashId, updateParam);
      openSnackBar('自動保存しました', 'left', 'bottom', 'info');
      setUnsaved(false);
    } catch (e) {
      console.error(e);
    }
  }, [hashId, hospitalHashId, status, values]);

  const saveDraftInspection = useCallback(async () => {
    // draftの場合のみdraftで保存可能
    if (status !== 'draft') {
      return;
    }
    try {
      const updateParam = formValueToInspection(values, status);
      await updateInspection(hospitalHashId, hashId, updateParam);
      openSnackBar('下書きを保存しました', 'left', 'bottom', 'info');

      navigate('/inspection_v2/tables');
    } catch (e) {
      console.error(e);
    }
  }, [status, values, hospitalHashId, hashId, navigate]);

  const stopPrompt = useCallback(() => setUnsaved(false), []);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;
    // only for draft status
    if (status === 'draft' && dirty && autoSaveEnabled) {
      setUnsaved(true);
      timer = setTimeout(saveAutoInspection, period);
    }
    return () => {
      if (timer !== null) {
        clearTimeout(timer);
      }
    };
  }, [values, dirty, isValid, status, saveAutoInspection, period, autoSaveEnabled]);

  return {setUnsaved, saveDraftInspection, stopPrompt};
};

type InspectionInnerProps = {
  hashId: string;
  inspection: InspectionIndex;
  isRelease: boolean;
  autoSaveEnabled: boolean;
  onClickChangeToDraft: () => void;
};

const InspectionInner: React.FC<InspectionInnerProps> = ({
  hashId,
  inspection,
  isRelease,
  autoSaveEnabled,
  onClickChangeToDraft,
}: InspectionInnerProps) => {
  const {myInfo} = useMyInfo();
  const classes = useStyles();
  const tab = useAtomValue(tabAtom);
  const {setUnsaved, saveDraftInspection, stopPrompt} = useAutoSave(
    myInfo.hospitalHashId,
    hashId,
    inspection.status,
    AUTO_SAVE_PERIOD,
    autoSaveEnabled
  );

  useEffect(() => {
    // NOTE:公開ボタンが押された時にunsavedをfalseに
    if (isRelease) setUnsaved(false);
  }, [isRelease, saveDraftInspection, setUnsaved]);

  return (
    <Grid container className={classes.root}>
      <Header
        inspectionType={inspection.type}
        inspectionStatus={inspection.status}
        onChangeToDraft={onClickChangeToDraft}
        onSaveDraft={() => {
          stopPrompt();
          saveDraftInspection();
        }}
      />
      <Grid className={classes.mainContent}>
        {tab === 'items' && <ItemsForm />}
        {tab === 'preview' && <Preview />}
        {tab === 'products' && <ProductsForm inspectionHashId={hashId} inspectionType={inspection.type} />}
        {tab === 'start_dates' && <HospitalProductsForm inspectionHashId={hashId} />}
      </Grid>
    </Grid>
  );
};

const _Inspection: React.FC = () => {
  const {hashId} = useParams();
  const {myInfo} = useMyInfo();
  const [inspection, setInspection] = useState<InspectionIndex | null>(null);
  const [initialValues, setInitialValues] = useState<FormValue | null>(null);
  const [isRelease, setIsRelease] = useState(false);

  const goBackToSetting = useBackPrevious('/inspection_v2/tables');
  const navigate = useNavigate();
  const [autoSaveEnabled, setAutoSaveEnabled] = useState(true);

  // タブを点検表に戻す
  useOnlyOnce(useResetAtom(tabAtom));

  useAsyncEffect(async () => {
    if (isNullish(hashId)) return;

    try {
      // eslint-disable-next-line no-shadow
      const inspection = await getInspection(myInfo.hospitalHashId, hashId);

      const sections =
        inspection.table && inspection.table.items.length > 0
          ? inspectionTableToSections(inspection.table)
          : [getDefaultSection()];

      setInitialValues({
        name: inspection.name,
        type: (inspection.type as 'periodic' | 'post_use') ?? 'periodic',
        sections: sections,
      });

      setInspection(inspection);
    } catch (e: unknown) {
      console.error(e);
    }
  }, []);

  const changeToDraft = useChangeToDraft();

  const handleClickChangeToDraft = useCallback(async () => {
    if (inspection === null || inspection.status !== 'available') {
      return;
    }

    try {
      const updated = await changeToDraft(myInfo.hospitalHashId, inspection);
      if (updated) {
        openSnackBar('下書きに戻しました');

        goBackToSetting();
      }
    } catch (e) {
      if (e) {
        console.error(e);
      }
    }
  }, [inspection, changeToDraft, myInfo.hospitalHashId, goBackToSetting]);

  const handleSubmit = useCallback(
    async (values: FormValue) => {
      const {hospitalHashId} = myInfo;
      if (!hashId) {
        openSnackBar('IDがセットされていません', 'center', 'top', 'error');
        return;
      }

      try {
        if (inspection?.status === 'available') {
          await dialogHandler.open(InspectionUpdateAlertDialog, {});
        } else if (inspection?.status === 'draft') {
          setIsRelease(true);
          setAutoSaveEnabled(false);
        }
      } catch (_e) {
        return;
      }

      let updateParam: UpdateInspectionParam;
      try {
        updateParam = formValueToInspection(values, 'available');
      } catch (e: unknown) {
        return openSnackBar(e + '', 'center', 'top', 'error');
      }

      try {
        await updateInspection(hospitalHashId, hashId, updateParam);
        if (inspection?.status === 'available') {
          openSnackBar('点検表を更新しました', 'center', 'top', 'success');
        } else if (inspection?.status === 'draft') {
          openSnackBar('点検表を公開しました', 'center', 'top', 'success');
        }

        // 点検(新) かつ 新規で公開した場合、機種設定画面への遷移を勧奨するダイアログを表示
        if (inspection?.status === 'draft') {
          try {
            await dialogHandler.open(InspectionScheduleConfigurationNavigateDialog, {});
            navigate('/inspection_v2/whole_product_plans', {replace: true});
          } catch (e) {
            // 「後で設定」ボタンがクリックされたらそのまま一覧に戻す
            goBackToSetting();
          }
        } else {
          goBackToSetting();
        }
      } catch (e: unknown) {
        console.error(e);
        openSnackBar(`点検表作成に失敗しました: ${e}`, 'center', 'top', 'error');
      }
    },
    [myInfo, hashId, inspection?.status, goBackToSetting]
  );

  if (!hashId || inspection === null || initialValues === null) {
    return <InnerLoading />;
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={FormSchema}
      validateOnMount={true}
      validateOnChange={true}
      validateOnBlur={true}
      validateOnSubmit={true}>
      <InspectionInner
        hashId={hashId}
        inspection={inspection}
        isRelease={isRelease}
        autoSaveEnabled={autoSaveEnabled}
        onClickChangeToDraft={handleClickChangeToDraft}
      />
    </Formik>
  );
};
// TODO:HIT-4529 Templateから参照あり
// src/Apps/InspectionTemplates/TemplateList/index.tsx
export const Inspection = withSuspense(_Inspection, null);
