import React, {useCallback} from 'react';
import {withSuspense} from '@front-libs/core';
import {useNavigate, useParams} from 'react-router-dom';
import {InspectionPreview} from '@organisms/InspectionPreview';
import {useInspectionEditorQuery} from './hooks';
import {Button, Grid, Paper, Typography, styled} from '@material-ui/core';
import {InspectionTypeMap} from '@modules/inspections/enum';
import {InspectionStatus} from '@molecules/InspectionStatus';
import {isNullish} from '@front-libs/helpers';

const Container = styled('div')({
  width: '100vw',
});

const HeaderContainer = styled(Paper)({
  padding: '20px 24px',
  backgroundColor: '#fff',
  borderBottom: '1px solid #F5F8FA',
  position: 'sticky',
  top: 0,
  zIndex: 1,
});

const PreviewContainer = styled('div')({
  padding: '24px 0px',
  width: '768px',
  margin: '0 auto',
});

const Text = styled(Typography)({
  fontSize: '14px',
});

export const InspectionEditor = withSuspense(() => {
  const navigate = useNavigate();
  const {hashId} = useParams();
  const {inspection} = useInspectionEditorQuery({
    inspectionHashId: hashId,
  });

  const handleClickEditInspection = useCallback(() => {
    // hashIdがない場合は遷移しない
    if (isNullish(hashId)) return;

    navigate(`/inspections/${hashId}`);
  }, [hashId, navigate]);

  const handleClose = useCallback(() => {
    navigate('/inspection_v2/tables');
  }, [navigate]);

  if (!inspection || !inspection.table) return null;

  return (
    <Container>
      <HeaderContainer elevation={2}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={2}>
            <Text display="block">点検タイプ：{InspectionTypeMap[inspection.type]?.label ?? ''}</Text>
            <Text display="block">
              ステータス：
              <InspectionStatus status={inspection.status as 'available' | 'draft'} />
            </Text>
          </Grid>
          <Grid item>
            <Text>{inspection.name}</Text>
          </Grid>
          <Grid item xs={2}>
            <Grid container justifyContent={'flex-end'} style={{gap: '16px'}}>
              <Grid item>
                <Button variant="outlined" color="primary" onClick={handleClose}>
                  閉じる
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" onClick={handleClickEditInspection}>
                  編集
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </HeaderContainer>
      <PreviewContainer>
        <InspectionPreview inspectionTable={inspection?.table} />
      </PreviewContainer>
    </Container>
  );
});
