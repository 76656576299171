import React, {memo, useCallback, useEffect, useRef} from 'react';
import {Grid} from '@material-ui/core';
import {FastField, FieldInputProps, FieldMetaProps, FormikProps, FormikErrors} from 'formik';
import {SectionView} from '@Apps/Inspection/fields/Section';
import {Section as SectionType} from '@Apps/Inspection/types';
import {Fields} from '@Apps/Inspection/Fields';
import {FormValue} from '@Apps/Inspection/types';
import {useFocused} from './hooks';
import {DEFAULT_SECTION_NAME, focusedItemAtom} from './states';
import {useAtomValue} from 'jotai';

type SectionProps = {
  index: number;
  onClickDuplicateSection: React.MouseEventHandler;
  onClickDeleteSection: React.MouseEventHandler;
};

type SectionField = FormValue['sections'][number];

type SectionFieldProps = {
  field: FieldInputProps<SectionField>;
  meta: FieldMetaProps<SectionField>;
  form: FormikProps<FormValue>;
};

type SectionInnerProps = SectionProps & SectionFieldProps;

const SectionInner: React.FC<SectionInnerProps> = (props) => {
  const {index, field, form, onClickDuplicateSection, onClickDeleteSection} = props;
  const {dirty} = form;
  const [focused, onFocused] = useFocused(index, null);
  const ref = useRef<HTMLDivElement | null>(null);
  const focusedItem = useAtomValue(focusedItemAtom);

  // セクションが描画された時（追加された時）にフォーカスする＆スクロールする
  useEffect(() => {
    // 初期描画時にフォーカスしてしまうのを防ぐ
    if (!dirty) return;

    if (focused && focusedItem.el === null) {
      ref.current?.scrollIntoView({behavior: 'smooth', block: 'center'});
      if (ref.current !== null) {
        onFocused(ref.current);
      }
    }
  }, [dirty, focused, focusedItem.el, onFocused]);

  const handleClickContainer = useCallback(
    (e) => {
      if (ref.current !== null) {
        onFocused(ref.current);
      }
    },
    [onFocused]
  );

  const handleChangeName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const key = `sections[${index}].name`;
      form.setFieldValue(key, e.target.value, true);
    },
    [form, index]
  );

  const handleFocusName = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      if (DEFAULT_SECTION_NAME === e.target.value) {
        const key = `sections[${index}].name`;
        form.setFieldValue(key, '');
        form.setFieldTouched(key, true);
      }
    },
    [form, index]
  );

  const handleBlurName = useCallback(
    (e: React.FocusEvent) => {
      form.setFieldTouched(`sections[${index}].name`, true);
    },
    [form, index]
  );

  const sectionError = form.errors.sections ? (form.errors.sections[index] as FormikErrors<SectionType>) : undefined;

  return (
    <Grid ref={ref} container onClick={handleClickContainer}>
      <SectionView
        name={field.value.name}
        error={sectionError?.name}
        focused={focused}
        onForcusName={handleFocusName}
        onChangeName={handleChangeName}
        onBlurName={handleBlurName}
        onClickDuplicate={onClickDuplicateSection}
        onClickDelete={onClickDeleteSection}
      />
      <Fields sectionIndex={index} />
    </Grid>
  );
};

export const Section: React.FC<SectionProps> = memo((props) => {
  const {index} = props;

  return (
    <FastField name={`sections[${index}]`}>
      {({field, form, meta}: SectionFieldProps) => <SectionInner {...props} field={field} meta={meta} form={form} />}
    </FastField>
  );
});
