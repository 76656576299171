import {deleteInspectionResults} from '@modules/inspection_results/api';
import {InspectionResultStatus} from '@modules/inspection_results/enum';
import {InspectionResultIndex} from '@modules/inspection_results/types';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {MessageDialog} from '@molecules/Dialogs/MessageDialog';
import {openSnackBar} from '@molecules/SnackBar';
import {useCallback} from 'react';

const FAKE_INSPECTION_ID = 'EgqdR7b5l3mzr6Y';

export const useDeleteInspectionResult = (hospitalHashId: string, data: InspectionResultIndex[]) => {
  const deleteInspectionResult = useCallback(
    async (hashIds: string[], status: InspectionResultStatus) => {
      if (hashIds.length === 0) return;
      let title = '';
      switch (status) {
        case 'completed':
          title = '点検結果';
          break;
        case 'uncompleted':
          title = '実施中の点検予定';
          break;
        case 'skipped':
          title = 'スキップした予定';
          break;
        default:
          title = '点検予定';
          break;
      }

      try {
        const content =
          hashIds.length === 1
            ? `この${title}を削除しようとしています。\n\nこのアクションは元に戻せません。`
            : `${hashIds.length}件の${title}を削除しようとしています。\n\nこのアクションは元に戻せません。`;
        await dialogHandler.open(MessageDialog, {
          title: `${title}を削除しますか？`,
          content: content,
          positiveButtonLabel: '削除',
          warning: true,
        });
      } catch (_e) {
        // キャンセルが押された場合
        return;
      }
      try {
        const res = await deleteInspectionResults(hospitalHashId, FAKE_INSPECTION_ID, {
          inspectionResultHashIds: hashIds,
        });
        const message = hashIds.length === 1 ? '削除しました' : `${res}件削除しました`;
        openSnackBar(message, 'left', 'bottom', 'info');
      } catch (e: unknown) {
        if (e) {
          console.error(e);
          openSnackBar(`エラーが発生しました: ${e}`, 'left', 'bottom', 'error');
        }
      }
    },
    [hospitalHashId]
  );

  return deleteInspectionResult;
};
