import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {TableViewLayout} from '@components/layouts/TableViewLayout';
import {useDebounceMemo} from '@front-libs/core';
import {getQueryOrderKey, isNullish} from '@front-libs/helpers';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {TableLayout, TableLayoutResult, useTableLayout} from '@modules/table_layout/hooks/useTableLayout';
import {Table} from '@molecules/Table';
import {Column} from '@molecules/Table/props';
import {Grid, styled} from '@mui/material';
import {useAtom} from 'jotai';
import {useNavigate} from 'react-router-dom';
import {InnerLoading} from '@components/molecules/Loading';
import {PaginationAndPerPage} from '@organisms/PaginationAndPerPage/PaginationAndPerPage';
import {InspectionTypeMap} from '@modules/inspections/enum';
import {formatPeriod} from '@Apps/Inspection/ProductsForm/columns/InspectionPeriodColumn';
import {WholeProductPlanListPageSizeAtom} from './state';
import {useGetHospitalNarrowCategoriesOptions, useGetHospitalRootCategoriesOptions} from '@modules/categories/api';
import {PopperSelectBoxProps, SelectOptionProps} from '@components/molecules/Buttons/PopperSelectBoxButton';
import {WholeProductPlansSortKey} from '@modules/inspections_products/type';
import {useFetchInspectionSettingsQuery} from '@modules/inspection_setting/hooks';
import {FetchInspectionSettingsParam, InspectionSetting, PeriodUnit} from '@modules/inspection_setting/types';
import {SearchAndFilter} from '@components/organisms/SearchAndFilter';

const StyledRoot = styled(Grid)({
  width: '100%',
  height: '100%',
  flexWrap: 'nowrap',
});
const StyledTableViewLayout = styled(TableViewLayout)({
  flex: 1,
  height: '100%',
});

type FilterParams = {
  name?: string;
  categoryHashIds?: string[];
};

/** APIのパラメータの生成 */
const generateFetchInspectionSettingsParams = (
  page: number,
  pageSize: number,
  orderKey: WholeProductPlansSortKey | null,
  searchName: string | null,
  filterParams: FilterParams | null
): FetchInspectionSettingsParam => {
  const params: FetchInspectionSettingsParam = {
    page: page - 1,
    perPage: pageSize,
    showDeleted: false,
  };

  if (orderKey) {
    params.order = getQueryOrderKey(orderKey) as WholeProductPlansSortKey;
  }

  if (searchName) {
    params.name = searchName;
  }

  if (filterParams?.categoryHashIds && filterParams.categoryHashIds) {
    // 小分類のIDは配列の最後
    params.categoryHashIds =
      filterParams.categoryHashIds.length === 1 ? filterParams.categoryHashIds[0] : filterParams.categoryHashIds.at(-1);
  }
  return params;
};

/** APIのプロパティ名からソートキーに変換するマッピングデータ */
const sortKeyMap: Record<string, string> = {
  wholeProductDisplayName: 'display_name',
  wholeProductName: 'name',
  wholeProductMakerName: 'maker_name',
  inspectionSettingName: 'plan_name',
  inspectionName: 'inspection_name',
  inspectionPeriod: 'periodic_inspection_period',
  inspectionType: 'inspection_type',
  wholeProductRootCategoryName: 'root_category',
  wholeProductNarrowCategoryName: 'narrow_category',
};

type WholeProductPlansListElement = Omit<
  InspectionSetting,
  'inspectionSettingHashId' | 'inspectionHashId' | 'inspectionPeriodUnit' | 'inspectionPeriod' | 'inspectionType'
> & {
  inspectionType: string;
  inspectionPeriod: string;
};

const useTableColumns = (tableLayout: TableLayoutResult | undefined) => {
  return useMemo(() => {
    if (!tableLayout) return;
    const tableColumn = Object.assign<Column<WholeProductPlansListElement>[], TableLayout[]>(
      [],
      tableLayout.currentLayout
    );
    return tableColumn;
  }, [tableLayout?.currentLayout]);
};

/**
 * 検査リスト
 * @returns
 */
export const WholeProductPlansList = () => {
  const navigate = useNavigate();
  const {myInfo} = useMyInfo();
  const [searchName, setSearchName] = useState('');
  const [pageSize, setPageSize] = useAtom(WholeProductPlanListPageSizeAtom);
  const [page, setPage] = useState<number>(1);
  const [tableLayout, setTableLayout] = useTableLayout(
    'WholeProductPlansList',
    useMemo(() => ({inspectionName: true}), [])
  );
  const [orderKey, setOrderKey] = useState<WholeProductPlansSortKey>('');
  const [filterParams, setFilterParams] = useState<FilterParams | null>(null);
  const [broadCategoryHashId, setBroadCategoryHashId] = useState<string>();
  const {rootCategoryOptions} = useGetHospitalRootCategoriesOptions(myInfo.hospitalHashId);
  const {narrowCategoryOptions} = useGetHospitalNarrowCategoriesOptions(myInfo.hospitalHashId, broadCategoryHashId);
  const [resetNarrowCategoryValue, setResetNarrowCategoryValue] = useState<boolean>(false);

  const params = useDebounceMemo<FetchInspectionSettingsParam>(
    () => {
      return generateFetchInspectionSettingsParams(page, pageSize, orderKey, searchName, filterParams);
    },
    [page, pageSize, orderKey, searchName, filterParams],
    300
  );

  const {data, isLoading, totalCount} = useFetchInspectionSettingsQuery(myInfo.hospitalHashId, params);

  /** テーブル表示用データ */
  const listData = useMemo<WholeProductPlansListElement[]>(() => {
    return (data ?? []).map((inspectionProduct) => ({
      inspectionSettingHashId: inspectionProduct.inspectionSettingHashId,
      wholeProductHashId: inspectionProduct.wholeProductHashId,
      wholeProductName: inspectionProduct.wholeProductName,
      wholeProductMakerName: inspectionProduct.wholeProductMakerName,
      inspectionSettingName: inspectionProduct.inspectionSettingName,
      wholeProductDisplayName: inspectionProduct.wholeProductDisplayName,
      inspectionType: InspectionTypeMap[inspectionProduct.inspectionType]?.label ?? '',
      inspectionName: inspectionProduct.inspectionName,
      inspectionPeriod: inspectionProduct.inspectionPeriodUnit
        ? formatPeriod(inspectionProduct.inspectionPeriod ?? 0, inspectionProduct.inspectionPeriodUnit)
        : '',
      wholeProductRootCategoryName: inspectionProduct.wholeProductRootCategoryName,
      wholeProductNarrowCategoryName: inspectionProduct.wholeProductNarrowCategoryName,
    }));
  }, [data]);

  /** テーブルの項目クリック時の処理 */
  const handleClickRow = useCallback(
    (event?: React.MouseEvent, rowData?: WholeProductPlansListElement) => {
      if (rowData && rowData?.wholeProductHashId) {
        navigate(`/inspection_v2/whole_product_plans/${rowData.wholeProductHashId}/edit/`);
      }
    },
    [navigate]
  );

  /** 並び順変更ハンドラ */
  const handleOrderChange = useCallback(
    (columnIndex: number, orderDirection: 'asc' | 'desc') => {
      if (columnIndex === -1) {
        setOrderKey('');
        return;
      }
      const newOrderKey = `${orderDirection === 'desc' ? '-' : ''}${
        sortKeyMap[tableLayout?.currentLayout[columnIndex].field]
      }` as WholeProductPlansSortKey;
      if (isNullish(newOrderKey)) return;
      setOrderKey(newOrderKey);
    },
    [tableLayout?.currentLayout]
  );

  /** 検索バー入力文字ハンドラ */
  const handleChangeSearchName = useCallback(
    (newSearchName: string) => {
      if (page !== 1) setPage(1);
      setSearchName(newSearchName);
    },
    [page]
  );

  /** 大分類・小分類切り替え共通処理 */
  const handleChangeCategory = useCallback(
    (selectValue?: SelectOptionProps) => {
      if (page !== 1) setPage(1);
      setFilterParams((prevFilterParams) => {
        if (selectValue == null) {
          const categoryHashIds = prevFilterParams?.categoryHashIds;
          if (categoryHashIds?.length === 1) {
            // 小分類リセット時
            return {
              ...prevFilterParams,
              categoryHashIds: undefined,
            };
          }

          return {
            ...prevFilterParams,
            categoryHashIds: categoryHashIds && categoryHashIds.length > 0 ? [categoryHashIds[0]] : undefined,
          };
        }

        const newCategoryHashIds = selectValue?.value
          ? Array.from(new Set([...(prevFilterParams?.categoryHashIds || []), selectValue.value]))
          : undefined;
        return {
          ...prevFilterParams,
          categoryHashIds: newCategoryHashIds,
        };
      });
    },
    [filterParams, page]
  );

  /** 大分類切り替え */
  const handleRootChangeCategory = useCallback(
    (selectValue?: SelectOptionProps) => {
      setBroadCategoryHashId(selectValue?.value);
      setResetNarrowCategoryValue(true);
      if (selectValue?.value) {
        handleChangeCategory(selectValue);
      } else {
        setFilterParams(null);
      }
    },
    [handleChangeCategory]
  );

  /** 小分類リセット処理 */
  useEffect(() => {
    if (resetNarrowCategoryValue) {
      setResetNarrowCategoryValue(false);
    }
  }, [resetNarrowCategoryValue]);

  /** テーブルレイアウト切り替え */
  const handleChangeTableLayout = useCallback(
    (newTableLayout: TableLayoutResult) => {
      setTableLayout(newTableLayout);
    },
    [setTableLayout]
  );

  /** 検索バー横のセレクトボックス設定 */
  const popperSelectBoxButtonProps: PopperSelectBoxProps[] = [
    {
      buttonLabel: '大分類',
      options: rootCategoryOptions,
      isMulti: false,
      searchable: false,
      onChange: handleRootChangeCategory,
    },
    {
      buttonLabel: '小分類',
      options: narrowCategoryOptions,
      isMulti: false,
      searchable: false,
      onChange: handleChangeCategory,
      resetValue: resetNarrowCategoryValue,
    },
  ];

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
    if (page !== 1) setPage(1);
  };

  const serializedTableColumn = useTableColumns(tableLayout);

  if (!serializedTableColumn) return <InnerLoading />;
  return (
    <StyledRoot container>
      <StyledTableViewLayout>
        <TableViewLayout.Header>
          <SearchAndFilter
            searchNameValue={searchName ?? undefined}
            tableLayout={tableLayout}
            searchLabel="機種名・型式で検索"
            onChangeSearchName={handleChangeSearchName}
            onChangeTableLayout={handleChangeTableLayout}
            popperSelectBoxButtonProps={popperSelectBoxButtonProps}
          />
        </TableViewLayout.Header>
        <TableViewLayout.Body>
          <Table<WholeProductPlansListElement>
            stickyHeader={true}
            showSelection={false}
            isLoading={isLoading}
            columns={serializedTableColumn}
            data={listData}
            onOrderChange={handleOrderChange}
            onRowClick={handleClickRow}
            tableSize="small"
          />
        </TableViewLayout.Body>
        <TableViewLayout.Footer container justifyContent="space-between">
          <PaginationAndPerPage
            totalCount={totalCount ?? 0}
            currentPage={page}
            pageSize={pageSize}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
          />
        </TableViewLayout.Footer>
      </StyledTableViewLayout>
    </StyledRoot>
  );
};
