import React from 'react';
import {Card, CardContent, Typography, makeStyles} from '@material-ui/core';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import {Draggable} from 'react-beautiful-dnd';

type StyleProps = {
  isDnDEnabled: boolean;
  itemCount?: number;
};

// MEMO: カードのサイズ調整必要
// const getWidth = (itemCount: number | undefined) => {
//   if (itemCount === undefined) {
//     return 'calc(100% / 6)';
//   } else if (itemCount < 4) {
//     return 'calc(100% / 3)';
//   } else if (itemCount > 3 && itemCount < 9) {
//     return 'calc(100% / 4)';
//   } else if (itemCount > 8 && itemCount < 11) {
//     return 'calc(100% / 5)';
//   } else {
//     return 'calc(100% / 6)';
//   }
// };

const useStyles = makeStyles((theme) => ({
  cardRoot: (props: StyleProps) => ({
    width: '92px', // getWidth(props.itemCount),
    minWidth: 88, // MEMO: tablet画面で修正必要
    height: 56,
    borderRadius: 10,
    margin: 8,
    background: '#FFF',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: props.isDnDEnabled ? 'grab' : 'pointer',
    '&:active': {
      cursor: props.isDnDEnabled ? 'grabbing' : 'pointer',
    },
  }),
  dragIndicator: {
    position: 'absolute',
    top: 2,
    left: 0,
  },
  centeredText: {
    textAlign: 'center',
    color: theme.palette.rent.main,
    fontFamily: 'Roboto',
    fontSize: 14,
    lineHeight: '15px',
    fontWeight: 700,
  },
}));

type RentalDnDCardProps = {
  title: string;
  index: number;
  isDnDEnabled?: boolean;
  itemCount?: number;
};

export const RentalDnDCard: React.FC<RentalDnDCardProps> = ({title, index, itemCount, isDnDEnabled = true}) => {
  const classes = useStyles({isDnDEnabled, itemCount});

  return (
    <Draggable draggableId={title} index={index} isDragDisabled={!isDnDEnabled}>
      {(provided) => (
        <Card
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          className={classes.cardRoot}>
          {isDnDEnabled && <DragIndicatorIcon className={classes.dragIndicator} color="action" fontSize="small" />}
          <CardContent style={{padding: '2px 14px 0px'}}>
            <Typography variant="h6" component="div" className={classes.centeredText}>
              {title}
            </Typography>
          </CardContent>
        </Card>
      )}
    </Draggable>
  );
};
