import React, {useCallback} from 'react';
import {Button, CSSObject, Grid, Typography} from '@mui/material';
import {ChevronLeft} from '@mui/icons-material';
import {useBackPrevious} from '@front-libs/core';

const prevButtonStyles: CSSObject = {
  padding: '0px',
  justifyContent: 'flex-start',
  color: '#050505',
  '&:hover': {
    backgroundColor: 'inherit',
  },
};

const pageTitleStyles: CSSObject = {
  fontSize: '18px',
  fontWeight: 'bold',
  fontColor: 'primary.dark',
};

/**
 * 点検一覧に戻るボタン
 * Gridで構成されている
 */
export const InspectionScheduleBackButton: React.FC = () => {
  const goBackToResultsPage = useBackPrevious('/inspection_v2/results');

  const handleClickPrevButton = useCallback(() => {
    goBackToResultsPage();
  }, [goBackToResultsPage]);
  return (
    <Grid container>
      <Grid item xs container direction="column">
        <Button color="inherit" sx={prevButtonStyles} onClick={handleClickPrevButton}>
          <ChevronLeft />
          <span>点検実施予定一覧に戻る</span>
        </Button>
        <Typography variant="h1" sx={pageTitleStyles}>
          点検設定
        </Typography>
        {/* TODO:点検の手順用ボタンが追加される */}
      </Grid>
    </Grid>
  );
};
