import {isNullish} from '@front-libs/helpers';
import {InspectionTableIndex} from '@modules/inspections/types';
import {InspectionResultIndex, MultiSelectResultItem, ResultItem} from '@modules/inspection_results/types';
import {
  FormValue,
  InputField,
  SelectField,
  NumericField,
  MultiSelectField,
  TextField,
  MultilineTextField,
  DateField,
  TimeField,
} from '@Apps/InspectionResult/pc/common/types';
// TODO:HIT-4529 SP参照あり
export const recordToFormValue = (
  table: InspectionTableIndex | null | undefined,
  inspectionResult: InspectionResultIndex,
  isDraft: boolean
): FormValue => {
  if (!table) return {items: {}, isDraft: true};

  const originalItems = inspectionResult.items ?? [];

  const items: {[key: string]: InputField} = {};
  for (const section of table.items) {
    if (section.type !== 'section') continue;
    for (const item of section.items) {
      if (item.id === undefined) {
        console.warn('item.id is undefined!', item);
        continue;
      }

      const originalItem = originalItems.find((resItem) => resItem.id === item.id);
      switch (item.type) {
        case 'select':
        case 'numeric':
        case 'text':
        case 'multiline-text':
        case 'date': {
          let value = '';
          if (item.type === originalItem?.type) {
            value = isNullish(originalItem.value) ? '' : (originalItem.value as string);
          }
          items[item.id] = {
            id: item.id,
            type: item.type,
            value: value,
          };
          break;
        }
        case 'multi-select': {
          const valueCheckedMap: {[key: string]: boolean} = {};
          if (item.type === originalItem?.type) {
            for (const v of (originalItem as MultiSelectResultItem)?.values ?? []) {
              valueCheckedMap[v] = true;
            }
          }
          const values: {[key: string]: boolean} = {};
          for (const option of item.options) {
            values[option.value] = valueCheckedMap[option.value] ?? false;
          }
          items[item.id] = {
            id: item.id,
            type: item.type,
            values: values,
          };
          break;
        }
        case 'time': {
          // eslint-disable-next-line no-shadow
          let value = '';
          if (item.type === originalItem?.type) {
            // HIT869対応 初期値設定せず保存時にnullがDBに保存される。表示の際は逆変換を実施する対応
            value = isNullish(originalItem.value) ? '' : (originalItem.value as string);
          }
          items[item.id] = {
            id: item.id,
            type: item.type,
            // 12:34:56 => 12:34
            value: value.slice(0, 5),
          };
          break;
        }
        default:
          break;
      }
    }
  }

  return {
    items,
    isDraft: isDraft,
  };
};
// TODO:HIT-4529 SP参照あり
export const formFieldToItems = (table: InspectionTableIndex | undefined, formValue: FormValue): ResultItem[] => {
  if (!table) return [];

  const items: ResultItem[] = [];
  for (const section of table.items) {
    if (section.type !== 'section') {
      throw new Error(`expected section, but got, ${section.type}`);
    }

    for (const item of section.items) {
      const id = item.id + '';

      const formItem = formValue.items[id];
      if (item.type !== formItem?.type) {
        throw new Error(
          `expected the form value with same type, type of item=${item.type}, type of field value=${formItem?.type}`
        );
      }

      switch (item.type) {
        case 'select':
        case 'numeric':
        case 'text':
        case 'multiline-text':
        case 'date': {
          const typedItem = formItem as SelectField | NumericField | TextField | MultilineTextField | DateField;

          items.push({
            id: id,
            type: item.type,
            // HIT869対応 初期値をnullに統一
            value: typedItem.value !== '' ? typedItem.value : null,
          });

          break;
        }
        case 'multi-select': {
          const typedInput = formItem as MultiSelectField;
          const values: string[] = Object.keys(typedInput.values).filter((key) => typedInput.values[key] === true);

          items.push({
            id: id,
            type: item.type,
            // HIT869対応 初期値をnullに統一
            values: values.length !== 0 ? values : null,
          });

          break;
        }
        case 'time': {
          const typedInput = formItem as TimeField;

          items.push({
            id: id,
            type: item.type,
            // HIT869対応 空文字の場合バックエンドでパースエラーが発生し、保存できない対応。
            value: typedInput.value !== '' ? `${typedInput.value}:00` : null,
          });

          break;
        }
      }
    }
  }

  return items;
};
