import dayjs from 'dayjs';
import {useEffect, useState} from 'react';

/**
 * 日付・時刻をYYYY年M月D日 (ddd) HH:mm形式で返すuseHook
 */
export const useDateTime = () => {
  const [now, setNow] = useState(dayjs().format('YYYY年M月D日 (ddd) HH:mm'));

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(dayjs().format('YYYY年M月D日 (ddd) HH:mm'));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return now;
};
