import React, {useState} from 'react';
import {Grid, Box, styled, Theme, Button, Typography} from '@material-ui/core';
import {defaultHeightWidth} from '@components/templates/RentalTemplate';
import {CheckCircle} from '@material-ui/icons';
import {useNavigate, useParams} from 'react-router-dom';
import {useAsyncEffect} from '@front-libs/core';
import {getFaultReception} from '@modules/fault_receptions/api/faultReceptionApi';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {InnerLoading} from '@molecules/Loading';
import {useResetFaultReceptionAtom} from './hooks';
import {FaultReceptionHeader} from '@components/molecules/FaultReceptionHeader';

/**
 * 不具合受付完了時の画面
 * 受付番号が表示される
 */
export const FaultComplete = () => {
  const [receptionId, setReceptionId] = useState<string | null>(null);
  const {hashId} = useParams();
  const {myInfo} = useMyInfo();
  const navigate = useNavigate();

  useResetFaultReceptionAtom();

  useAsyncEffect(async () => {
    if (!hashId) return;

    try {
      const faultReception = await getFaultReception(myInfo.hospitalHashId, hashId);

      setReceptionId(faultReception.receptionId);
    } catch (e: unknown) {
      console.error(e);
    }
  }, []);

  if (!hashId || !receptionId) {
    return <InnerLoading />;
  }

  const handleClick = () => {
    navigate('/shared/reception_menu', {replace: true});
  };

  return (
    <>
      <FaultReceptionHeader title="不具合受付" />
      <StyledBox style={defaultHeightWidth}>
        <StyledContainer>
          <StyledContent>
            <Grid container justifyContent="center" alignItems="center">
              <StyledCircle />
              <StyledTitle>不具合受付が完了しました</StyledTitle>
            </Grid>
          </StyledContent>
          <StyledGridContainer>
            <StyledGrid item>
              <StyledGridContent>
                <StyledInnerGrid>
                  <InnerText>受付番号</InnerText>
                  <MediumText>
                    {/* Memo: receptionIdの前半6文字、後半4文字に分割 */}
                    {receptionId.substring(0, 6)} - <LargeText>{receptionId.substring(7)}</LargeText>
                  </MediumText>
                </StyledInnerGrid>
                <Text>受付番号をメモし、対象の医療機器に貼り付けてください</Text>
              </StyledGridContent>
            </StyledGrid>
          </StyledGridContainer>
          <StyledContent>
            <Grid container justifyContent="center">
              <StyledButton onClick={handleClick}>受付番号をメモしたので、終了する</StyledButton>
            </Grid>
          </StyledContent>
        </StyledContainer>
      </StyledBox>
    </>
  );
};

const StyledBox = styled(Box)(({theme}: {theme: Theme}) => ({
  width: '100%',
  maxWidth: 1040,
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const StyledContainer = styled(Grid)({
  width: '100%',
});

const StyledContent = styled(Box)({
  marginBottom: '32px',
});

const StyledGridContainer = styled(Grid)({
  width: '100%',
  marginTop: '32px',
  marginBottom: '32px',
});

const StyledGrid = styled(Grid)({
  borderRadius: 20,
  backgroundColor: 'white',
});

const StyledGridContent = styled(Box)({
  position: 'relative',
  padding: '8px',
  height: '446px',
});

const StyledCircle = styled(CheckCircle)({
  width: '96px',
  height: '96px',
  color: '#3C9E58',
});

const StyledTitle = styled(Grid)({
  color: '#374659',
  fontSize: '48px',
  fontWeight: 'bold',
  paddingLeft: '24px',
});

const StyledButton = styled(Button)(({theme}: {theme: Theme}) => ({
  backgroundColor: theme.palette.common.white,
  height: '80px',
  borderRadius: '20px',
  boxShadow: '0px 4px 0px 0px rgba(209, 216, 245, 1)',
  fontSize: '24px',
  fontWeight: 'bold',
  color: theme.palette.rent.main,
  padding: '28px 32px',
}));

const StyledInnerGrid = styled(Box)({
  width: '510px',
  height: '230px',
  borderRadius: '20px',
  backgroundColor: '#0E3870',
  color: 'white',
  position: 'relative',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '16px',
});

// StyledInnerGrid内のテキストを受け取るコンポーネント
const InnerText = styled(Typography)({
  fontSize: '40px',
  fontWeight: 'bold',
});

const MediumText = styled(Typography)({
  fontSize: '48px',
});

const LargeText = styled('span')({
  fontSize: '96px',
  fontWeight: 'bold',
});

const Text = styled(Grid)({
  position: 'absolute',
  top: '85%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  fontSize: '36px',
  fontWeight: 'bold',
  whiteSpace: 'nowrap',
  color: '#374659',
});
