import React from 'react';
import {ListPageLayout} from '@components/layouts/ListPageLayout';
import {Grid, Button, Typography, Theme} from '@material-ui/core';
import {PageRoutingMenu} from '@organisms/PageRoutingMenu';
import {ChevronLeft} from '@material-ui/icons';
import {styled} from '@material-ui/styles';
import {WholeProductCationHeader} from './WholeProductCationHeader';
import {useIsDisplayAnnounceBar} from './states';
import {useAtomValue} from 'jotai';
import {wholeProductTotalCount} from '@modules/hospital_whole_products_bulk_update/jotai';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {
  UnifyWholeProductModal,
  UnifyWholeProductModalProps,
  UnifyWholeProductModalResult,
} from './Modal/UnifyWholeProductModal';
import {unifyWholeProduct} from '@modules/whole_products/api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {openSnackBar} from '@molecules/SnackBar';
import {useNavigate} from 'react-router-dom';
import {UnifyWholeProductRequest} from '@modules/whole_products/type';
import {MessageDialog} from '@molecules/Dialogs/MessageDialog';
import {UnifyWholeProductConfirmModal} from './Modal/UnifyWholeProductConfirmModal';

const BackProductsButton = () => {
  const navigate = useNavigate();
  const handleClickProductsLink = () => {
    navigate('/products');
  };
  return (
    <BackButton onClick={handleClickProductsLink}>
      <ChevronLeft />
      機器一覧
    </BackButton>
  );
};

/**
 * 機種一覧ページのヘッダー
 */
export const WholeProductsListHeader = () => {
  const {myInfo} = useMyInfo();
  const [isDisplayAnnounceBar, setIsDisplayAnnounceBar] = useIsDisplayAnnounceBar();
  const totalCount = useAtomValue(wholeProductTotalCount);

  const handleUnifyWholeProducts = async () => {
    const request: UnifyWholeProductRequest = {fromWholeProductHashId: '', toWholeProductHashId: ''};
    try {
      const res = await dialogHandler.open<UnifyWholeProductModalProps, UnifyWholeProductModalResult>(
        UnifyWholeProductModal,
        {
          open: true,
        }
      );
      if (!res.fromWholeProduct || !res.toWholeProduct) {
        openSnackBar('入力値が不正です', 'left', 'bottom', 'error');
        return;
      }
      await dialogHandler.open(UnifyWholeProductConfirmModal, {});

      request.fromWholeProductHashId = res.fromWholeProduct;
      request.toWholeProductHashId = res.toWholeProduct;
    } catch (_e) {
      return;
    }

    try {
      await unifyWholeProduct(myInfo.hospitalHashId, request);
      openSnackBar('機種の統合を受け付けました', 'left', 'bottom', 'success');
    } catch (e) {
      console.error(e);
      openSnackBar('機種の統合を受け付けに失敗しました', 'left', 'bottom', 'error');
    }
  };

  return (
    <ListPageLayout.Header defaultOpen={isDisplayAnnounceBar} style={{padding: '16px 20px 4px 16px'}}>
      {isDisplayAnnounceBar && (
        <WholeProductCationHeader
          onClose={() => {
            setIsDisplayAnnounceBar(false);
          }}
        />
      )}
      <FlexDiv>
        <Grid container>
          <Grid item>
            <BackProductsButton />
            <PageRoutingMenu>
              <PageTitleTypography id="tutorial-products-list" variant={'h5'}>
                院内機種
              </PageTitleTypography>
            </PageRoutingMenu>
          </Grid>
          {totalCount > 0 && (
            <Grid item>
              <RegisterCountTypography>{totalCount}件の登録</RegisterCountTypography>
            </Grid>
          )}
        </Grid>
        <IntegrationButton variant="outlined" color="primary" onClick={handleUnifyWholeProducts}>
          機種を統合
        </IntegrationButton>
      </FlexDiv>
    </ListPageLayout.Header>
  );
};

const FlexDiv = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});
const IntegrationButton = styled(Button)({
  background: '#FFFFFF',
  whiteSpace: 'nowrap',
  padding: '8px 28px',
});

const BackButton = styled(Button)({
  fontSize: '14px',
  padding: 0,
});

const PageTitleTypography = styled(Typography)(({theme}: {theme: Theme}) => ({
  fontSize: 20,
  fontWeight: 700,
  color: theme.palette.primary.dark,
}));

const RegisterCountTypography = styled(Typography)({
  fontSize: 12,
  color: '#5D6B82',
  marginLeft: '8px',
  marginTop: '40px',
});
