import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {Box, CSSObject, Grid, IconButton, Typography} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const AlertCSS: CSSObject = {
  width: 'calc(100% - 64px)',
  height: 'calc(100% - 24px)',
  position: 'absolute',
  zIndex: 100,
  background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #FF9800',
  color: '#C77700',
  top: 0,
  left: 0,
  margin: 0,
  padding: '12px 32px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};
const FontSizeCss: CSSObject = {
  fontSize: '14px',
};
const HeadTextCss: CSSObject = {
  ...FontSizeCss,
  fontWeight: 700,
};

type Props = {onClose: VoidFunction};

/**
 *
 * 機種一覧ページにて機器登録ガイド文を表示される注意喚起ヘッダー
 *
 * @param props.onClose 閉じる処理
 * @returns
 */
export const WholeProductCationHeader: React.FC<Props> = ({onClose}) => {
  return (
    <Box sx={AlertCSS}>
      <Box sx={{display: 'flex', alignItems: 'center'}}>
        <InfoOutlinedIcon sx={{color: '#C77700'}} fontSize="medium" />
        <Box sx={{marginLeft: 2}}>
          <Typography component={'h1'} sx={HeadTextCss}>
            機器を新規登録＞登録済みの院内機種から登録
          </Typography>
          <Typography component={'h1'} sx={HeadTextCss}>
            対象の機種にカーソルを合わせ、「機器を新規登録」ボタンをクリックしてください。
          </Typography>
        </Box>
      </Box>
      <Box>
        <IconButton aria-label="close" size="small" onClick={onClose}>
          <CloseIcon sx={{color: '#C77700'}} fontSize="medium" />
        </IconButton>
      </Box>
    </Box>
  );
};
