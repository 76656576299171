import {Box, Tabs, Tab, Typography, Button} from '@material-ui/core';
import {MoreVert} from '@material-ui/icons';
import {FastField, FieldInputProps, FieldMetaProps, FormikProps, useFormikContext} from 'formik';
import React, {memo, useCallback, useMemo, ChangeEvent, useState, useEffect} from 'react';
import {InspectionIndex, InspectionTableIndex} from '@modules/inspections/types';
import {InspectionResultIndex} from '@modules/inspection_results/types';
import {EmptyAnswer, FormValue} from '../../pc/common/types';
import {getEmptyAnswers} from '../../pc/common/validator';
import {
  FinishInspectionDialog,
  FinishInspectionDialogProps,
  FinishInspectionDialogResult,
} from './Dialog/FinishInspectionDialog';
import {dialogHandler} from '@components/molecules/Dialogs/DialogHandler';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {openSnackBar} from '@molecules/SnackBar';
import {useBackPrevious} from '@front-libs/core';
import {NextUpdateInspectionStatusParam, updateInspectionResult} from '@modules/inspection_results/api';
import {formFieldToItems} from '../../pc/common/mapper';
import {convertDateToRFC3339} from '@front-libs/helpers';
import {ActionBottomDialog} from './Dialog/ActionBottomDialog';
import {
  NoSaveInspectionDialogProps,
  NoSaveInspectionDialogResult,
  NoSaveInspectionDialog,
} from './Dialog/ActionBottomDialog/NoSaveDialog';
import {useNavigate} from 'react-router-dom';
import {SuspendInspectionDialog, SuspendInspectionDialogProps} from './Dialog/ActionBottomDialog/SuspendDialog';
import {styled} from '@material-ui/styles';

const StyledTab = styled(Tab)({
  padding: '0px 16px',
  fontWeight: 400,
  color: '#172B4D',
  fontSize: '16px',
  // 選択時のスタイル
  '&.Mui-selected': {
    borderBottom: '2px solid #0052CC',
    fontWeight: 'bold',
    color: '#0052CC',
  },
});

const StyledRoot = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'white',
  justifyContent: 'space-between',
  padding: '0px 16px',
});

const StyledTitleContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '8px 0px',
  alignItems: 'center',
});

const StyledTitleActionBtnContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

const StyledTitleTxt = styled(Typography)({
  fontWeight: 'bold',
  color: '#172B4D',
});

const StyledCompleteBtn = styled(({submitButtonDisabled, ...otherProps}) => <Button {...otherProps} />)(
  ({submitButtonDisabled}: {submitButtonDisabled: boolean}) => ({
    color: submitButtonDisabled ? '#C6CBD4' : '#0052CC',
    paddingRight: '4px',
    fontSize: '16px',
    border: 'none',
  })
);

const StyledProductContainer = styled(Box)({
  display: 'flex',
});

const StyledProductTxt = styled(Typography)({
  paddingRight: '16px',
  fontSize: '14px',
});

const StyledSettingBar = styled(Box)({
  display: 'flex',
});
const StyledMoreVert = styled(MoreVert)({
  paddingLeft: '4px',
});

const StyledErrorCnt = styled(Box)({
  width: '24px',
  height: '20px',
  backgroundColor: '#2A96E8',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#FFFFFF',
  fontSize: '14px',
  lineHeight: '16px',
  textAlign: 'right',
  borderRadius: '80%',
});

const HeaderInner: React.FC<
  HeaderFieldProps & HeaderInnerProps & {selectedCallBack: (selectedTab: SelectedHeaderTab) => void}
> = memo((props) => {
  const {inspection, inspectionTable, inspectionResult, form, nextUpdateInspectionStatusParam, selectedCallBack} =
    props;
  const {myInfo} = useMyInfo();
  const {values} = useFormikContext<FormValue>();
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [emptyAnswers, setEmptyAnswers] = useState<EmptyAnswer[]>([]);
  const goBackToResultsPage = useBackPrevious('/sp/inspection/results');

  useEffect(() => {
    form.validateForm().then(() => {
      const newEmptyAnswers = getEmptyAnswers(form.errors, inspection.table.items);
      setEmptyAnswers(newEmptyAnswers);
    });
  }, [form, inspection.table.items]);

  const submitButtonDisabled = useMemo(
    () => emptyAnswers.length > 0 || form.isSubmitting,
    [emptyAnswers, form.isSubmitting]
  );

  const handleTabChange = useCallback(
    (event: ChangeEvent<unknown>, newValue: number) => {
      setValue(newValue);
      selectedCallBack(newValue === 0 ? 'InspectionItems' : 'PhotosOrFiles');
    },
    [selectedCallBack]
  );

  const handleClickFinish = useCallback(
    async (e: React.MouseEvent) => {
      e.stopPropagation();
      let completedAt: string;
      try {
        const res = await dialogHandler.open<FinishInspectionDialogProps, FinishInspectionDialogResult>(
          FinishInspectionDialog,
          {
            defaultInspectorHashId: myInfo.hashId,
            inspectionResult: inspectionResult,
          }
        );
        completedAt = res.completedAt;
      } catch (_e) {
        return;
      }

      try {
        const res = await updateInspectionResult(myInfo.hospitalHashId, inspection.hashId, inspectionResult.hashId, {
          inspectionTableHashId: inspectionTable.hashId,
          inspectorHashId: nextUpdateInspectionStatusParam.inspectorHashId,
          status: 'completed',
          items: formFieldToItems(inspectionTable, values),
          comment: '',
          completedAt: convertDateToRFC3339(new Date(completedAt)),
        });
        // FIXME 苦肉の策で2秒Wait後、Reloadする処理。意図的には同じパスだがうまく再描画されないため。
        await new Promise((resolve) => setTimeout(resolve, 2000));
        navigate(`/sp/inspections/${res.data.inspectionHashId}/result/${res.data.hashId}`);
        window.location.reload();
        openSnackBar('点検を保存しました', 'center', 'bottom');
      } catch (_e) {
        return;
      }
    },
    [
      inspection.hashId,
      inspectionResult,
      inspectionTable,
      myInfo.hashId,
      myInfo.hospitalHashId,
      navigate,
      nextUpdateInspectionStatusParam.inspectorHashId,
      values,
    ]
  );

  const handleClickNoSave = useCallback(
    async (e: React.MouseEvent) => {
      e.stopPropagation();
      let isNotSave = '';
      try {
        const res = await dialogHandler.open<NoSaveInspectionDialogProps, NoSaveInspectionDialogResult>(
          NoSaveInspectionDialog,
          {}
        );
        isNotSave = res.isNotSave;
      } catch (_e) {
        return;
      }
      if (isNotSave) {
        navigate(`/sp/inspection/results`);
        openSnackBar('点検結果を保存せず終了しました。', 'center', 'bottom');
      }
    },
    [navigate]
  );

  const handleClickSaveAsDraft = useCallback(
    async (e: React.MouseEvent) => {
      e.stopPropagation();
      try {
        // eslint-disable-next-line @typescript-eslint/ban-types
        await dialogHandler.open<SuspendInspectionDialogProps, {}>(SuspendInspectionDialog, {});
      } catch (_e) {
        return;
      }
      try {
        await updateInspectionResult(myInfo.hospitalHashId, inspection.hashId, inspectionResult.hashId, {
          inspectionTableHashId: inspectionResult.inspectionTableHashId,
          inspectorHashId: nextUpdateInspectionStatusParam.inspectorHashId,
          items: formFieldToItems(inspectionTable, values),
          status: 'uncompleted',
          comment: '',
        });
        openSnackBar('点検結果を保存し中断しました。', 'center', 'bottom');
        goBackToResultsPage();
      } catch (_e) {
        return;
      }
    },
    [
      goBackToResultsPage,
      inspection.hashId,
      inspectionResult.hashId,
      inspectionResult.inspectionTableHashId,
      inspectionTable,
      myInfo.hospitalHashId,
      nextUpdateInspectionStatusParam.inspectorHashId,
      values,
    ]
  );

  const handleClickAction = useCallback(
    async (e: React.MouseEvent) => {
      e.stopPropagation();
      try {
        await dialogHandler.open(ActionBottomDialog, {
          onDiscardChanges: handleClickNoSave,
          onSaveAsDraft: handleClickSaveAsDraft,
        });
      } catch (_e) {
        return;
      }
    },
    [handleClickNoSave, handleClickSaveAsDraft]
  );

  return (
    <StyledRoot>
      <StyledTitleContainer>
        <StyledTitleTxt>{inspection.name}</StyledTitleTxt>
        <StyledTitleActionBtnContainer>
          <StyledCompleteBtn
            disabled={submitButtonDisabled}
            submitButtonDisabled={submitButtonDisabled}
            onClick={handleClickFinish}>
            点検完了
          </StyledCompleteBtn>
          <StyledErrorCnt>{emptyAnswers.length}</StyledErrorCnt>
          <StyledMoreVert onClick={handleClickAction} />
        </StyledTitleActionBtnContainer>
      </StyledTitleContainer>
      <StyledProductContainer>
        <StyledProductTxt>型式：{inspectionResult.hospitalProduct.name}</StyledProductTxt>
        <StyledProductTxt>管理番号：{inspectionResult.hospitalProduct.managementId}</StyledProductTxt>
      </StyledProductContainer>
      <StyledSettingBar>
        <Tabs value={value} onChange={handleTabChange} indicatorColor="primary" textColor="primary" centered>
          <StyledTab label="点検項目" />
          <StyledTab label="写真・ファイル" />
        </Tabs>
      </StyledSettingBar>
    </StyledRoot>
  );
});

/** 選択されているタブ InspectionItems=点検項目 PhotosOrFiles=写真・ファイル */
export type SelectedHeaderTab = 'InspectionItems' | 'PhotosOrFiles';

export type HeaderFieldProps = {
  field: FieldInputProps<FormValue>;
  meta: FieldMetaProps<FormValue>;
  form: FormikProps<FormValue>;
};

export const Header: React.FC<HeaderInnerProps> = memo((props) => {
  const {selectedCallBack} = props;
  return (
    <FastField name="">
      {({field, form, meta}: HeaderFieldProps) => (
        <HeaderInner {...props} field={field} meta={meta} form={form} selectedCallBack={selectedCallBack} />
      )}
    </FastField>
  );
});

type HeaderInnerProps = {
  inspection: InspectionIndex;
  inspectionTable: InspectionTableIndex;
  inspectionResult: InspectionResultIndex;
  nextUpdateInspectionStatusParam: NextUpdateInspectionStatusParam;
  selectedCallBack: (selectedTab: SelectedHeaderTab) => void;
};
