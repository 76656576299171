import React from 'react';
import {createStyles, Grid, makeStyles, Theme, Typography} from '@material-ui/core';
import {Search} from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontWeight: 'bold',
    },
    message: {
      marginTop: '8px',
      whiteSpace: 'pre-line',
    },
    iconContainer: {
      marginLeft: '48px',
    },
    icon: {
      fontSize: 240,
      color: '#DADADA',
    },
  })
);

type NoProductsProps = {
  title: string;
  message: string;
};

export const NoProducts: React.FC<NoProductsProps> = (props) => {
  const {title, message} = props;
  const classes = useStyles();

  return (
    <Grid container alignItems="center" justifyContent="space-between">
      <Grid xs item container direction="column">
        <Typography variant="h6" className={classes.title}>
          {title}
        </Typography>
        <Typography variant="body1" className={classes.message}>
          {message}
        </Typography>
      </Grid>
      <Grid item className={classes.iconContainer}>
        <Search className={classes.icon} />
      </Grid>
    </Grid>
  );
};
