import React, {createContext, useContext} from 'react';
import {PeriodUnit} from '@modules/inspection_setting/types';

type UpdatePeriodRequestDispatcher = (
  productHashId: string,
  type: 'periodic' | 'maker',
  period: number,
  unit: PeriodUnit
) => Promise<void>;

const UpdatePeriodRequestDispatcherContext = createContext<UpdatePeriodRequestDispatcher>(
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async () => {}
);

type UpdatePeriodRequestDispatcherContextProviderProps = {
  handler: UpdatePeriodRequestDispatcher;
};

export const UpdatePeriodRequestDispatcherContextProvider: React.FC<UpdatePeriodRequestDispatcherContextProviderProps> =
  ({handler, children}) => {
    return (
      <UpdatePeriodRequestDispatcherContext.Provider value={handler}>
        {children}
      </UpdatePeriodRequestDispatcherContext.Provider>
    );
  };

export const useUpdatePeriodRequestDispatcherContext = () => {
  return useContext(UpdatePeriodRequestDispatcherContext);
};

type UnassignWholeProductRequestDispatcher = (productHashId: string) => Promise<void>;

const UnassignWholeProductRequestDispatcherContext = createContext<UnassignWholeProductRequestDispatcher>(
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async () => {}
);

type UnassignWholeProductRequestDispatcherContextProviderProps = {
  handler: UnassignWholeProductRequestDispatcher;
};

export const UnassignWholeProductRequestDispatcherContextProvider: React.FC<
  UnassignWholeProductRequestDispatcherContextProviderProps
> = ({handler, children}) => {
  return (
    <UnassignWholeProductRequestDispatcherContext.Provider value={handler}>
      {children}
    </UnassignWholeProductRequestDispatcherContext.Provider>
  );
};

export const useUnassignWholeProductRequestDispatcherContext = () => {
  return useContext(UnassignWholeProductRequestDispatcherContext);
};

type DisplayHospitalProductRequestDispatcher = (wholeProductHashId: string) => void;

const DisplayHospitalProductRequestDispatcherContext = createContext<DisplayHospitalProductRequestDispatcher>(
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  (_id) => {}
);

type DisplayHospitalProductRequestDispatcherContextProviderProps = {
  handler: DisplayHospitalProductRequestDispatcher;
};

export const DisplayHospitalProductRequestDispatcherContextProvider: React.FC<
  DisplayHospitalProductRequestDispatcherContextProviderProps
> = ({handler, children}) => {
  return (
    <DisplayHospitalProductRequestDispatcherContext.Provider value={handler}>
      {children}
    </DisplayHospitalProductRequestDispatcherContext.Provider>
  );
};

export const useDisplayHospitalProductRequestDispatcherContext = () => {
  return useContext(DisplayHospitalProductRequestDispatcherContext);
};
