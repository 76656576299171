import React, {useCallback} from 'react';
import {Box, Button, makeStyles} from '@material-ui/core';
import {theme} from '@components/atoms/theme';
import {useNavigate, useLocation} from 'react-router-dom';
import clsx from 'clsx';
import {StrUtil} from '@front-libs/helpers';
import {ChevronLeft, ChevronRight} from '@material-ui/icons';
import {useMyRole} from '@modules/hospital_users/hooks/useMyRole';

export const RentalFooterHeightTabletH = '148px';
export const RentalFooterHeightLaptop = '168px';

type Props = {
  text: string;
  backButtonText?: string;
  backButtonSize?: 'small' | 'large';
} & (NotHaveNextButton | HaveNextButton);

type NotHaveNextButton = {
  nextButtonLabel?: undefined;
};

type HaveNextButton = {
  nextButtonLabel: string;
  onClickNextButton: () => void;
  isDisabled?: boolean;
};

export const Footer: React.FC<Props> = React.memo(
  ({text, backButtonText = '戻る', backButtonSize = 'small', ...props}) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const path = useLocation().pathname;
    const {isRentalOnly} = useMyRole();

    const handleClickTop = useCallback(() => {
      if (path === '/shared') {
        navigate('/rentals');
      } else {
        navigate(-1);
      }
    }, [navigate, path]);

    // NOTE: 共通アカウントの場合、貸出返却の最初の画面から機器管理画面に遷移できないようにする
    const isAvailableBtn = path !== '/shared' || !isRentalOnly;

    return (
      <Box className={classes.container}>
        {isAvailableBtn && (
          <Button
            data-testid={'rental-footer-back-button'}
            variant={'outlined'}
            className={clsx(classes.button, classes.backButton, backButtonSize === 'small' && classes.smallBtn)}
            classes={{label: classes.muiButton}}
            onClick={handleClickTop}>
            <ChevronLeft className={classes.leftIcon} />
            <Box className={classes.previousBtnText}>{backButtonText}</Box>
          </Button>
        )}
        <Box className={classes.text}>{StrUtil.nl2br(text)}</Box>
        {props.nextButtonLabel && (
          <Button
            data-testid={'rental-footer-next-button'}
            variant={'outlined'}
            className={clsx(classes.button, classes.nextButton)}
            disabled={props.isDisabled}
            onClick={props.onClickNextButton}>
            <Box className={classes.nextBtnText}>{props.nextButtonLabel}</Box>
            <ChevronRight className={classes.leftIcon} />
          </Button>
        )}
      </Box>
    );
  }
);

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: theme.palette.rent.main,
    height: RentalFooterHeightTabletH,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('laptop')]: {
      height: RentalFooterHeightLaptop,
    },
  },
  text: {
    color: theme.palette.common.white,
    fontSize: '24px',
    fontWeight: 'bold',
    textAlign: 'center',
    [theme.breakpoints.up('tabletV')]: {
      fontSize: '32px',
    },
    [theme.breakpoints.up('tabletH')]: {
      fontSize: '36px',
    },
    [theme.breakpoints.up('laptop')]: {
      fontSize: '42px',
    },
  },
  button: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.rent.main,
    position: 'absolute',
    fontSize: '18px',
    fontWeight: 'bold',
    height: '60px',
    padding: '5px 0px 5px 8px',
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
    [theme.breakpoints.up('tabletH')]: {
      fontSize: '20px',
    },
    [theme.breakpoints.up('laptop')]: {
      fontSize: '24px',
      padding: '5px 15px',
    },
  },
  muiButton: {
    justifyContent: 'left',
  },
  backButton: {
    width: '296px',
    left: '0px',
    borderRadius: '0px 45px 45px 0px',
    boxShadow: '0px 4px 0px 0px rgba(209, 216, 245, 1)',
    [theme.breakpoints.up('laptop')]: {
      width: '336px',
    },
  },
  nextButton: {
    right: '0px',
    borderRadius: '45px 0px 0px 45px',
    boxShadow: '0px 4px 0px 0px rgba(209, 216, 245, 1)',
  },
  smallBtn: {
    width: '108px',
    [theme.breakpoints.up('tabletV')]: {
      width: '140px',
    },
    [theme.breakpoints.up('tabletH')]: {
      width: '160px',
    },
    [theme.breakpoints.up('laptop')]: {
      width: '176px',
    },
  },
  previousBtnText: {
    paddingTop: '4px',
    paddingRight: '24px',
  },
  nextBtnText: {
    paddingTop: '4px',
    paddingLeft: '24px',
  },
  leftIcon: {
    paddingTop: '4px',
    paddingRight: '8px',
    fontSize: '24px',
  },
}));
