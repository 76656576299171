import React, {useCallback, useMemo} from 'react';
import {createStyles, Grid, makeStyles, Typography} from '@material-ui/core';
import {Column} from '@molecules/Table/props';
import dayjs from 'dayjs';
import {TableLayout, useTableLayout} from '@modules/table_layout/hooks/useTableLayout';
import {FileCategory, FileIndex} from '@modules/files/types';
import {UserFormatter} from '@modules/hospital_users/helpers';
import {Table} from '@molecules/Table';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {FilePreviewDialog} from '@molecules/Dialogs/FilePreviewDialog';
import {EditFileNameDialog} from '@organisms/Files/FileList/EditFileNameDialog';
import {openSnackBar} from '@molecules/SnackBar';
import {updateFile, deleteFile} from '@modules/files/api';
import {AlertDialog} from '@molecules/Dialogs/AlertDialog';
import {toFileLabel} from '@modules/files/constants';

type FileElement = {
  hashId: string;
  fileName: string;
  fileType: string;
  category: FileCategory;
  url: string;
  createdAt: string;
  createdBy: string;
};

type FilesTableProps = {
  files: FileIndex[];
  isLoading?: boolean;
  noDataComponent: JSX.Element;
  disableEdit: boolean;
  disableDelete: boolean;
  onEditFileName: (fileHashId: string) => void;
  onDeleteFile: (fileHashId: string) => void;
  onOrderChange: (order: string | undefined) => void;
};

export const FileTable: React.FC<FilesTableProps> = ({
  files,
  isLoading,
  noDataComponent,
  disableEdit,
  disableDelete,
  onEditFileName,
  onDeleteFile,
  onOrderChange,
  ...props
}) => {
  const classes = useStyles();
  const [tableLayout] = useTableLayout('hospitalFileList');

  const handleClickPreview = useCallback(async (_e: React.MouseEvent, data: FileElement) => {
    await dialogHandler.open(FilePreviewDialog, {
      fileName: data.fileName,
      fileType: data.fileType,
      title: 'ファイルプレビュー',
      url: data.url,
    });
  }, []);

  const handleClickRename = useCallback(
    async (_e: React.MouseEvent, data: FileElement) => {
      const updatedFileName = await dialogHandler.open(EditFileNameDialog, {defaultFileName: data.fileName});

      try {
        await updateFile(data.hashId, updatedFileName);

        openSnackBar('ファイルを更新しました。');
        onEditFileName(data.hashId);
        // eslint-disable-next-line no-shadow
      } catch (_e) {
        openSnackBar('ファイルの更新に失敗しました。', 'left', 'bottom', 'error');
      }
    },
    [onEditFileName]
  );

  const handleClickDelete = useCallback(
    async (_e: React.MouseEvent, data: FileElement) => {
      await dialogHandler.open(AlertDialog, {
        title: 'ファイルを削除しますか？',
        content: 'このファイルを削除しようとしています。\n\nこのアクションは元に戻せません。',
        positiveButtonLabel: 'ファイルを削除',
      });

      try {
        await deleteFile(data.hashId);

        openSnackBar('ファイルを削除しました。');
        onDeleteFile(data.hashId);
      } catch (e) {
        openSnackBar('ファイルの削除に失敗しました。', 'left', 'bottom', 'error');
      }
    },
    [onDeleteFile]
  );

  const serializedTableColumn = useMemo(() => {
    const tableColumn = Object.assign<Column<FileElement>[], TableLayout[]>([], tableLayout.currentLayout);
    return tableColumn.map<Column<FileElement>>((item) => {
      if (item.field === 'fileName') {
        item.render = (data) => (
          <Typography className={classes.name} variant="inherit">
            {data.fileName}
          </Typography>
        );
      }
      if (item.field === 'category') {
        item.render = (data) => (
          <Typography className={classes.name} variant="inherit">
            {toFileLabel(data.category)}
          </Typography>
        );
      }
      item.noBodyWrap = true;
      return item;
    });
  }, [tableLayout, classes.name]);

  const handleOrderChange = useCallback(
    (columnIndex: number, orderDirection: 'asc' | 'desc') => {
      if (columnIndex === -1) {
        onOrderChange(undefined);
      } else {
        onOrderChange(`${orderDirection === 'desc' ? '-' : ''}${String(serializedTableColumn[columnIndex].field)}`);
      }
    },
    [onOrderChange, serializedTableColumn]
  );

  const fileListElements = useMemo<FileElement[]>(() => {
    return (
      files?.map((file) => ({
        hashId: file.hashId,
        fileName: file.fileName,
        fileType: file.fileType,
        category: file.category,
        url: file.url,
        createdAt: dayjs(file.createdAt).format('YYYY/MM/DD'),
        createdBy: UserFormatter.getFullName(file.createdBy),
      })) || []
    );
  }, [files]);

  return (
    <Grid container className={classes.fileTableContainer}>
      <Table<FileElement>
        stickyHeader={true}
        tableSize="small"
        paperProps={{className: classes.tablePaper}}
        showSelection={false}
        isLoading={isLoading}
        columns={serializedTableColumn}
        data={fileListElements}
        noDataComponent={noDataComponent}
        onOrderChange={handleOrderChange}
        rowActions={useMemo(
          () =>
            disableEdit
              ? [
                  {
                    type: 'button' as const,
                    label: 'プレビュー',
                    onClick: handleClickPreview,
                  },
                ]
              : [
                  {
                    type: 'button' as const,
                    label: 'プレビュー',
                    onClick: handleClickPreview,
                  },
                  ...(disableDelete
                    ? [
                        {
                          type: 'button' as const,
                          label: '名前を変更',
                          onClick: handleClickRename,
                        },
                      ]
                    : [
                        {
                          type: 'menu' as const,
                          label: 'アクション',
                          items: [
                            {
                              label: '名前を変更',
                              onClick: handleClickRename,
                            },
                            {
                              label: '削除',
                              onClick: handleClickDelete,
                            },
                          ],
                        },
                      ]),
                ],
          // eslint-disable-next-line react-hooks/exhaustive-deps
          [disableEdit, handleClickPreview, handleClickRename, handleClickDelete]
        )}
      />
    </Grid>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    fileTableContainer: {
      minWidth: '700px',
    },
    tablePaper: {
      maxHeight: `calc(100vh - 56px - 48px - 39px - 33px - 64px - 32px - 24px - 20px)`,
    },
    name: {
      width: 'auto',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  })
);
