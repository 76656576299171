import React from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {Field} from '@Apps/Inspection/Field';
import {Field as FieldType, FormValue} from '@Apps/Inspection/types';
import {Draggable, Droppable} from 'react-beautiful-dnd';
import {FieldArray, FieldArrayRenderProps, FormikProps, getIn} from 'formik';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    fields: {
      width: '100%',
      padding: '0px',
      margin: '0px',
      '& > li': {
        marginTop: '16px',
        listStyle: 'none',
      },
    },
    field: {
      width: '100%',
    },
  })
);

type FieldsProps = {
  sectionIndex: number;
};

type FieldsInnerProps = FieldArrayRenderProps &
  FieldsProps & {
    form: FormikProps<FormValue>;
  };

// TODO: react-beautiful-dndのatom wrapperに置き換える
const FieldsInner: React.FC<FieldsInnerProps> = (props) => {
  const {name, sectionIndex, form, insert, remove} = props;
  const classes = useStyles();
  const fields = getIn(form.values, name) as FieldType[];

  const handleDuplicateField = React.useCallback(
    (e: React.MouseEvent, index: number) => {
      // 複製アイコン押下でフォーカスが当たらないようにする
      e.stopPropagation();

      const targetItem = getIn(form.values, `${name}[${index}]`);
      // NOTE: HIT-3555のincidentでDeepCopy対応。
      const res = JSON.parse(JSON.stringify(targetItem));
      insert(index + 1, {
        ...res,
        id: '',
      });
    },
    [form.values, insert, name]
  );

  const handleDeleteField = React.useCallback(
    (e: React.MouseEvent, index: number) => {
      // 削除アイコン押下でフォーカスが当たらないようにする
      e.stopPropagation();

      remove(index);
    },
    [remove]
  );

  return (
    <Droppable droppableId={JSON.stringify({section: sectionIndex})}>
      {(provided) => (
        <ul className={classes.fields} {...provided.droppableProps} ref={provided.innerRef}>
          {fields.map((_, index) => (
            <Draggable key={index} index={index} draggableId={JSON.stringify({section: sectionIndex, field: index})}>
              {(innerProvided) => (
                <li className={classes.field} ref={innerProvided.innerRef} {...innerProvided.draggableProps}>
                  <Field
                    key={index}
                    sectionIndex={sectionIndex}
                    fieldIndex={index}
                    // biome-ignore lint/style/noNonNullAssertion: 非nullアサーション演算子(!)
                    dragHandlerProps={innerProvided.dragHandleProps!}
                    onClickDuplicate={(e) => handleDuplicateField(e, index)}
                    onClickDelete={(e) => handleDeleteField(e, index)}
                  />
                </li>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </ul>
      )}
    </Droppable>
  );
};

export const Fields: React.FC<FieldsProps> = (props) => (
  <FieldArray
    name={`sections[${props.sectionIndex}].fields`}
    render={(arrayHelpers) => <FieldsInner {...arrayHelpers} {...props} />}
  />
);
