import React, {useMemo, useState, useCallback} from 'react';
import {Grid} from '@material-ui/core';
import {Pagination} from '@material-ui/lab';
import {Column, RowAction} from '@molecules/Table/props';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {TableLayout, TableLayoutResult, useTableLayout} from '@modules/table_layout/hooks/useTableLayout';
import {getAxiosErrorData, useDebounceCallback} from '@front-libs/core';
import qs from 'qs';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {
  copyInspection,
  deleteInspection,
  FetchInspectionListParams,
  useFetchInspectionListQuery,
} from '@modules/inspections/api';
import {InspectionStatus, InspectionType, InspectionTypeMap} from '@modules/inspections/enum';
import {InspectionListElement, InspectionSettingListElement} from '../types';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {
  CopyInspectionDialog,
  CopyInspectionDialogProps,
  CopyInspectionDialogResult,
} from '@molecules/Dialogs/CopyInspectionDialog';
import {getQueryOrderKey, pickFirstQuery} from '@front-libs/helpers';
import {formatRFC3339Date} from '@front-libs/helpers';
import {openSnackBar} from '@components/molecules/SnackBar';
import {SearchBar} from './SearchBar';
import {InspectionList as inspectionList} from '@modules/inspections/types';
import {useAtom} from 'jotai';
import {pageSizeAtom, searchNameAtom} from '@Apps/InspectionSetting/InspectionList/states';
import {Table} from '@molecules/Table';
import {styled} from '@material-ui/styles';
import {EditProductsDialog, EditProductsDialogProps} from '@Apps/Inspection/ProductsForm/EditProductsDialog';
import {InspectionPeriodProduct} from '@modules/inspection_setting/types';
import {updateInspectionPeriods} from '@modules/inspection_setting/api';
import {TableViewLayout} from '@components/layouts/TableViewLayout';
import {
  InspectionSettingDialog,
  InspectionSettingDialogProps,
} from '@Apps/InspectionSetting/InspectionList/Dialogs/InspectionSettingDialog';
import {DisplayNumberSelect} from '@components/molecules/DisplayNumberSelect';
import {InspectionStatusColumn} from './InspectionStatusColumn';
import {ConfirmDeleteInspectionDialog} from '@organisms/ConfirmDeleteInspectionDialog';
import {useMyRole} from '@modules/hospital_users/hooks/useMyRole';

/**
 *
 *
 * @param {(TableLayoutResult)} tableLayout
 * @return {*}
 */
const useTableColumns = (
  tableLayout: TableLayoutResult | undefined,
  handleClickEditProducts: (_e: React.MouseEvent, data: InspectionSettingListElement) => Promise<void>
) => {
  return useMemo(() => {
    if (!tableLayout) return;
    const tableColumn = Object.assign<Column<InspectionListElement>[], TableLayout[]>([], tableLayout.currentLayout);
    return tableColumn.map<Column<InspectionListElement>>((item) => {
      if (item.field === 'status') {
        item.render = (rowData: InspectionListElement) => {
          return <InspectionStatusColumn rowData={rowData} />;
        };
      }
      if (item.field === 'name') {
        item.render = ({name}) => name.name;
      }
      if (item.field === 'publishedAt') {
        // MaterialTableのソートを使うと、空文字が先頭に来てしまうので無効化する
        item.customSort = (_data1, _data2) => {
          return 1;
        };
      }
      if (item.field === 'updatedBy') {
        item.render = ({updatedAt}) => updatedAt;
        item.sorting = false;
      }
      return item;
    });
  }, [tableLayout]);
};

/**
 * 点検表削除
 *
 * @param {string} hospitalHashId
 * @param {InspectionIndex} inspection
 * @return Promise<boolean>
 */
const disableInspection = async (hospitalHashId: string, inspection: inspectionList): Promise<boolean> => {
  try {
    // 削除確認用のダイアログを表示する
    await dialogHandler.open(ConfirmDeleteInspectionDialog, {});
  } catch (_e) {
    return false;
  }

  try {
    await deleteInspection(hospitalHashId, inspection.hashId);
    openSnackBar('点検表を削除しました (一覧から消えるまでに数秒かかることがあります。画面を更新してご確認ください。)');

    return true;
  } catch (e: unknown) {
    const errorData = getAxiosErrorData(e);
    switch (errorData?.message) {
      case 'cannot change status of inspection with ongoing results to draft':
        openSnackBar('実施途中の点検が存在するため、点検表を削除できません。', 'left', 'bottom', 'error');
        break;
      default:
        openSnackBar(`エラーが発生しました: ${errorData?.message}`, 'left', 'bottom', 'error');
    }

    return false;
  }
};

const PageDescriptionGrid = styled(Grid)({
  margin: 'auto 0px',
  flex: 1,
});
const PaginationContainerGrid = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
  fontSize: '0.875rem',
});
const EmptyGrid = styled(Grid)({
  flex: 1,
});
const StyledRoot = styled(Grid)({
  width: '100%',
  height: '100%',
  flexWrap: 'nowrap',
});
const StyledTableViewLayout = styled(TableViewLayout)({
  flex: 1,
  height: '100%',
});

const ROW_ACTION_INDEX = 1;

/**
 * 検査リスト
 * @returns
 */
export const InspectionList: React.FC = () => {
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const [searchName, setSearchName] = useAtom(searchNameAtom);
  const {myInfo} = useMyInfo();
  const [pageSize, setPageSize] = useAtom(pageSizeAtom);
  const {isAdmin} = useMyRole();

  const [tableLayout, setTableLayout] = useTableLayout(
    'inspectionTableList',
    useMemo(() => ({name: true}), [])
  );

  const handleChangeTableLayout = useCallback(
    (layout: TableLayoutResult) => {
      setTableLayout(layout);
    },
    [setTableLayout]
  );

  const [page, setPage] = useState<number>(Number(queryParams.get('page')) || 1);
  const [orderKey, setOrderKey] = useState<string | null>(pickFirstQuery(queryParams.get('order')) ?? null);
  const [type, setType] = useState<string | null>(queryParams.get('type') || null);
  const [selectedStatus, setSelectedStatus] = React.useState<InspectionStatus | null>(
    (queryParams.get('status') as InspectionStatus) || null
  );

  const params = useMemo(() => {
    const _p: FetchInspectionListParams = {
      page: page - 1,
      perPage: pageSize,
    };

    if (orderKey) {
      _p.order = orderKey;
    }

    if (searchName) {
      _p.name = searchName;
    }

    if (type) {
      _p.inspectionType = type as InspectionType;
    }

    _p.statuses = selectedStatus ?? 'available,draft';

    navigate({
      search: qs.stringify(
        {..._p, statuses: undefined, status: selectedStatus || undefined, page: (_p?.page || 0) + 1},
        {arrayFormat: 'brackets'}
      ),
    });

    // convert order fields for API
    if (orderKey) {
      _p.order = getQueryOrderKey(orderKey);
    }

    return _p;
  }, [page, pageSize, orderKey, searchName, type, navigate, selectedStatus]);

  const query = useFetchInspectionListQuery(myInfo.hospitalHashId, params);

  const handleClickRow = useCallback(
    (_event?: React.MouseEvent, rowData?: InspectionListElement) => {
      if (!rowData) return;

      // 公開の場合はプレビュー画面に遷移
      if (rowData.name.status === 'available') {
        return navigate(`/inspection_v2/table_preview/${rowData?.hashId}`);
      }
      // 下書きの場合は編集画面に遷移
      if (rowData.name.status === 'draft') {
        return navigate(`/inspections/${rowData?.hashId}`);
      }
    },
    [navigate]
  );

  const handleChangePage = useCallback((event: React.ChangeEvent<unknown>, p: number) => {
    setPage(p);
  }, []);

  const startDisplayPosition = useMemo(() => {
    return query.totalCount === 0 ? 0 : Math.ceil((page - 1) * pageSize + 1);
  }, [page, pageSize, query.totalCount]);

  const endDisplayPosition = useMemo(() => {
    const endPosition = Math.ceil(page * pageSize);
    return endPosition > query.totalCount ? query.totalCount : endPosition;
  }, [page, pageSize, query.totalCount]);

  const totalPage = useMemo(() => {
    return Math.ceil(query.totalCount / pageSize);
  }, [pageSize, query.totalCount]);

  const handleOrderChange = useCallback(
    (columnIndex: number, orderDirection: 'asc' | 'desc') => {
      if (columnIndex === -1) {
        setOrderKey(null);
      } else {
        setOrderKey(`${orderDirection === 'desc' ? '-' : ''}${String(tableLayout?.currentLayout[columnIndex].field)}`);
      }
    },
    [tableLayout?.currentLayout]
  );

  const handleChangeSearchName = useDebounceCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setPage(1);
      setSearchName(e.target.value);
    },
    500,
    []
  );

  const handleChangeType = useCallback((type: string | null) => {
    setPage(1);
    setType(type);
  }, []);

  const handleChangeStatus = useCallback((status: string | null) => {
    setPage(1);
    setSelectedStatus(status);
  }, []);

  const handleClickCopy = useCallback(
    async (e: React.MouseEvent, data: InspectionListElement) => {
      const inspection = (query.data ?? []).find((i) => i.hashId === data.hashId);
      if (inspection === undefined) {
        return;
      }

      try {
        // eslint-disable-next-line no-shadow
        const {type, name, shouldMigrateProducts} = await dialogHandler.open<
          CopyInspectionDialogProps,
          CopyInspectionDialogResult
        >(CopyInspectionDialog, {
          defaultName: `${inspection.name}のコピー`,
        });

        await copyInspection(myInfo.hospitalHashId, inspection.hashId, {
          type: type,
          name: name,
          migrateProducts: shouldMigrateProducts,
        });

        query.refetch();
      } catch (_e) {
        console.error(_e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [query.data]
  );

  const handleClickDelete = useCallback(
    async (_e: React.MouseEvent, data: InspectionListElement) => {
      const inspection = (query.data ?? []).find((i) => i.hashId === data.hashId);
      if (inspection === undefined) return;

      const updated = await disableInspection(myInfo.hospitalHashId, inspection);
      if (updated) {
        query.refetch();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [myInfo.hospitalHashId, query.data]
  );

  const listData = useMemo<InspectionListElement[]>(() => {
    return (query.data ?? []).map((i) => ({
      hashId: i.hashId,
      name: {
        name: i.name,
        status: i.status,
      },
      type: InspectionTypeMap[i?.type]?.label ?? '',
      createdAt: formatRFC3339Date(i.createdAt, 'YYYY/MM/DD HH:mm') ?? '',
      updatedAt: formatRFC3339Date(i.updatedAt, 'YYYY/MM/DD HH:mm') ?? '',
      publishedAt:
        i.status === 'available' && i.publishedAt ? formatRFC3339Date(i.publishedAt, 'YYYY/MM/DD HH:mm') ?? '' : 'ー',
    }));
  }, [query.data]);

  const handleClickInspectionSetting = useCallback(
    async (hashId: string) => {
      try {
        await dialogHandler.open<InspectionSettingDialogProps>(InspectionSettingDialog, {});
        navigate(`/inspections/${hashId}`);
      } catch (e: unknown) {
        if (e) {
          console.error(e);
        }
      }
    },
    [navigate]
  );

  const handleClickEditProducts = useCallback(
    async (_e: React.MouseEvent, data: InspectionSettingListElement) => {
      const inspection = (query.data ?? []).find((i) => i.hashId === data.hashId);
      if (inspection === undefined) return;

      const selectedProductsValues = data.inspectionProducts?.map((item) => item.wholeProduct) ?? [];

      try {
        const products = await dialogHandler.open<EditProductsDialogProps, InspectionPeriodProduct[]>(
          EditProductsDialog,
          {
            hospitalHashId: myInfo.hospitalHashId,
            inspectionHashId: data.hashId,
            inspectionType: inspection.type,
            defaultValues: selectedProductsValues,
          }
        );

        const additionalProducts = products.filter(
          (item) => selectedProductsValues && !selectedProductsValues.some((i) => i.hashId === item.hashId)
        );

        const deletedProducts = selectedProductsValues
          ? selectedProductsValues.filter((item) => !products.some((i) => i.hashId === item.hashId))
          : [];

        await updateInspectionPeriods(myInfo.hospitalHashId, {
          inspectionHashId: data.hashId,
          addWholeProductHashIds: additionalProducts.map((i) => i.hashId),
          deleteWholeProductHashIds: deletedProducts.map((i) => i.hashId),
        });

        if (additionalProducts.length > 0 || deletedProducts.length > 0) {
          openSnackBar('対象機種を更新しました', 'center', 'top', 'success');

          // 定期点検の場合ダイアログ表示
          if (inspection.type === 'periodic') {
            handleClickInspectionSetting(data.hashId);
          }
        }

        query.refetch();
      } catch (e: unknown) {
        if (e) {
          console.error(e);
          openSnackBar(`機種追加に失敗しました: $e`, 'center', 'top', 'error');
        }
      }
    },
    [handleClickInspectionSetting, myInfo.hospitalHashId, query]
  );

  const serializedTableColumn = useTableColumns(tableLayout, handleClickEditProducts);

  /** マウスオーバーのボタンを定義 */
  const rowAction: RowAction<InspectionListElement>[] = useMemo(() => {
    const actions: RowAction<InspectionListElement>[] = [
      {
        type: 'button',
        label: '複製',
        onClick: handleClickCopy,
      },
    ];
    if (isAdmin) {
      actions.push({
        type: 'button',
        label: '削除',
        onClick: handleClickDelete,
      });
    }
    return actions;
  }, [handleClickCopy, handleClickDelete, isAdmin]);

  if (!serializedTableColumn) return null;

  return (
    <StyledRoot container>
      <StyledTableViewLayout>
        <TableViewLayout.Header>
          <SearchBar
            defaultSearchName={searchName ?? ''}
            defaultType={queryParams.get('type')}
            defaultStatus={queryParams.get('status')}
            onChangeSearchName={handleChangeSearchName}
            onChangeType={handleChangeType}
            onChangeStatus={handleChangeStatus}
            onChangeTableLayout={handleChangeTableLayout}
            tableLayout={tableLayout}
          />
        </TableViewLayout.Header>
        <TableViewLayout.Body>
          <Table<InspectionListElement>
            stickyHeader={true}
            showSelection={false}
            isLoading={query.isLoading}
            columns={serializedTableColumn}
            rowActions={rowAction}
            data={listData}
            rowActionIndex={ROW_ACTION_INDEX}
            onOrderChange={handleOrderChange}
            onRowClick={handleClickRow}
            tableSize="small"
          />
        </TableViewLayout.Body>
        <TableViewLayout.Footer container justifyContent="space-between">
          <PageDescriptionGrid item>
            {query.totalCount}件のうち{startDisplayPosition}件目-{endDisplayPosition}件目までを表示しています
          </PageDescriptionGrid>
          <PaginationContainerGrid item>
            <Pagination page={page} count={totalPage || 1} shape="rounded" onChange={handleChangePage} />
            <DisplayNumberSelect
              pageSize={pageSize}
              update={(selectNum) => {
                setPageSize(selectNum);
                setPage(1);
              }}
            />
          </PaginationContainerGrid>
          <EmptyGrid />
        </TableViewLayout.Footer>
      </StyledTableViewLayout>
    </StyledRoot>
  );
};
