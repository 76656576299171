import {Field, FormValue, NumericValidator as NumericFieldValidator, Section} from '@Apps/Inspection/types';
import {
  InspectionItem,
  NumericValidator as NumericItemValidator,
  UpdateInspectionParam,
} from '@modules/inspections/api';
import {InspectionTableIndex} from '@modules/inspections/types';
import {InspectionStatus} from '@modules/inspections/enum';

const fieldValidatorsToItemValidators = (vals: NumericFieldValidator[]): NumericItemValidator => {
  const itemVals = vals
    .map((v): NumericItemValidator | null => {
      switch (v.type) {
        case 'gt':
        case 'gte':
        case 'lt':
        case 'lte':
        case 'eq':
        case 'neq':
          return {
            op: v.type,
            value: v.value,
            errorText: v.errorText,
          };
        case 'between':
        case 'not-between': {
          const left = Math.min(v.lower, v.upper);
          const right = Math.max(v.lower, v.upper);
          return {
            op: v.type,
            lower: left,
            upper: right,
            errorText: v.errorText,
          };
        }
      }
      return null;
    })
    .filter((v): v is NumericItemValidator => v !== null);
  return {
    op: 'and',
    exprs: itemVals,
  };
};

const fieldsToItems = (fields: Field[], needFormValidation: boolean): InspectionItem[] => {
  return fields
    .map((f): InspectionItem | null => {
      const base = {
        name: f.name,
        description: f.settings.showsDescription && f.description ? f.description : '',
        inspectionPoint: f.settings.showsInspectionPoint && f.inspectionPoint ? f.inspectionPoint : '',
        required: f.required,
      };
      switch (f.type) {
        case 'pull-down':
          if (needFormValidation && (!f.options || f.options.length === 0)) {
            throw new Error('選択肢が空のフィールドがあります');
          }
          return {
            ...base,
            type: 'select',
            view: 'pull-down',
            options: f.options,
            validator: f.settings.showsValidator ? f.validators : null,
          };
        case 'radio':
          if (needFormValidation && (!f.options || f.options.length === 0)) {
            throw new Error('選択肢が空のフィールドがあります');
          }
          return {
            ...base,
            type: 'select',
            view: 'radio',
            options: f.options,
            validator: f.settings.showsValidator ? f.validators : null,
          };
        case 'checkbox':
          if (needFormValidation && (!f.options || f.options.length === 0)) {
            throw new Error('選択肢が空のフィールドがあります');
          }
          return {
            ...base,
            type: 'multi-select',
            options: f.options,
            validator: f.settings.showsValidator ? f.validators : null,
          };
        case 'numeric':
          return {
            ...base,
            type: 'numeric',
            unit: f.unit,
            validator: f.settings.showsValidator ? fieldValidatorsToItemValidators(f.validators) : null,
          };
        case 'text':
          return {
            ...base,
            type: 'text',
          };
        case 'multiline-text':
          return {
            ...base,
            type: 'multiline-text',
          };
        case 'date':
          return {
            ...base,
            type: 'date',
          };
        case 'time':
          return {
            ...base,
            type: 'time',
          };
        default:
          return {
            ...base,
            type: null,
          };
      }
    })
    .filter((item): item is InspectionItem => item !== null);
};

const fieldSectionsToItemSections = (sections: Section[], needFormValidation: boolean): InspectionItem[] => {
  return sections.map((section) => {
    const {name, fields} = section;
    const items = fieldsToItems(fields, needFormValidation);
    return {
      type: 'section',
      name: name,
      description: '',
      inspectionPoint: '',
      items: items,
    };
  });
};

export const formValueToInspection = (value: FormValue, status: InspectionStatus): UpdateInspectionParam => {
  // draftの場合はバリデーションを行わない
  const items = fieldSectionsToItemSections(value.sections, status !== 'draft');
  return {
    name: value.name,
    status: status,
    items: items,
  };
};

/*
 * Item -> Field
 */
const itemValidatorToFieldValidators = (validator: NumericItemValidator): NumericFieldValidator[] => {
  // currently only expects and as root validator
  if (validator.op === 'and') {
    return validator.exprs
      .map((e): NumericFieldValidator | null => {
        switch (e.op) {
          case 'gt':
          case 'gte':
          case 'lt':
          case 'lte':
          case 'eq':
          case 'neq':
            return {
              type: e.op,
              value: e.value,
              errorText: e.errorText ? e.errorText : undefined,
            };
          case 'between':
          case 'not-between': {
            return {
              type: e.op,
              lower: e.lower,
              upper: e.upper,
              errorText: e.errorText ? e.errorText : undefined,
            };
          }
        }
        return null;
      })
      .filter((v): v is NumericFieldValidator => v !== null);
  }
  return [];
};

const itemToField = (item: InspectionItem): Field | null => {
  const baseField = {
    id: item.id,
    name: item.name,
    description: item.description ? item.description : '',
    inspectionPoint: item.inspectionPoint ? item.inspectionPoint : '',
  };

  const baseSettings = {
    showsInspectionPoint: !!item.inspectionPoint,
    showsDescription: !!item.description,
  };

  switch (item.type) {
    case 'select':
      return {
        ...baseField,
        type: item.view,
        required: item.required,
        options: item.options,
        validators: item.validator
          ? item.validator
          : {
              values: [],
            },
        settings: {
          ...baseSettings,
          showsValidator: item.validator !== null,
        },
      };
    case 'multi-select':
      return {
        ...baseField,
        type: 'checkbox',
        required: item.required,
        options: item.options,
        validators: item.validator
          ? item.validator
          : {
              values: [],
            },
        settings: {
          ...baseSettings,
          showsValidator: item.validator !== null,
        },
      };
    case 'numeric':
      return {
        ...baseField,
        type: 'numeric',
        required: item.required,
        unit: item.unit,
        validators: item.validator ? itemValidatorToFieldValidators(item.validator) : [],
        settings: {
          ...baseSettings,
          showsValidator: item.validator !== null,
        },
      };
    case 'text':
      return {
        ...baseField,
        type: 'text',
        required: item.required,
        settings: baseSettings,
      };
    case 'multiline-text':
      return {
        ...baseField,
        type: 'multiline-text',
        required: item.required,
        settings: baseSettings,
      };
    case 'date':
      return {
        ...baseField,
        type: 'date',
        required: item.required,
        settings: baseSettings,
      };
    case 'time':
      return {
        ...baseField,
        type: 'time',
        required: item.required,
        settings: baseSettings,
      };
    case null:
      return {
        ...baseField,
        type: '',
        required: false,
        settings: baseSettings,
      };
  }
  return null;
};

export const inspectionTableToSections = (table: InspectionTableIndex): Section[] => {
  return table.items
    .map((item): Section | null => {
      // item at top level should be section
      if (item.type === 'section') {
        const fields = item.items.map(itemToField).filter((i): i is Field => i !== null);
        return {
          id: item.id,
          name: item.name,
          fields: fields,
        };
      }
      return null;
    })
    .filter((i): i is Section => i !== null);
};
