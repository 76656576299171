import {Cacheable as Cashable, ICache} from '@auth0/auth0-spa-js';
import {User} from '@auth0/auth0-react';
import {EventEmitter} from 'events';

export const CHANGE_CURRENT_USER_EVENT = 'CHANGE_CURRENT_USER';
export const CHANGE_USERS_EVENT = 'CHANGE_USERS';

const CACHE_KEY_PREFIX = '@@auth0spajs@@';
const AUTH0_MULTI_CACHE_KEY_PREFIX = '@@hitotsu-front@@';
const AUTH0_MULTI_CACHE_USER_SUB_KEY = `${AUTH0_MULTI_CACHE_KEY_PREFIX}_user_sub`;
const AUTH0_MULTI_CACHE_USERS_KEY = `${AUTH0_MULTI_CACHE_KEY_PREFIX}_users`;
const AUTH0_MULTI_CACHE_USER_STORAGES_KEY = `${AUTH0_MULTI_CACHE_KEY_PREFIX}_storages`;

type Users = Record<string, User>;
type UserStorages = Record<string, Record<string, string>>;

export class MultipleAuth0Cache extends EventEmitter implements ICache {
  public users: Users = {};
  public userStorages: UserStorages = {};
  public currentUser: User | null = null;

  constructor(shouldLoadCurrentUser?: boolean) {
    super();
    const users = localStorage.getItem(AUTH0_MULTI_CACHE_USERS_KEY);
    if (users) {
      this.users = JSON.parse(users) as Users;
    }
    const storages = localStorage.getItem(AUTH0_MULTI_CACHE_USER_STORAGES_KEY);
    if (storages) {
      this.userStorages = JSON.parse(storages) as UserStorages;
    }

    if (shouldLoadCurrentUser) {
      this.loadCurrentUser();
    }
  }

  public loadCurrentUser() {
    const currentUserSub = localStorage.getItem(AUTH0_MULTI_CACHE_USER_SUB_KEY);
    if (currentUserSub !== null && this.users[currentUserSub]) {
      this.currentUser = this.users[currentUserSub];
      this._restoreAuth0Data(this.currentUser);
      this.emit(CHANGE_CURRENT_USER_EVENT, this.currentUser);
    }
  }

  public isLoggedIn() {
    return this.currentUser != null;
  }

  public getCurrentUser() {
    return this.currentUser;
  }

  public getUsers() {
    const users: User[] = [];
    Object.keys(this.users).forEach((k) => {
      users.push(this.users[k]);
    });
    return users;
  }

  // 現在サインインしているユーザーを登録する
  public addCurrentUser(user: User) {
    this._backupAuth0Data(user);
    this._addUser(user);
    // biome-ignore lint/style/noNonNullAssertion: 非nullアサーション演算子(!)
    this.switchUser(user.sub!);
  }

  public switchUser(userSub: string): User | null {
    const targetUser = this.users[userSub];
    if (targetUser && targetUser.sub !== this.currentUser?.sub) {
      this.currentUser = targetUser;
      // biome-ignore lint/style/noNonNullAssertion: 非nullアサーション演算子(!)
      localStorage.setItem(AUTH0_MULTI_CACHE_USER_SUB_KEY, targetUser.sub!);
      this._restoreAuth0Data(targetUser);
      this._emitChangeCurrentUserEvent();
      return this.currentUser;
    }
    return null;
  }

  public signOutCurrentUser(): boolean {
    if (this.currentUser) {
      const targetUser = this.currentUser;
      this.currentUser = null;
      // biome-ignore lint/style/noNonNullAssertion: 非nullアサーション演算子(!)
      delete this.users[targetUser.sub!];
      // biome-ignore lint/style/noNonNullAssertion: 非nullアサーション演算子(!)
      delete this.userStorages[targetUser.sub!];

      localStorage.removeItem(AUTH0_MULTI_CACHE_USER_SUB_KEY);
      localStorage.setItem(AUTH0_MULTI_CACHE_USERS_KEY, JSON.stringify(this.users));
      localStorage.setItem(AUTH0_MULTI_CACHE_USER_STORAGES_KEY, JSON.stringify(this.userStorages));

      if (Object.keys(this.users).length > 0) {
        this.currentUser = this.users[Object.keys(this.users)[0]];
        // biome-ignore lint/style/noNonNullAssertion: 非nullアサーション演算子(!)
        localStorage.setItem(AUTH0_MULTI_CACHE_USER_SUB_KEY, this.currentUser.sub!);
        this._restoreAuth0Data(this.currentUser);
      }
      this._emitChangeCurrentUserEvent();
      this._emitChangeUsersEvent();
      return true;
    }
    return false;
  }

  public removeCurrentUserSub() {
    localStorage.removeItem(AUTH0_MULTI_CACHE_USER_SUB_KEY);
  }

  public clearAuth0Caches(shouldBackup: true) {
    if (shouldBackup && this.currentUser !== null) {
      this._backupAuth0Data(this.currentUser);
    }
    this.currentUser = null;

    // clear cache
    for (const key of this.allKeys()) {
      localStorage.removeItem(key);
    }

    this._emitChangeCurrentUserEvent();
  }

  private _backupAuth0Data(user: User) {
    const userKeys = Object.keys(localStorage).filter((key) => key.startsWith(CACHE_KEY_PREFIX));
    const newUserStorage: {[key: string]: string} = {};
    for (const key of userKeys) {
      newUserStorage[key] = localStorage.getItem(key) as string;
    }
    if (user && user.sub) this.userStorages[user.sub] = newUserStorage;
    localStorage.setItem(AUTH0_MULTI_CACHE_USER_STORAGES_KEY, JSON.stringify(this.userStorages));
  }

  private _restoreAuth0Data(user: User) {
    const userStorage = (user && user.sub && this.userStorages[user.sub]) ?? undefined;
    // 現在セットされているAuth0関連のデータを削除
    this.allKeys().forEach((key) => {
      localStorage.removeItem(key);
    });

    if (!userStorage) return;
    // userStorageに保存されているAuth0関連のデータをセット
    Object.keys(userStorage).forEach((key) => {
      localStorage.setItem(key, userStorage[key] + '');
    });
  }

  private _addUser(user: User) {
    // biome-ignore lint/style/noNonNullAssertion: 非nullアサーション演算子(!)
    const exists = this.users[user.sub!] !== undefined;
    // biome-ignore lint/style/noNonNullAssertion: 非nullアサーション演算子(!)
    this.users = {...this.users, [user.sub!]: user};
    localStorage.setItem(AUTH0_MULTI_CACHE_USERS_KEY, JSON.stringify(this.users));
    if (!exists) {
      this._emitChangeUsersEvent();
    }
  }

  private _emitChangeCurrentUserEvent() {
    this.emit(CHANGE_CURRENT_USER_EVENT, this.currentUser);
  }

  private _emitChangeUsersEvent() {
    // console.log('_emitChangeUsersEvent', this.getUsers());
    this.emit(CHANGE_USERS_EVENT, this.getUsers());
  }

  // Auth0のICache向けメソッド
  public set<T = Cashable>(key: string, entry: T) {
    localStorage.setItem(key, JSON.stringify(entry));
  }

  public get<T = Cashable>(key: string) {
    const json = localStorage.getItem(key);

    if (!json) return null;

    try {
      const payload = JSON.parse(json) as T;
      return payload;
    } catch (e) {
      /* istanbul ignore next */
    }
    return null;
  }

  public remove(key: string) {
    localStorage.removeItem(key);
  }

  public allKeys() {
    return Object.keys(window.localStorage).filter((key) => key.startsWith(CACHE_KEY_PREFIX));
  }
}
