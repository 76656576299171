import {ViewInspectionResultStatus, ViewInspectionType} from './types';

export const ViewInspectionTypes = ['daily', 'pre_use', 'in_use', 'post_use', 'periodic', 'maker_periodic'] as const;
// TODO:HIT-4529 SP参照あり
export const ViewInspectionTypeToText: Record<(typeof ViewInspectionTypes)[number], string> = {
  daily: '日常点検',
  pre_use: '使用前点検',
  in_use: '使用中点検',
  post_use: '使用後点検',
  periodic: '定期点検',
  maker_periodic: 'メーカー保守点検',
};

type InspectionStatusMenu = {value: ViewInspectionResultStatus; label: string};

const getLabelForUnplannedInspection = (inspectionType?: ViewInspectionType) => {
  if (inspectionType === 'pre_use' || inspectionType === 'in_use') {
    return '対象機器';
  }

  if (inspectionType === 'periodic') {
    return '実施予定';
  }

  return '未実施';
};

// TODO:HIT-4529 V2参照あり
export const getInspectionStatusMenus: (
  inspectionType?: (typeof ViewInspectionTypes)[number]
) => InspectionStatusMenu[] = (inspectionType) =>
  [
    {
      label: getLabelForUnplannedInspection(inspectionType),
      value: 'unplanned',
    },
    inspectionType === 'periodic'
      ? {
          label: '予定月超過',
          value: 'overdue',
        }
      : null,
    {
      label: '実施途中',
      value: 'uncompleted',
    },
    {
      label: '完了',
      value: 'completed',
    },
    {
      label: 'スキップ',
      value: 'skipped',
    },
  ].filter((menu): menu is InspectionStatusMenu => menu !== null);
