import {NestedMenuListItemType} from '@components/molecules/NestedMenuList';
import {DeviceSplitMenuValueType} from './type';

export const MENU_ITEM_TIMELINE_VALUE = 'timeline';
export const MENU_ITEM_FILE_VALUE = 'file';
export const MENU_ITEM_PMDA_FILE_VALUE = 'pmdaFile';
export const MENU_ITEM_INSPECTION_REPAIR_VALUE = 'inspectionAndRepair';

export const PRODUCT_DETAIL_MENU_ITEMS = [
  {label: 'タイムライン', value: MENU_ITEM_TIMELINE_VALUE},
  {label: 'ファイル', value: MENU_ITEM_FILE_VALUE},
  {label: '添付文書', value: MENU_ITEM_PMDA_FILE_VALUE},
  {label: '点検・修理予定', value: MENU_ITEM_INSPECTION_REPAIR_VALUE},
];

export const RelinkMenuListItems: NestedMenuListItemType<DeviceSplitMenuValueType>[] = [
  {
    label: '別機種に再紐付け',
    value: [
      {
        label: '登録済みの院内機種に再紐付け',
        value: 'registered',
      },
      {
        label: '院内で未登録の機種に再紐付け',
        value: 'unregistered',
      },
    ],
  },
  {
    label: '機器を流用して機器を新規登録',
    value: 'copy',
  },
];

export const RelinkAddDeleteMenuListItem: NestedMenuListItemType<DeviceSplitMenuValueType>[] = [
  ...RelinkMenuListItems,
  {
    label: '機器情報を削除',
    value: 'delete',
    sx: {color: ' #C7243A'},
  },
];
