import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import clsx from 'clsx';
import {useAtomValue, useSetAtom} from 'jotai';
import {Grid, makeStyles} from '@material-ui/core';
import {defaultHeightWidth, setOuterHeight, setOuterWidth, useTemplate} from '@templates/RentalTemplate';
import {useAsyncEffect} from '@front-libs/core';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {bulkUpdateRentals, DeviceLender, FetchRentalsParams, getRentals} from '@modules/rentals/api';
import {RentalHistoryIndex} from '@modules/rentals/types';
import {
  defaultBarcodeScannerTypeAtom,
  canUseDeviceCameraAtom,
  rentalSettingAtom,
  rentStepperAtom,
  returnLenderNameAtom,
} from '../../states';
import {Footer} from '../../Footer';
import {Header} from '../../Header';
import {NoRowsContent} from '../../NoRowsContent';
import {RentalTable} from '../RentalTable';
import {LenderTable} from '../LenderTable';
import {ReturnButton} from '../ReturnButton';
import {RentalErrorDialog} from '../../RentalErrorDialog';
import {StrUtil} from '@front-libs/helpers';

export const ReturnByBarcodeReader: React.FC = () => {
  const classes = useStyles();
  const templateClasses = useTemplate();
  const {myInfo} = useMyInfo();
  const [returnHospitalProducts, setReturnHospitalProducts] = useState<RentalHistoryIndex[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [managementId, setManagementId] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();
  const returnLenderName = useAtomValue(returnLenderNameAtom);
  const setRentalStepper = useSetAtom(rentStepperAtom);
  const hospitalRentalSetting = useAtomValue(rentalSettingAtom);
  const canUseDeviceCamera = useAtomValue(canUseDeviceCameraAtom);
  const setDefaultBarcodeScannerType = useSetAtom(defaultBarcodeScannerTypeAtom);

  const deviceLender: DeviceLender | undefined = useMemo(() => {
    if (hospitalRentalSetting === 'not_need') return undefined;

    return {
      userId: hospitalRentalSetting === 'by_barcode' ? returnLenderName ?? undefined : undefined,
      userName: hospitalRentalSetting === 'by_direct_input' ? returnLenderName ?? undefined : undefined,
    };
  }, [hospitalRentalSetting, returnLenderName]);

  const handleClickCancel = useCallback(
    (rowIndex: number) => {
      setReturnHospitalProducts(returnHospitalProducts.filter((_item, idx) => idx !== rowIndex));
    },
    [returnHospitalProducts]
  );

  const handleClickSwitchDeviceCamera = useCallback(() => {
    if (canUseDeviceCamera) {
      setDefaultBarcodeScannerType('camera');
      navigate('/shared/return/product/camera');
    }
  }, [canUseDeviceCamera, navigate, setDefaultBarcodeScannerType]);

  const handleBarcodeReaderInput = useCallback(
    async (event: React.KeyboardEvent<HTMLInputElement>) => {
      // Enter以外のキーを検知したらmanagementIdに連結する
      if (event.key !== 'Enter') {
        setManagementId(managementId + event.key);
        return;
      } else if (event.key === 'Enter' && managementId.length === 0) {
        return;
      }
      setManagementId('');

      // Enterを検知したら、managementIdをもとにレンタル情報を検索する
      let parameterManagementId = StrUtil.toHankaku(managementId);
      if (parameterManagementId[0] === '!') {
        parameterManagementId = parameterManagementId.slice(0, -1);
        const index = parameterManagementId.indexOf('('); // `(` のインデックスを取得
        if (index !== -1) {
          // `(` が見つかった場合
          parameterManagementId = parameterManagementId.slice(index); // `(` の次の文字から末尾までを取得
        }
      }

      const params: FetchRentalsParams = {
        status: 'renting',
        managementId: parameterManagementId,
      };
      const {data, totalCount} = await getRentals(myInfo.hospitalHashId, params);
      if (totalCount > 0) {
        setReturnHospitalProducts([
          ...returnHospitalProducts,
          ...data.filter((item) => !returnHospitalProducts.some((d) => d.hashId === item.hashId)),
        ]);
      } else {
        setOpenDialog(true);
      }
    },
    [managementId, myInfo.hospitalHashId, returnHospitalProducts]
  );

  const handleSubmit = async () => {
    if (!isSubmitting) setIsSubmitting(true);
  };
  useAsyncEffect(async () => {
    if (!isSubmitting) return;
    await bulkUpdateRentals(
      myInfo.hospitalHashId,
      returnHospitalProducts.map((item) => ({
        rentalHashId: item.hashId,
        returnUserHashId: myInfo.hashId,
        returnDeviceLender: deviceLender,
      }))
    );
    navigate('/shared/complete');
    setRentalStepper(2);
  }, [isSubmitting]);

  useEffect(() => {
    setRentalStepper(1);
  }, [setRentalStepper]);

  const focusInput = useCallback(
    (e: React.FocusEvent<HTMLInputElement, Element>) => {
      if (!openDialog) e.target.focus();
    },
    [openDialog]
  );

  useEffect(() => {
    window.addEventListener('resize', () => {
      setOuterHeight();
      setOuterWidth();
    });
    setOuterHeight();
    setOuterWidth();
    return () => {
      window.removeEventListener('resize', () => {
        setOuterHeight();
        setOuterWidth();
      });
    };
  }, []);

  return (
    <>
      <Header title={'返却'} />
      <Grid
        item
        id={'mainContent'}
        className={clsx(templateClasses.content, classes.contentContainer)}
        style={defaultHeightWidth}>
        <input
          type="text"
          autoFocus
          readOnly
          onBlur={focusInput}
          value={managementId}
          onKeyPress={handleBarcodeReaderInput}
          className={classes.barcodeInput}
        />

        {returnHospitalProducts.length === 0 ? (
          <NoRowsContent />
        ) : (
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item className={classes.rentalTableContainer}>
              <RentalTable returnHospitalProducts={returnHospitalProducts} onClickCancel={handleClickCancel} />
            </Grid>
            <Grid item container alignItems="flex-end" className={classes.operationContainer}>
              {returnLenderName && (
                <Grid item className={classes.operatorTableGridItem}>
                  <LenderTable lender={returnLenderName} />
                </Grid>
              )}
              <Grid item style={{width: '100%'}}>
                <ReturnButton label={'返却する'} onChange={handleSubmit} />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Footer
        text={'返却する医療機器の\nバーコードを読み取って下さい'}
        nextButtonLabel={canUseDeviceCamera ? 'カメラで読み取る' : undefined}
        onClickNextButton={handleClickSwitchDeviceCamera}
      />
      <RentalErrorDialog open={openDialog} onClickButton={() => setOpenDialog(false)} status={null} type="return" />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    paddingLeft: 32,
    paddingRight: 32,
    [theme.breakpoints.up('tabletH')]: {
      paddingLeft: 60,
      paddingRight: 60,
    },
    [theme.breakpoints.up('desktop')]: {
      paddingLeft: 80,
      paddingRight: 80,
    },
  },
  rentalTableContainer: {
    maxHeight: '100%',
    overflow: 'scroll',
    height: 'fit-content',
    width: '65%',
    [theme.breakpoints.up('laptop')]: {
      width: '70%',
    },
  },
  barcodeInput: {
    position: 'absolute',
    top: 20,
    marginTop: '-550px',
    '@media screen and (orientation: portrait)': {
      marginTop: '0px',
      marginLeft: '-5500px',
    },
  },
  operationContainer: {
    width: '25%',
    height: 408,
    paddingBottom: 24,
    overflow: 'scroll',
    maxHeight: '100%',
    gap: '20px',
    [theme.breakpoints.up('laptop')]: {
      paddingBottom: 16,
      paddingTop: 16,
    },
  },
  operatorTableGridItem: {
    width: '100%',
    marginTop: '48px',
    [theme.breakpoints.up('laptop')]: {
      marginTop: '28px',
    },
  },
}));
