import React, {useCallback} from 'react';
import {InspectionMenuHeader} from '@components/organisms/InspectionMenuHeader';
import {InspectionScheduleBackButton} from '@components/organisms/InspectionMenuHeader/InspectionScheduleBackButton';
import {useNavigate} from 'react-router-dom';
import {withSuspense} from '@front-libs/core';
import {ListPageLayout} from '@components/layouts/ListPageLayout';
import {InspectionList} from './InspectionList';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {InspectionType} from '@modules/inspections/enum';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {
  NewInspectionDialog,
  NewInspectionDialogProps,
  NewInspectionDialogResult,
} from '@molecules/Dialogs/NewInspectionDialog';
import {createInspection} from '@modules/inspections/api';
import {DEFAULT_INSPECTION_NAME} from '@Apps/Inspection/states';
import {openSnackBar} from '@components/molecules/SnackBar';
import {MenuItemType, PopperMenuButton} from '@components/molecules/Buttons/PopperMenuButton';

type CreateInspectionType = 'blank' | 'template';

const createInspectionOptions: {label: string; value: CreateInspectionType}[] = [
  {
    label: '新規で点検表を作成',
    value: 'blank',
  },
  {
    label: 'テンプレートを元に作成',
    value: 'template',
  },
];

/**
 * @param param0
 * @returns
 */
const MenuButton = ({
  onClickHandler,
}: {
  onClickHandler: (item: MenuItemType, event: React.MouseEvent<Document, MouseEvent>) => void;
}) => {
  return (
    <PopperMenuButton
      placement="bottom-start"
      hiddenArrow={false}
      buttonProps={{variant: 'text', style: {width: '100%', color: 'white', backgroundColor: '#0A52CC'}}}
      menuItemList={createInspectionOptions}
      itemProps={{style: {minWidth: '100px'}}}
      onMenuClick={onClickHandler}>
      点検表を作成
    </PopperMenuButton>
  );
};

const newInspection = async (hospitalHashId: string): Promise<string | null> => {
  let type: InspectionType;
  try {
    const res = await dialogHandler.open<NewInspectionDialogProps, NewInspectionDialogResult>(NewInspectionDialog, {});
    type = res.type;
  } catch (_e) {
    return null;
  }

  try {
    const res = await createInspection(hospitalHashId, {
      name: DEFAULT_INSPECTION_NAME,
      type: type,
      status: 'draft',
    });

    return res.data.hashId;
  } catch (e) {
    console.error(e);
    openSnackBar('点検表作成に失敗しました', 'left', 'bottom', 'error');
  }

  return null;
};

const _InspectionTableListV2: React.FC = () => {
  const navigate = useNavigate();
  const {myInfo} = useMyInfo();
  const onClickNewInspection = useCallback(
    async (event: MouseEvent) => {
      const hashId = await newInspection(myInfo.hospitalHashId);
      if (hashId !== null) {
        navigate(`/inspections/${hashId}`);
      }
    },
    [myInfo.hospitalHashId, navigate]
  );
  const handleMenuClick = useCallback(
    (item: MenuItemType, event: React.MouseEvent<Document, MouseEvent>) => {
      switch (item.value) {
        case 'blank':
          onClickNewInspection(event as never);
          break;
        case 'template':
          navigate(`/inspection/templates`);
          break;
      }
    },
    [navigate, onClickNewInspection]
  );

  return (
    <ListPageLayout page="inspection_table_list">
      <ListPageLayout.Header>
        <InspectionScheduleBackButton />
      </ListPageLayout.Header>
      <InspectionMenuHeader node={<MenuButton onClickHandler={handleMenuClick} />} />
      <ListPageLayout.Content>
        <InspectionList />
      </ListPageLayout.Content>
    </ListPageLayout>
  );
};

export const InspectionTableListV2 = withSuspense(_InspectionTableListV2, null);
