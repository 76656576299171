import React, {useCallback, useEffect, useMemo} from 'react';
import {useGetPeriodicInspectionsAnnualPlanQuery} from '@modules/dashboard/api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {useGetHospitalCategories} from '@modules/categories/api';
import {
  AnnualPlanTable,
  AnnualPlanTableBodyCell,
  AnnualPlanTableCategoryBodyCell,
  AnnualPlanTableCategoryHeadCell,
  AnnualPlanTableHeadCell,
} from './AnnualPlanTableCell';
import {makeStyles, Paper, TableBody, TableContainer, TableHead, TableRow} from '@material-ui/core';
import {useUpdateDashboardSubject, UpdatePaperType} from '../hooks';
import {PaperHeader} from '../PaperHeader';
import {papers} from '../consts';
import {useHospitalCategoryTreesQuery} from '@modules/categories/hooks/useCategoryTrees';
import _ from 'lodash';
import {CollapsedTableRow} from './CollapsedTableRow';
import {useNavigate} from 'react-router-dom';
import {convertDateToRFC3339, isNullish} from '@front-libs/helpers';
import {
  useInspectionType,
  useNarrowCategory,
  useRootCategory,
  useScheduledTimeRange,
} from '@Apps/InspectionV2/InspectionResultList/pc/InternalInspection/states/states';
import dayjs from 'dayjs';

const NARROW_CATEGORY_DEPTH = 1;

const defaultMonthlyCounts = Array<number>(12).fill(0);

// NOTE: 分類のFILTER項目。医療機器のみ表示する場合はtrueにする。
const IS_MEDICAL_DEVICE_CATEGORY = true;

export const InspectionCalendar: React.FC = () => {
  const classes = useStyles();
  const {myInfo} = useMyInfo();
  const navigate = useNavigate();
  const {subscribe, unsubscribe} = useUpdateDashboardSubject();
  const paper = papers.inspectionCalendar;

  const [, setInspectionType] = useInspectionType();
  const [, setScheduledTimeRange] = useScheduledTimeRange();
  const [, setRootCategory] = useRootCategory();
  const [, setNarrowCategory] = useNarrowCategory();

  const {data: categoryTrees} = useHospitalCategoryTreesQuery(myInfo.hospitalHashId, IS_MEDICAL_DEVICE_CATEGORY);
  const {data: narrowCategories, isLoading: isLoadingCategories} = useGetHospitalCategories(myInfo.hospitalHashId, {
    depth: NARROW_CATEGORY_DEPTH,
  });
  const {
    data: inspectionPlans,
    isLoading: isLoadingInspectionPlan,
    refetch: refetchInspectionPlan,
  } = useGetPeriodicInspectionsAnnualPlanQuery(myInfo.hospitalHashId);

  const newDisplayedData = useMemo(() => {
    if (isLoadingCategories || isLoadingInspectionPlan) return [];

    const displayCategoryTrees: {
      categoryHashId: string;
      categoryName: string;
      monthlyCount: number[];
      narrowCategories: {
        categoryHashId: string;
        categoryName: string;
        monthlyCount: number[];
      }[];
    }[] = [];

    narrowCategories.forEach((narrowCategory) => {
      const tree = categoryTrees.find((item) =>
        item.narrowCategories.find((innerItem) => innerItem.hashId === narrowCategory.hashId)
      );
      if (!tree) return;

      const plan = inspectionPlans?.find((ip) => ip.category.hashId === narrowCategory.hashId);
      const counts = [...defaultMonthlyCounts];
      if (plan) {
        plan.inspectionPlans.forEach((i) => {
          counts[i.month - 1] = i.numberOfInspection;
        });
      }

      // displayCategoryTreesにtreeが存在するか確認
      const target = displayCategoryTrees.find((item) => item.categoryName === tree.rootCategory.name);
      if (target) {
        // 大分類が存在する場合は、narrowCategoriesにcategoryを追加
        target.narrowCategories.push({
          categoryHashId: narrowCategory.hashId,
          categoryName: narrowCategory.name,
          monthlyCount: counts,
        });
        target.monthlyCount = target.monthlyCount.map((count, index) => count + counts[index]);
      } else {
        // 大分類が存在しない場合は、displayCategoryTreesにtreeを追加
        displayCategoryTrees.push({
          categoryHashId: tree.rootCategory.hashId,
          categoryName: tree.rootCategory.name,
          monthlyCount: counts,
          narrowCategories: [
            {
              categoryHashId: narrowCategory.hashId,
              categoryName: narrowCategory.name,
              monthlyCount: counts,
            },
          ],
        });
      }
    });

    return displayCategoryTrees.sort((a, b) => _.sum(b.monthlyCount) - _.sum(a.monthlyCount));
  }, [narrowCategories, categoryTrees, inspectionPlans, isLoadingCategories, isLoadingInspectionPlan]);

  const monthlyCountSum = useMemo(() => {
    if (isLoadingCategories || isLoadingInspectionPlan) return [];
    const monthlyCountSumArr: number[] = Array.of(12).fill(0);
    newDisplayedData.forEach((item) => {
      item.monthlyCount.forEach((count, index) => {
        if (monthlyCountSumArr[index]) {
          monthlyCountSumArr[index] += count;
        } else {
          monthlyCountSumArr[index] = count;
        }
      });
    });

    return monthlyCountSumArr;
  }, [isLoadingCategories, isLoadingInspectionPlan, newDisplayedData]);

  const onUpdateDashboardSubject = useCallback(
    (paperType: UpdatePaperType) => {
      if (paperType !== 'all' && paperType !== 'inspection_calendar') return;
      refetchInspectionPlan();
    },
    [refetchInspectionPlan]
  );

  const onClickCount = useCallback(
    (month: number, categoryHashId?: string, isRootCategory?: boolean) => {
      const date = dayjs().set('month', month - 1);

      const scheduledAtFrom = date.startOf('month').toDate();
      const scheduledAtTo = date.endOf('month').toDate();

      setInspectionType('periodic');
      setScheduledTimeRange([scheduledAtFrom, scheduledAtTo]);

      let search = `scheduledTimeFrom=${convertDateToRFC3339(scheduledAtFrom)}&scheduledTimeTo=${convertDateToRFC3339(
        scheduledAtTo
      )}`;

      if (!isNullish(categoryHashId)) {
        if (isRootCategory) {
          setRootCategory(categoryHashId);
          setNarrowCategory(null);
        } else {
          setRootCategory(null);
          setNarrowCategory(categoryHashId);
        }
        search += `&categories=${categoryHashId}`;
      }

      navigate(`/inspection_v2/results?type=periodic&status=unplanned&${search}`);
    },
    [navigate, setInspectionType, setNarrowCategory, setRootCategory, setScheduledTimeRange]
  );

  useEffect(() => {
    subscribe(onUpdateDashboardSubject);
    return () => unsubscribe(onUpdateDashboardSubject);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper className={classes.paper}>
      <PaperHeader title={paper.label} />
      <div className={classes.root}>
        <TableContainer classes={{root: classes.customTableContainer}}>
          <AnnualPlanTable stickyHeader={true}>
            <TableHead className={classes.tableHead}>
              <TableRow>
                <AnnualPlanTableCategoryHeadCell>分類</AnnualPlanTableCategoryHeadCell>
                <AnnualPlanTableHeadCell>1月</AnnualPlanTableHeadCell>
                <AnnualPlanTableHeadCell>2月</AnnualPlanTableHeadCell>
                <AnnualPlanTableHeadCell>3月</AnnualPlanTableHeadCell>
                <AnnualPlanTableHeadCell>4月</AnnualPlanTableHeadCell>
                <AnnualPlanTableHeadCell>5月</AnnualPlanTableHeadCell>
                <AnnualPlanTableHeadCell>6月</AnnualPlanTableHeadCell>
                <AnnualPlanTableHeadCell>7月</AnnualPlanTableHeadCell>
                <AnnualPlanTableHeadCell>8月</AnnualPlanTableHeadCell>
                <AnnualPlanTableHeadCell>9月</AnnualPlanTableHeadCell>
                <AnnualPlanTableHeadCell>10月</AnnualPlanTableHeadCell>
                <AnnualPlanTableHeadCell>11月</AnnualPlanTableHeadCell>
                <AnnualPlanTableHeadCell>12月</AnnualPlanTableHeadCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow style={{background: '#D6E8FC'}}>
                <AnnualPlanTableCategoryBodyCell style={{fontSize: 16, background: 'inherit', fontWeight: 700}}>
                  合計
                </AnnualPlanTableCategoryBodyCell>
                {monthlyCountSum.map((item, idx) => {
                  return (
                    <AnnualPlanTableBodyCell key={idx} hasCount={item > 0} style={{fontSize: 16}}>
                      {item === 0 ? (
                        '-'
                      ) : (
                        <span style={{cursor: 'pointer'}} onClick={() => onClickCount(idx + 1)}>
                          {item}
                        </span>
                      )}
                    </AnnualPlanTableBodyCell>
                  );
                })}
              </TableRow>
              {newDisplayedData.map((item, outerIndex) => (
                <CollapsedTableRow onClickCount={onClickCount} {...item} key={outerIndex} />
              ))}
            </TableBody>
          </AnnualPlanTable>
        </TableContainer>
      </div>
    </Paper>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '0px 16px 16px',
    borderTop: `4px solid ${theme.palette.primary.dark}`,
    height: 'calc(100% - 20px)',
  },
  root: {
    width: '100%',
    maxHeight: '80%',
    overflowX: 'scroll',
    marginTop: 24,
  },
  tableHead: {
    position: 'sticky',
    top: 0,
    zIndex: 1000,
  },
  customTableContainer: {
    overflowX: 'initial',
  },
}));
