import {Button, Grid, makeStyles, TextField} from '@material-ui/core';
import {Search} from '@material-ui/icons';
import React, {useCallback, useState} from 'react';
import {PopperSelectBoxButton, SelectOptionProps} from '@molecules/Buttons/PopperSelectBoxButton';
import {CategoryFormatter} from '@modules/categories/helpers';
import {useCategoryQuery} from '@modules/categories/hooks/useCategories';
import {FetchCompaniesParam, useFetchCompaniesQuery} from '@modules/companies/api';
import {CompanyFormatter} from '@modules/companies/helpers';
import {
  searchNameAtom,
  selectedCategoryAtom,
  selectedMakerAtom,
  selectedNarrowCategoryAtom,
  selectedRootCategoryAtom,
} from '../state';
import {useAtom} from 'jotai';
import {isNullish} from '@front-libs/helpers';
import {useSetAtom} from 'jotai';
import {useDescendantCategoryQuery} from '@modules/categories/api';
import {dialogHandler} from '@components/molecules/Dialogs/DialogHandler';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {createWholeProducts} from '@modules/products/api';
import {CreateWholeProduct} from '@modules/products/types';
import {NewRegistrationDialogProps, NewRegistrationDialogResult, NewRegistrationDialog} from './Dialog';
import {useNavigate} from 'react-router-dom';

export const ToolBar: React.FC = () => {
  const classes = useStyles();
  const {myInfo} = useMyInfo();

  const [resetNarrowCategoryValue] = useState<boolean>(false);
  const {rootCategoryQuery} = useCategoryQuery();
  const [fetchCompaniesQueryParam, setFetchCompaniesQueryParam] = useState<FetchCompaniesParam>({});
  const {data: companies} = useFetchCompaniesQuery(myInfo.hospitalHashId, fetchCompaniesQueryParam);
  const [searchName, setSearchName] = useAtom(searchNameAtom);
  const setSelectedMaker = useSetAtom(selectedMakerAtom);
  const [selectedRootCategory, setSelectedRootCategory] = useAtom(selectedRootCategoryAtom);
  const [selectedNarrowCategory, setSelectedNarrowCategory] = useAtom(selectedNarrowCategoryAtom);
  const setSelectedCategory = useSetAtom(selectedCategoryAtom);
  const navigate = useNavigate();

  const descendantCategories = useDescendantCategoryQuery(selectedRootCategory);

  const handleSearchNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchName(e.target.value);
  };

  const handleMakerChange = (selected?: SelectOptionProps) => {
    setSelectedMaker(selected?.value ?? null);
  };

  const handleRootCategoryChange = (selected?: SelectOptionProps) => {
    setSelectedRootCategory(selected?.value ?? null);
    if (isNullish(selectedNarrowCategory)) {
      setSelectedCategory(selected?.value ?? null);
    }
  };

  const handleNarrowCategoryChange = (selected?: SelectOptionProps) => {
    setSelectedNarrowCategory(selected?.value ?? null);
    setSelectedCategory(selected?.value ?? null);
  };

  const onInputChange = useCallback((inputValue: string) => {
    setFetchCompaniesQueryParam({name: inputValue === '' ? undefined : inputValue});
  }, []);

  const registerProduct = useCallback(async () => {
    try {
      const formData = await dialogHandler.open<NewRegistrationDialogProps, NewRegistrationDialogResult>(
        NewRegistrationDialog,
        {}
      );

      const isNew = Boolean(formData.newMakerName);

      const newProduct: CreateWholeProduct = {
        rootCategoryHashId: formData.rootCategoryHashId,
        narrowCategoryHashId: formData.subCategoryHashId,
        makerHashID: isNew ? undefined : formData.makerHashId,
        makerName: isNew ? formData.newMakerName ?? undefined : undefined,
        name: formData.name,
        displayName: formData.displayName,
        janCode: formData.janCode,
        approvalNumber: formData.approvalNumber,
        className: formData.className,
      };

      const res = await createWholeProducts(myInfo.hospitalHashId, newProduct);

      return res.data.hashId;
    } catch (e) {
      console.error(e);
      return null;
    }
  }, [myInfo.hospitalHashId]);

  const handleClickNewProduct = useCallback(async () => {
    const hashId = await registerProduct();
    if (hashId !== null) {
      navigate(`/products/registration/Step2/${hashId}`);
    }
  }, [navigate, registerProduct]);

  return (
    <Grid container className={classes.container}>
      <Grid item>
        <TextField
          label="機種名・型式で検索"
          size="small"
          variant="outlined"
          InputProps={{
            endAdornment: <Search />,
          }}
          style={{minWidth: '240px'}}
          defaultValue={searchName}
          onChange={handleSearchNameChange}
        />
        <PopperSelectBoxButton
          buttonLabel="大分類"
          options={CategoryFormatter.getOptions(rootCategoryQuery.data ?? [])}
          isMulti={false}
          onChange={handleRootCategoryChange}
          searchable={true}
        />
        <PopperSelectBoxButton
          buttonLabel="小分類"
          options={CategoryFormatter.getOptions(descendantCategories.data ?? [])}
          isMulti={false}
          onChange={handleNarrowCategoryChange}
          searchable={true}
          resetValue={resetNarrowCategoryValue}
        />
        <PopperSelectBoxButton
          buttonLabel="メーカー"
          options={CompanyFormatter.getOptions(companies?.data ?? [])}
          isMulti={false}
          onChange={handleMakerChange}
          searchable={true}
          onInputChange={onInputChange}
        />
      </Grid>
      <Grid item>
        <Button onClick={handleClickNewProduct} variant="contained" color="primary">
          機種情報を入力
        </Button>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    position: 'sticky',
    top: 0,
    backgroundColor: 'white',
    justifyContent: 'space-between',
  },
}));
