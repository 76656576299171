import React from 'react';
import {List, ListItemText, ListItemIcon, ListItemSecondaryAction, ListItemButton, CSSObject} from '@mui/material';
import {HospitalProductPlanStatus, HospitalProductPlanStatusOptions} from '@modules/hospital_product_plan/enum';
import {FiberManualRecord} from '@material-ui/icons';

const statusColors = {
  planned: '#3C9E58',
  unplanned: '#C7243A',
};

const ListCss: CSSObject = {
  padding: 0,
  '& > *:not(:first-child)': {marginTop: '8px'},
};
const ListItemCss: CSSObject = {
  '&.Mui-selected': {
    backgroundColor: 'rgba(42, 150, 232, 0.1)',
    borderRadius: '3px',
    '& .MuiListItemText-primary': {
      fontWeight: 'bold',
    },
  },
};

export type Status = {
  value: HospitalProductPlanStatus;
  label: string;
  indicator: React.ReactNode;
};

type StatusSelectorProps = {
  selectedStatus: HospitalProductPlanStatus | null;
  onChange: (status: HospitalProductPlanStatus) => void;
  counts: Record<HospitalProductPlanStatus, number | null>;
};

export const StatusSelector: React.FC<StatusSelectorProps> = (props) => {
  const {selectedStatus, onChange, counts} = props;

  return (
    <List sx={ListCss}>
      <ListItemButton onClick={() => onChange('all')} selected={selectedStatus === 'all'} sx={ListItemCss}>
        <ListItemIcon>
          <FiberManualRecord />
        </ListItemIcon>
        <ListItemText primary="すべての計画" />
      </ListItemButton>
      {HospitalProductPlanStatusOptions.map(({value, label}) => (
        <ListItemButton
          key={value}
          onClick={() => onChange(value)}
          selected={selectedStatus === value}
          sx={ListItemCss}>
          {value in statusColors && (
            <ListItemIcon>
              <FiberManualRecord style={{color: statusColors[value]}} />
            </ListItemIcon>
          )}
          <ListItemText primary={label} />
          {counts[value] !== null && (
            <ListItemSecondaryAction>
              <ListItemText primary={`${counts[value]}`} />
            </ListItemSecondaryAction>
          )}
        </ListItemButton>
      ))}
    </List>
  );
};
