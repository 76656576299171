import React, {Suspense, useCallback, useEffect, useState} from 'react';
import {InnerLoading} from '@components/molecules/Loading';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, styled} from '@mui/material';
import CloseIcon from '@material-ui/icons/Close';
import {ProductTable} from './ProductTable';
import {Pagination} from '@material-ui/lab';
import {isNullish} from '@front-libs/helpers';
import {useProductDialog} from './hooks';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {SearchBar} from './SearchBar';
import {useAtomValue, useSetAtom} from 'jotai';
import {pageAtom, searchNameAtom, searchRootCategoriesAtom, searchSubCategoriesAtom} from './jotai';

const StyledSpan = styled('span')(() => ({
  color: '#172B4D',
  fontSize: '20px',
  fontWeight: 'bold',
}));

const StyledIconButton = styled(IconButton)(() => ({
  padding: '0px',
}));

const StyledContent = styled(DialogContent)(() => ({
  padding: '8px 0px 0px',
  height: '500px',
  margin: '0px',
  overflow: 'hidden',
}));

const StyledGrid = styled(Grid)(() => ({
  padding: '0 32px',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

const StyledTableContainer = styled(Grid)(() => ({
  flex: '1 1',
  overflow: 'hidden',
}));

const StyledDialogActions = styled(DialogActions)(() => ({
  padding: '24px 32px 32px',
}));

const StyledPageDescription = styled(Grid)(() => ({
  margin: 'auto 0px',
  flex: 1,
  color: '#172B4D',
}));

const StyledPagination = styled(Grid)(() => ({
  display: 'flex',
  alignItems: 'center',
  fontSize: '0.875rem',
}));

const StyledFooterContainer = styled(Grid)(() => ({
  padding: '20px 24px 0 24px',
}));

export type CreateProductsDialogProps = DialogProps;

const CreateProductsDialogInner: React.FC<CreateProductsDialogProps> = (props) => {
  const {myInfo} = useMyInfo();
  const {isLoading, totalCount, handleChangePage, page, rows, totalPage, startDisplayPosition, endDisplayPosition} =
    useProductDialog();

  const searchName = useAtomValue(searchNameAtom);
  const rootCategory = useAtomValue(searchRootCategoriesAtom);
  const narrowCategory = useAtomValue(searchSubCategoriesAtom);
  const setPage = useSetAtom(pageAtom);

  useEffect(() => {
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchName, rootCategory, narrowCategory]);

  const [selectedHashId, setSelectedHashId] = useState<string | undefined>();

  const handleClose = useCallback(
    (e: React.MouseEvent) => {
      props.actions.reject();
    },
    [props.actions]
  );

  const handleClickSelect = useCallback(() => {
    if (isNullish(selectedHashId)) return;

    props.actions.resolve({
      hashId: selectedHashId,
    });
  }, [props.actions, selectedHashId]);

  const isValid = isNullish(selectedHashId);

  return (
    <Suspense fallback={<InnerLoading />}>
      <Dialog open={true} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth maxWidth="md">
        <DialogTitle sx={{padding: '16px 32px'}}>
          <Grid container justifyContent="space-between" alignItems="center">
            <StyledSpan>対象機種を選択</StyledSpan>
            <Grid item>
              <StyledIconButton onClick={handleClose}>
                <CloseIcon />
              </StyledIconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <StyledContent>
          <StyledGrid container wrap="nowrap">
            <Grid item container>
              <SearchBar myInfo={myInfo} />
            </Grid>
            <StyledTableContainer item>
              {rows === undefined || isLoading ? (
                <InnerLoading />
              ) : (
                <ProductTable
                  rows={rows}
                  onSelectHashId={(hashID: string) => setSelectedHashId(hashID)}
                  SelectHashId={selectedHashId}
                />
              )}
            </StyledTableContainer>
          </StyledGrid>
        </StyledContent>
        <StyledFooterContainer container>
          <StyledPageDescription item xs={6}>
            {totalCount}件のうち{startDisplayPosition}件目-{endDisplayPosition}件目までを表示しています
          </StyledPageDescription>
          <StyledPagination item xs={6}>
            <Pagination page={page} count={totalPage} shape="rounded" onChange={handleChangePage} />
          </StyledPagination>
        </StyledFooterContainer>
        <StyledDialogActions>
          <Button disabled={isValid} onClick={handleClickSelect} variant="contained" color="primary">
            作成
          </Button>
          <Button onClick={handleClose} color="primary">
            キャンセル
          </Button>
        </StyledDialogActions>
      </Dialog>
    </Suspense>
  );
};

export const CreateProductsDialog: React.FC<CreateProductsDialogProps> = (props) => {
  return (
    <Suspense fallback={<InnerLoading />}>
      <CreateProductsDialogInner {...props} />
    </Suspense>
  );
};
