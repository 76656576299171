import React, {useMemo, useState} from 'react';
import {Clear, Fullscreen, FullscreenExit, Minimize} from '@material-ui/icons';
import {makeStyles, Grid, IconButton, Button, Theme, styled, Avatar, useMediaQuery} from '@material-ui/core';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import clsx from 'clsx';
import {User} from '@auth0/auth0-react';
import {APPENDIX_WIDTH_BREAK_POINT} from '@Apps/ProductDetail/styled';

export type CommentDialogProps = {
  defaultValue?: string;
  userIndex?: User;
} & Omit<DialogProps, 'open'>;

type StyleProps = {
  isFullscreen: boolean;
  isMinimized: boolean;
};

const AvatarIcon = styled(Avatar)({
  width: '30px',
  height: '30px',
  marginRight: '8px',
});

export const ProductDetailCommentDialog: React.FC<CommentDialogProps> = (props) => {
  const {userIndex} = props;
  const [isFullscreen, setFullScreen] = useState(false);
  const [isMinimized, setMinimized] = useState(false);
  const [value, setValue] = useState(props.defaultValue ?? '');
  const classes = useStyle({isFullscreen, isMinimized});
  const isTabletDisplay = useMediaQuery(`(max-width:${APPENDIX_WIDTH_BREAK_POINT})`);

  const disabled = useMemo(() => value === '', [value]);

  const headerButtons = useMemo(() => {
    return [
      <IconButton key="minimized" className={classes.button} onClick={() => setMinimized(!isMinimized)}>
        <Minimize style={{transform: isMinimized ? 'rotate(180deg)' : undefined}} />
      </IconButton>,
      isFullscreen ? (
        <IconButton
          className={classes.button}
          onClick={() => {
            if (isMinimized) setMinimized(false);
            else setFullScreen(false);
          }}>
          <FullscreenExit />
        </IconButton>
      ) : (
        <IconButton
          className={classes.button}
          onClick={() => {
            if (isMinimized) setMinimized(false);
            else setFullScreen(true);
          }}>
          <Fullscreen />
        </IconButton>
      ),
      <IconButton key="reject" className={classes.button} onClick={props.actions.reject}>
        <Clear />
      </IconButton>,
    ];
  }, [classes.button, isFullscreen, isMinimized, props.actions.reject]);

  return (
    <div className={clsx({[classes.backdrop]: !isMinimized && isFullscreen})}>
      <Grid className={isTabletDisplay ? classes.rootInTablet : classes.root}>
        <Grid container className={classes.header}>
          <Grid item className={classes.buttons}>
            <Grid>{headerButtons}</Grid>
          </Grid>
        </Grid>
        <Grid className={classes.body}>
          <div style={{display: 'grid', gridTemplateColumns: 'auto 1fr', width: '100%'}}>
            <AvatarIcon src={userIndex?.picture} />
            <textarea
              value={value}
              onChange={(e) => setValue(e.target.value)}
              tabIndex={isMinimized ? -1 : 1}
              className={classes.inputComment}
              placeholder={'コメントを入力'}
            />
          </div>
        </Grid>

        <Grid className={classes.footer}>
          <Grid item className={classes.footerBtnContainer}>
            <Button
              disabled={disabled}
              onClick={() => props.actions.resolve(value)}
              color={'primary'}
              variant={'contained'}
              disableElevation
              style={{padding: '8px 20px', borderRadius: '32px'}}>
              コメントする
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const useStyle = makeStyles<Theme, StyleProps>(({palette, zIndex}: Theme) => ({
  root: {
    position: 'fixed',
    margin: 0,
    border: 'none',
    borderRadius: '10px',
    boxShadow: '0px 0px 20px 4px rgba(0,0,0,0.2)',
    overflow: 'hidden',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    transitionDuration: '.2s',
    zIndex: zIndex.drawer + 10,
    justifyContent: 'flex-end',
    top: ({isFullscreen, isMinimized}) => (isFullscreen ? 'unset' : isMinimized ? `calc(100vh - 56px)` : 236),
    right: ({isFullscreen, isMinimized}) => (!isFullscreen || isMinimized ? 32 : 'unset'),
    width: ({isFullscreen, isMinimized}) => (isFullscreen && !isMinimized ? '92vw' : 536),
    height: ({isFullscreen, isMinimized}) => (isMinimized ? 56 : isFullscreen ? '92vh' : '300px'),
  },
  rootInTablet: {
    position: 'fixed',
    margin: 0,
    border: 'none',
    borderRadius: '10px',
    boxShadow: '0px 0px 20px 4px rgba(0,0,0,0.2)',
    overflow: 'hidden',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    transitionDuration: '.2s',
    zIndex: zIndex.drawer + 10,
    justifyContent: 'flex-end',
    top: ({isFullscreen, isMinimized}) => (isFullscreen ? 'unset' : isMinimized ? `calc(100vh - 56px)` : 236),
    right: ({isFullscreen, isMinimized}) => (!isFullscreen || isMinimized ? 32 : 'unset'),
    width: ({isFullscreen, isMinimized}) => (isFullscreen && !isMinimized ? '92vw' : 426),
    height: ({isFullscreen, isMinimized}) => (isMinimized ? 56 : isFullscreen ? '92vh' : '300px'),
  },
  header: {
    alignItems: 'center',
    justifyContent: 'flex-end',
    color: 'white',
    fontSize: 18,
    height: 56,
    flex: '0 0 50px',
    padding: '0px 24px',
    zIndex: zIndex.drawer + 20,
  },
  title: {
    flex: '0 0 auto',
    width: 'auto',
  },
  buttons: {
    flex: '0 0 auto',
    width: 'auto',
  },
  button: {
    // color: 'white !important',
  },
  body: {
    flex: '1 1 0',
    padding: '0px 24px 16px',
    display: ({isMinimized}) => (isMinimized ? 'none' : 'flex'), // 最小化時に非表示
  },
  inputComment: {
    width: '100%',
    height: '100%',
    borderStyle: 'none',
    resize: 'none',
    fontSize: 16,
    paddingTop: 10,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    '&:focus-visible': {
      borderStyle: 'none',
      outline: 'none',
    },
  },
  footer: {
    height: 80,
    borderTop: `2px solid ${palette.grey[200]}`,
    boxSizing: 'border-box',
    justifyContent: 'flex-end',
    display: ({isMinimized}) => (isMinimized ? 'none' : 'flex'), // 最小化時に非表示
  },
  footerBtnContainer: {
    height: '100%',
    padding: '16px 16px 24px 32px',
  },
  backdrop: {
    zIndex: zIndex.appBar + 1000,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    position: 'fixed',
    right: 0,
    bottom: 0,
    top: 0,
    left: 0,
  },
}));
