import React, {useCallback, useMemo, useState} from 'react';
import {Button, DialogContent, DialogTitle, styled, Typography, Box} from '@mui/material';
import {PDFDisplay} from '@molecules/Utils/PDFDisplay';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import {Dialog} from '@atoms/Dialog/Dialog';
import {isChrome, isIOS, isTablet, isSafari} from 'react-device-detect';
import {AddCircleOutline, RemoveCircleOutline} from '@material-ui/icons';

const ZOOM_COEFFICIENT = 1.2;
const MAX_ZOOM = 3;
const MIN_ZOOM = 1;

type FilePreviewDialogProps = {
  fileName: string;
  fileType: string;
  url: string;
  title: string;
} & DialogProps;

const defaultSize = {
  width: '100%',
  height: '100%',
};

const NotPreviewDiv = styled('div')(defaultSize);
const PreviewImg = styled('img')({...defaultSize, objectFit: 'contain'});
const StyledDialogTitle = styled(DialogTitle)({
  '& .MuiDialogTitle-root': {
    padding: '16px 0px 16px 24px',
  },
});
const StyledBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const ButtonBox = styled(Box)({
  padding: '10px',
  display: 'flex',
  gap: 16,
});

const IconButton = styled('button')({
  fontSize: '16px',
  border: 'none',
  padding: '4px 8px',
  color: '#0A52CC',
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
  backgroundColor: 'white',
});

type ZoomButtonsProps = {
  handleZoomIn: VoidFunction;
  handleZoomOut: VoidFunction;
};

/**
 * 拡大縮小ボタンComponent
 */
export const ZoomButtons = ({handleZoomIn, handleZoomOut}: ZoomButtonsProps) => {
  return (
    <>
      <IconButton onClick={handleZoomIn} aria-label="AddCircleOutline" color="primary">
        <AddCircleOutline color="primary" />
        拡大
      </IconButton>
      <IconButton onClick={handleZoomOut} aria-label="AddCircleOutline" color="primary">
        <RemoveCircleOutline color="primary" />
        縮小
      </IconButton>
    </>
  );
};

type DialogHeaderProps = {
  title: string;
  isZoom: boolean;
  download: () => Promise<void>;
  close: VoidFunction;
} & ZoomButtonsProps;

/**
 * ダイアログヘッダー部分のComponent
 * @param param0
 * @returns
 */
const DialogHeader = ({title, isZoom, download, close, handleZoomIn, handleZoomOut}: DialogHeaderProps) => {
  return (
    <StyledDialogTitle>
      <StyledBox display="flex" alignItems="center">
        <Typography variant="subtitle1">{title}</Typography>
        <ButtonBox>
          {isZoom && <ZoomButtons handleZoomIn={handleZoomIn} handleZoomOut={handleZoomOut} />}
          <Button onClick={download} color={'primary'}>
            ダウンロード
          </Button>
          <Button onClick={close} color={'primary'}>
            閉じる
          </Button>
        </ButtonBox>
      </StyledBox>
    </StyledDialogTitle>
  );
};
/**
 * ファイルプレビューダイアログのコンポーネント
 *
 * @param {string} props.fileName - プレビューするファイルの名前
 * @param {string} props.fileType - プレビューするファイルのタイプ（MIMEタイプ）
 * @param {string} props.url - プレビューするファイルのURL
 * @param {string} [props.title='ファイルプレビュー'] - ダイアログのタイトル
 * @param {boolean} props.open - ダイアログが開いているかどうかを示すフラグ
 * @param {Object} props.actions - ダイアログのアクションに関するオブジェクト
 * @param {Function} props.actions.reject - ダイアログを閉じるための関数(Promise)
 * @param {Function} props.actions.resolve - ダイアログの決定動作を行うための関数(Promise)
 *
 * @example
 * <FilePreviewDialog
 *   fileName="example.pdf"
 *   fileType="application/pdf"
 *   url="https://example.com/example.pdf"
 *   open={true}
 *   actions={{
 *     reject: () => console.log('Dialog closed'),
 *     resolve: () => console.log('Dialog resolved')
 *   }}
 * />
 *
 * @returns {JSX.Element} ファイルプレビューダイアログのJSX要素
 */
export const FilePreviewDialog: React.FC<FilePreviewDialogProps> = ({
  open,
  actions: {reject},
  fileName,
  fileType,
  url,
  title = 'ファイルプレビュー',
  actions,
}: FilePreviewDialogProps) => {
  const [scale, setScale] = useState(1.0);

  const handleZoomIn = useCallback(() => {
    const newScale = scale * ZOOM_COEFFICIENT;
    setScale(newScale > MAX_ZOOM ? MAX_ZOOM : newScale);
  }, [scale]);

  const handleZoomOut = useCallback(() => {
    const newScale = scale / ZOOM_COEFFICIENT;
    setScale(newScale < MIN_ZOOM ? MIN_ZOOM : newScale);
  }, [scale]);

  const isPDF = !!fileType.match('application/pdf');

  const download = useCallback(async () => {
    const reader = new FileReader();
    reader.onloadend = function (e) {
      let dataUrl = (reader.result && reader.result.toString()) || '';
      if (isChrome && isIOS) {
        dataUrl = dataUrl.replace(/^data:[^;]*;/, 'data:attachment/file;');
      }
      const downloadLink = document.createElement('a');
      downloadLink.setAttribute('href', dataUrl);
      downloadLink.setAttribute('download', fileName);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    };
    const res = await fetch(url);
    reader.readAsDataURL(await res.blob());
  }, [fileName, url]);

  // NOTE:MobileSafariは直接ダウンロード出来ないので別タブで開く
  const downloadForIpadSafari = useCallback(async () => {
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [fileName, url]);

  const preview = useMemo(() => {
    if (isPDF) {
      return <PDFDisplay src={url} scale={scale} />;
    } else if (fileType.match('image/*')) {
      return <PreviewImg src={url} alt={fileName} />;
    } else {
      return <NotPreviewDiv>この形式のファイルはプレビューできません。</NotPreviewDiv>;
    }
  }, [fileName, fileType, isPDF, url, scale]);

  return (
    <Dialog
      open={Boolean(open)}
      fullWidth
      maxWidth="lg"
      PaperProps={{style: {height: '100%'}}}
      title={title}
      onClose={reject}>
      <DialogHeader
        title={title}
        isZoom={isPDF && isTablet}
        download={isTablet && isSafari ? downloadForIpadSafari : download}
        close={() => actions.resolve()}
        handleZoomIn={handleZoomIn}
        handleZoomOut={handleZoomOut}
      />
      <DialogContent
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          height: '100%',
          padding: 0,
          overflow: 'hidden',
        }}>
        {preview}
      </DialogContent>
    </Dialog>
  );
};
