import React, {useCallback, useMemo} from 'react';
import {Button as MButton, Dialog, DialogActions, DialogContent, styled} from '@material-ui/core';
import hospitalProductTemplateFile from './assets/template.xlsx?url';
import {ExcelFileDropZone} from '@organisms/HospitalProductImporter';
import {PageHeader, BodyText, BodyTextBold, SectionHeader} from './components/Typography';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {Link, useNavigate} from 'react-router-dom';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import {useImportHospitalProductsExcel} from './hooks';
import {HospitalProductTable} from './components/HospitalProductTable';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {SimpleDialog} from '@molecules/Dialogs/BaseDialog';

/* 
〜〜HospitalProductsをインポートできるまでの遠い道のり〜
1. ユーザーがファイルを準備

2. Excel か CSVを読み込む
2-2a-1. Excelの場合、シートを選択する
2-2a-2. 選択されたシートの1行目をヘッダーとして読み込む。20行表示する
2-2b-1. CSVの場合、1行目をヘッダーとして読み込む。20行表示する

3-1-1. ヘッダーの内容を読み取りシステム予測する。システム予測ができた場合、SelectValueを埋める
3-1-2. システム予測ができない場合、ヘッダの名前からカラム予測する
3-2. ユーザーがカラム内容を設定
3-3. カラム内容を確定するボタンクリック

4-1. WholeProductの一覧を作成
4-2. WholeProductsの曖昧予測APIコール
4-3. WholeProductsのリストを作成
4-4. WholeProductsを確定するボタンクリック

5-1. 最終的なHospitalProducts一覧を表示
5-2. 重複した行のインポート方法の設定
5-3. インポート処理実行
*/
export const ProductImport: React.FC = () => {
  const navigate = useNavigate();

  const {myInfo} = useMyInfo();

  const {
    uploadFile,
    isValidFile,
    hospitalProductData,
    hospitalProductsPage,
    displayHospitalProducts,
    setHospitalProductsPage,
    submitHospitalProductData,
  } = useImportHospitalProductsExcel(myInfo.hospitalHashId);

  const handleClickReturnProductList = useCallback(() => navigate('/products'), [navigate]);

  const handleSubmit = useCallback(async () => {
    submitHospitalProductData();
    await dialogHandler.open(SimpleDialog, {
      title: '機器台帳の受付完了',
      content:
        '機器台帳の登録を受付けました。\n順次処理を行い、登録処理が完了しましたら通知にてお知らせいたします。\n\n登録がエラーになった場合も通知しますので、内容をご確認ください。\n\n機器一覧画面に戻りますか？',
      positiveButtonLabel: '機器一覧画面に戻る',
      negativeButtonLabel: '閉じる',
    });

    navigate('/products');
  }, [navigate, submitHospitalProductData]);

  const encodedTemplateURL = useMemo(() => encodeURI(hospitalProductTemplateFile), []);

  return (
    <Dialog open={true} fullScreen>
      <DialogContent>
        <Section>
          <PageHeader>機器台帳登録</PageHeader>
          <BodyText>所定のテンプレート（Excelファイル）を用いて、機器を一括登録できます。</BodyText>
        </Section>

        <Section>
          <SectionHeader>1. Excelファイルの準備</SectionHeader>
          <BodyText>
            下記ボタンをクリックして、所定のテンプレート（Excelファイル）をダウンロードしてください。
            <br />
            ファイル内にある「記入例」シートに、記入の方法と注意事項が書かれていますので、ご確認ください。
          </BodyText>
          <BodyTextBold>入力必須項目については、機器登録の設定をご確認ください。</BodyTextBold>
          <Link to={encodedTemplateURL} target="_blank" download style={{textDecoration: 'none'}}>
            <Button color="primary" variant="contained" style={{width: 'auto', marginTop: 8}}>
              機器登録用のテンプレートをダウンロード
            </Button>
          </Link>
        </Section>

        <Section>
          <SectionHeader>2. Excelファイルのアップロード</SectionHeader>
          <BodyText>所定のテンプレートに、登録したい機器情報を入力した上でアップロードしてください。</BodyText>
          <ContentWrapper>
            <ExcelFileDropZone onUpload={uploadFile} />
          </ContentWrapper>
        </Section>

        <Section>
          <SectionHeader>3. データの確認・登録</SectionHeader>
          <BodyText>
            アップロードされたファイルを基に、HITOTSU Assetにインポートする機器台帳データを表示します。
            <br />
            確認後、問題がなければ「データを登録」ボタンをクリックしてください。
          </BodyText>
        </Section>

        {isValidFile && (
          <>
            <Section>
              <ContentWrapper>
                <HospitalProductTable
                  data={displayHospitalProducts}
                  page={hospitalProductsPage + 1}
                  totalCount={hospitalProductData.length}
                  onPageChange={(newPage: number) => {
                    setHospitalProductsPage(newPage - 1);
                  }}
                />
              </ContentWrapper>
            </Section>
            <Section>
              <Button
                disabled={!isValidFile || hospitalProductData.length === 0}
                color="primary"
                variant="contained"
                onClick={handleSubmit}>
                データを登録
              </Button>
            </Section>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={handleClickReturnProductList}>
          <ChevronLeftIcon />
          機器一覧画面に戻る
        </Button>
        <Grow />
      </DialogActions>
    </Dialog>
  );
};

const Section = styled('section')({
  marginBottom: 32,
});

const ContentWrapper = styled('div')({
  margin: '16px auto',
});

const Button = styled(MButton)({
  width: 192,
});

const Grow = styled('div')({
  flexGrow: 1,
});
