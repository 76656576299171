import React from 'react';
import {createStyles, Grid, makeStyles, TextField, Theme} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: '0px 16px',
    },
    textFieldContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    textField: {
      width: '100%',
    },
  })
);

type SingleLineTextFieldProps = {
  focused: boolean;
  placeholder: string;
};

export const SingleLineTextField: React.FC<SingleLineTextFieldProps> = (props) => {
  const {focused, placeholder} = props;
  const classes = useStyles();

  return (
    <Grid container direction="column">
      <Grid className={classes.textFieldContainer} md={6}>
        <TextField
          className={classes.textField}
          disabled={focused === true}
          variant="standard"
          placeholder={placeholder}
        />
      </Grid>
    </Grid>
  );
};
