import React, {useMemo} from 'react';
import {makeStyles, Theme, createStyles, withStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import {StepIconProps} from '@material-ui/core/StepIcon';
import {useAtomValue} from 'jotai';
import {rentOrReturnAtom, rentStepperAtom} from '../states';
import currentStepIcon from '../../../assets/current_step.svg';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {Rentalsteps, Returnsteps} from './consts';

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 10px)',
    right: 'calc(50% + 10px)',
  },
  active: {
    '& $line': {
      borderColor: '#172B4D',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#172B4D',
    },
  },
  line: {
    borderColor: '#E4E6EC',
    borderTopWidth: 1,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#E4E6EC',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#172B4D',
  },
  completed: {
    color: '#172B4D',
    zIndex: 1,
    fontSize: 18,
  },
  stepIcon: {
    width: '16px',
    height: '16px',
  },
  stepIconCompleted: {
    color: '#172B4D',
    width: '16px',
    height: '16px',
  },
});

const QontoStepIcon: React.FC<StepIconProps> = (props) => {
  const classes = useQontoStepIconStyles();
  const {active, completed} = props;
  const currentStep = useAtomValue(rentStepperAtom);

  const currentIcon = useMemo(() => {
    if (completed) {
      return <FiberManualRecordIcon className={classes.stepIconCompleted} />;
    } else if (active) {
      if (currentStep !== 3) {
        return <img src={currentStepIcon} alt="currentStep" />;
      } else {
        return <CheckCircleIcon fontSize="small" />;
      }
    } else {
      return <FiberManualRecordIcon className={classes.stepIcon} />;
    }
  }, [active, classes.stepIcon, classes.stepIconCompleted, completed, currentStep]);

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}>
      {currentIcon}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    container: {
      backgroundColor: 'transparent',
      width: '44%',
      position: 'absolute',
      left: 'calc(50% - 22%)',
      top: '72px',
      padding: 0,
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    stepLabel: {
      color: '#172B4D !important',
      width: 'max-content',
      margin: '16px auto',
    },
    hide: {display: 'none'},
  })
);

type StepperProps = {
  activeStep: number;
};

export const RentalStepper: React.FC<StepperProps> = ({activeStep}) => {
  const classes = useStyles();
  const currentStep = useAtomValue(rentStepperAtom);
  const rentOrReturn = useAtomValue(rentOrReturnAtom);

  const steps = rentOrReturn === 'rent' ? Rentalsteps : Returnsteps;

  return (
    <div className={classes.root}>
      <Stepper alternativeLabel activeStep={activeStep} className={classes.container} connector={<QontoConnector />}>
        {steps.map((step) => (
          <Step key={step.value}>
            <StepLabel
              classes={currentStep !== step.value ? {label: classes.hide} : {label: classes.stepLabel}}
              StepIconComponent={QontoStepIcon}>
              {step.label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};
