import React from 'react';
import {createStyles, Grid, makeStyles, Theme} from '@material-ui/core';
import {InspectionTableIndex} from '@modules/inspections/types';
import {Section} from '@components/molecules/InspectionTableFormItems/Section';
import {Item} from '@Apps/InspectionResult/pc/EditInspectionResult/Item';
import {InspectionItem} from '@modules/inspections/api';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    root: {
      margin: '24px 0px',
    },
    items: {
      maxWidth: '700px',
      '& > div:not(:first-child)': {
        marginTop: '42px',
      },
    },
  })
);

type ItemsFormProps = {
  inspectionTable: InspectionTableIndex;
};

export const ItemsForm: React.FC<ItemsFormProps> = (props) => {
  const {inspectionTable} = props;
  const classes = useStyles();

  if (!inspectionTable) {
    // todo: show message
    return null;
  }

  return (
    <Grid className={classes.root} container justifyContent="center">
      <Grid container direction="column" className={classes.items}>
        {inspectionTable.items.map((item, i) => {
          if (item.type === 'section') {
            return (
              <Section key={i} name={item.name}>
                {item.items.map((sectionItem, j) => (
                  <Item key={j} item={sectionItem as InspectionItem & {id: string}} />
                ))}
              </Section>
            );
          } else {
            return <Item key={i} item={item as InspectionItem & {id: string}} />;
          }
        })}
      </Grid>
    </Grid>
  );
};
