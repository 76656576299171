import React, {useState, useCallback} from 'react';
import {createStyles, makeStyles, Theme, Typography, Grid, Menu, Button, TextField} from '@material-ui/core';
import {PeriodUnit} from '@modules/inspection_setting/types';
import {ProductListElement} from '../types';
import {useUpdatePeriodRequestDispatcherContext} from '../contexts';

export const formatPeriod = (period: number, periodUnit: PeriodUnit) => {
  switch (periodUnit) {
    case 'day':
      return `${period}日`;
    case 'month':
      return `${period}ヶ月`;
    case 'year':
      return `${period}年`;
    default:
      // should not reach here
      break;
  }
  return '';
};

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    period: {
      display: 'inline',
      color: theme.palette.info.dark,
      fontWeight: 'bold',
      cursor: 'pointer',
    },
    dialog: {
      minWidth: '250px',
      padding: '24px',
    },
    createButton: {
      display: 'inline',
      color: theme.palette.info.dark,
      fontWeight: 'bold',
    },
    actionButton: {
      background: 'rgba(9, 30, 66, 0.04)',
      '&:not(:first-of-type)': {
        marginLeft: '8px',
      },
    },
  })
);

type InspectionPeriodicColumnProps = {
  period?: number;
  periodUnit?: PeriodUnit;
  onSave: (period: number, unit: PeriodUnit) => void;
};

const InspectionPeriodicColumn: React.FC<InspectionPeriodicColumnProps> = (props) => {
  const {period, periodUnit, onSave} = props;
  const classes = useStyle();
  const isSetValue = period && periodUnit;

  const [value, setValue] = useState<number | null>(period ?? null);
  const handleChangeValue = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(Number.parseInt(e.target.value));
  }, []);

  const [menuAnchor, setMenuAnchor] = React.useState<Element | null>(null);
  const handleClickText = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(e.currentTarget);
  }, []);
  const handleCloseMenu = useCallback(() => {
    setMenuAnchor(null);
  }, []);

  const handleSave = useCallback(
    (e: React.MouseEvent) => {
      handleCloseMenu();
      if (value != null) {
        onSave(value, 'month');
      }
    },
    [handleCloseMenu, onSave, value]
  );
  const handleCancel = useCallback(
    (e: React.MouseEvent) => {
      handleCloseMenu();
    },
    [handleCloseMenu]
  );

  return (
    <Grid>
      <Typography className={classes.period} onClick={handleClickText}>
        {isSetValue ? formatPeriod(period, periodUnit) : '点検間隔を設定'}
      </Typography>
      <Menu
        anchorEl={menuAnchor}
        getContentAnchorEl={null}
        anchorOrigin={{vertical: 'center', horizontal: 'center'}}
        open={Boolean(menuAnchor)}
        onClose={handleCloseMenu}>
        <Grid className={classes.dialog} container direction="column" style={{zIndex: 99999}}>
          <Grid item container style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
            <Grid item style={{width: '80px'}}>
              <TextField
                type="number"
                variant={'outlined'}
                fullWidth
                size={'small'}
                defaultValue={period ?? ''}
                value={value}
                InputProps={{inputProps: {min: 1, max: 12}}}
                onChange={handleChangeValue}
              />
            </Grid>
            <Grid item style={{width: '50px', marginLeft: '8px'}}>
              <Typography onClick={handleClickText}>ヶ月</Typography>
            </Grid>
          </Grid>
          <Grid item container style={{marginTop: '8px'}}>
            <Button className={classes.actionButton} variant="outlined" onClick={handleSave} disabled={value === null}>
              保存
            </Button>
            <Button className={classes.actionButton} variant="outlined" onClick={handleCancel}>
              キャンセル
            </Button>
          </Grid>
        </Grid>
      </Menu>
    </Grid>
  );
};

export const PeriodicInspectionPeriodicColumn: React.FC<ProductListElement> = (props) => {
  const {hashId, periodicInspectionPeriod, periodicInspectionPeriodUnit} = props;
  const updatePeriod = useUpdatePeriodRequestDispatcherContext();

  const onSave = useCallback(
    (period: number, unit: PeriodUnit) => {
      updatePeriod(hashId, 'periodic', period, unit);
    },
    [hashId, updatePeriod]
  );

  return (
    <InspectionPeriodicColumn
      period={periodicInspectionPeriod}
      periodUnit={periodicInspectionPeriodUnit}
      onSave={onSave}
    />
  );
};
