import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import clsx from 'clsx';
import {useAtomValue, useSetAtom} from 'jotai';
import {Grid, makeStyles} from '@material-ui/core';
import {defaultHeightWidth, setOuterHeight, setOuterWidth, useTemplate} from '@templates/RentalTemplate';
import {useAsyncEffect} from '@front-libs/core';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {bulkUpdateRentals, DeviceLender} from '@modules/rentals/api';
import {rentalSettingAtom, rentStepperAtom, returnLenderNameAtom} from '../../../states';
import {Footer} from '../../../Footer';
import {Header} from '../../../Header';
import {RentalTable} from '../../RentalTable';
import {LenderTable} from '../../LenderTable';
import {ReturnButton} from '../../ReturnButton';
import {RentalErrorDialog} from '../../../RentalErrorDialog';
import {scannedProductAtom} from '../states';
import {useAtom} from 'jotai';

export const ConfirmReturn: React.FC = () => {
  const classes = useStyles();
  const templateClasses = useTemplate();
  const {myInfo} = useMyInfo();
  const [returnHospitalProducts, setReturnHospitalProducts] = useAtom(scannedProductAtom);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();
  const returnLenderName = useAtomValue(returnLenderNameAtom);
  const setRentalStepper = useSetAtom(rentStepperAtom);
  const hospitalRentalSetting = useAtomValue(rentalSettingAtom);

  const deviceLender: DeviceLender | undefined = useMemo(() => {
    if (hospitalRentalSetting === 'not_need') return undefined;

    return {
      userId: hospitalRentalSetting === 'by_barcode' ? returnLenderName ?? undefined : undefined,
      userName: hospitalRentalSetting === 'by_direct_input' ? returnLenderName ?? undefined : undefined,
    };
  }, [hospitalRentalSetting, returnLenderName]);

  const handleClickCancel = useCallback(
    (rowIndex: number) => {
      setReturnHospitalProducts(returnHospitalProducts.filter((_item, idx) => idx !== rowIndex));
    },
    [returnHospitalProducts, setReturnHospitalProducts]
  );

  const handleSubmit = async () => {
    if (!isSubmitting) setIsSubmitting(true);
  };

  useAsyncEffect(async () => {
    if (!isSubmitting) return;
    await bulkUpdateRentals(
      myInfo.hospitalHashId,
      returnHospitalProducts.map((item) => ({
        rentalHashId: item.hashId,
        returnUserHashId: myInfo.hashId,
        returnDeviceLender: deviceLender,
      }))
    );
    navigate('/shared/complete');
    setRentalStepper(2);
  }, [isSubmitting]);

  useEffect(() => {
    setRentalStepper(1);
  }, [setRentalStepper]);

  useEffect(() => {
    window.addEventListener('resize', () => {
      setOuterHeight();
      setOuterWidth();
    });
    setOuterHeight();
    setOuterWidth();
    return () => {
      window.removeEventListener('resize', () => {
        setOuterHeight();
        setOuterWidth();
      });
    };
  }, []);

  return (
    <>
      <Header title={'返却'} />
      <Grid
        item
        id={'mainContent'}
        className={clsx(templateClasses.content, classes.contentContainer)}
        style={defaultHeightWidth}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item className={classes.rentalTableContainer}>
            <RentalTable returnHospitalProducts={returnHospitalProducts} onClickCancel={handleClickCancel} />
          </Grid>
          <Grid item container alignItems="flex-end" className={classes.operationContainer}>
            {returnLenderName && (
              <Grid item className={classes.operatorTableGridItem}>
                <LenderTable lender={returnLenderName} />
              </Grid>
            )}
            <Grid item style={{width: '100%'}}>
              <ReturnButton label={'返却する'} onChange={handleSubmit} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Footer text={'返却する医療機器の\nバーコードを読み取って下さい'} />
      <RentalErrorDialog open={openDialog} onClickButton={() => setOpenDialog(false)} status={null} type="return" />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    paddingLeft: 32,
    paddingRight: 32,
    [theme.breakpoints.up('tabletH')]: {
      paddingLeft: 60,
      paddingRight: 60,
    },
    [theme.breakpoints.up('desktop')]: {
      paddingLeft: 80,
      paddingRight: 80,
    },
  },
  rentalTableContainer: {
    maxHeight: '100%',
    overflow: 'scroll',
    height: 'fit-content',
    width: '65%',
    [theme.breakpoints.up('laptop')]: {
      width: '70%',
    },
  },
  barcodeInput: {
    position: 'absolute',
    top: 20,
    marginTop: '-550px',
    '@media screen and (orientation: portrait)': {
      marginTop: '0px',
      marginLeft: '-5500px',
    },
  },
  operationContainer: {
    width: '25%',
    height: 408,
    paddingBottom: 24,
    overflow: 'scroll',
    maxHeight: '100%',
    gap: '20px',
    [theme.breakpoints.up('laptop')]: {
      paddingBottom: 16,
      paddingTop: 16,
    },
  },
  operatorTableGridItem: {
    width: '100%',
    marginTop: '48px',
    [theme.breakpoints.up('laptop')]: {
      marginTop: '28px',
    },
  },
}));
