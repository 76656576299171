import React, {useState, useRef, useCallback, useEffect, useMemo} from 'react';
import {Grid, makeStyles} from '@material-ui/core';
import clsx from 'clsx';
import {theme} from '@components/atoms/theme';
import {Header} from '@Apps/BaseSharedMenu/Header';
import {Footer} from '@Apps/BaseSharedMenu/Footer';
import {defaultHeightWidth, useTemplate} from '@components/templates/RentalTemplate';
import {Scanner} from '../../../Scanner';
import {ScannedProductTable} from './ScannedProductTable';
import {useNavigate} from 'react-router-dom';
import {useAtom} from 'jotai';
import {scannedProductAtom} from '../states';
import {FetchHospitalProductsParams, getHospitalProducts} from '@modules/hospital_products/api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {RentalErrorDialog} from '@Apps/BaseSharedMenu/RentalErrorDialog';
import {ProductStatus, UnacceptedStatus} from '../../types';
import {InnerLoading} from '@components/molecules/Loading';

export const ScanRentDeviceBarcode = () => {
  const classes = useStyles();
  const scannerRef = useRef(null);
  const {myInfo} = useMyInfo();
  const templateClasses = useTemplate();
  const navigate = useNavigate();
  const [scanning, setScanning] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [status, setStatus] = useState<ProductStatus>(null);
  const [rentHospitalProducts, setRentHospitalProducts] = useAtom(scannedProductAtom);

  // biome-ignore lint/complexity/noUselessTernary: <explanation>
  const isDisabled = useMemo(() => (rentHospitalProducts.length > 0 ? false : true), [rentHospitalProducts.length]);
  const constraints = useMemo(() => ({width: window.innerWidth * 0.6, height: window.innerHeight * 0.5}), []);

  const updateRentHospitalProductsByManagementId = useCallback(
    async (managementId: string) => {
      // managementIdをもとに機器情報を検索する
      const params: FetchHospitalProductsParams = {
        managementId: managementId,
        permanentlyAssigneds: false,
        status: 'ready',
      };

      const {data, totalCount} = await getHospitalProducts(myInfo.hospitalHashId, params);

      if (totalCount === 0) {
        setStatus(null);
        setOpenDialog(true);
        // FIX ME: 管理番号がユニークになったら、loop回す必要なくなる。
      } else if (data.some((d) => d.status !== 'ready' || (d.numberPeriodInspectionResultsInPeriod ?? 0) > 0)) {
        data.forEach((d) => setStatus(d.status));
        setOpenDialog(true);
      } else {
        setRentHospitalProducts((prevState) => [
          ...prevState,
          ...data.filter((item) => !prevState.some((d) => d.hashId === item.hashId)),
        ]);
      }
    },
    [myInfo.hospitalHashId, setRentHospitalProducts]
  );

  const onDetected = useCallback(
    (managementId: string) => {
      updateRentHospitalProductsByManagementId(managementId);
    },
    [updateRentHospitalProductsByManagementId]
  );

  const handleClickCancel = useCallback(
    (rowIndex: number) => {
      setRentHospitalProducts(rentHospitalProducts.filter((_item, idx) => idx !== rowIndex));
    },
    [rentHospitalProducts, setRentHospitalProducts]
  );

  const handleClickNextButton = useCallback(() => {
    navigate('/shared/rental/product/camera/checkout');
  }, [navigate]);

  useEffect(() => {
    setScanning(!scanning);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header title={'貸出'} />
      <Grid
        item
        id={'mainContent'}
        className={clsx(templateClasses.content, classes.contentContainer)}
        style={defaultHeightWidth}>
        <div ref={scannerRef} className={classes.videoContainer}>
          <canvas className={clsx('drawingBuffer', classes.canvas)} style={{position: 'absolute'}} />
          {scanning ? (
            <Scanner scannerRef={scannerRef} onDetected={onDetected} constraints={constraints} />
          ) : (
            <InnerLoading />
          )}
        </div>
        <div className={classes.result}>
          <ScannedProductTable rentingHospitalProducts={rentHospitalProducts} onClickCancel={handleClickCancel} />
        </div>
      </Grid>
      <Footer
        text={'貸出機器のバーコードを\nカメラで読み取って下さい'}
        nextButtonLabel={'確認画面へ'}
        onClickNextButton={handleClickNextButton}
        isDisabled={isDisabled}
      />
      <RentalErrorDialog
        open={openDialog}
        onClickButton={() => setOpenDialog(false)}
        status={status as UnacceptedStatus}
        type="rent"
      />
    </>
  );
};

const useStyles = makeStyles(() => ({
  contentContainer: {
    paddingTop: `calc((var(--outerHeight) - 148px - 104px - 412px)/2)`,
    '@media screen and (orientation: portrait)': {
      paddingTop: `calc((var(--outerWidth) - 148px - 104px - 412px)/2)`,
    },
    paddingLeft: 32,
    paddingRight: 32,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('tabletH')]: {
      paddingLeft: 60,
      paddingRight: 60,
    },
    [theme.breakpoints.up('desktop')]: {
      paddingLeft: 80,
      paddingRight: 80,
    },
  },
  videoContainer: {
    position: 'relative',
    width: '70%',
  },
  canvas: {
    top: '0px',
    left: '0px',
    height: '100%',
    width: '100%',
  },
  result: {
    width: '30%',
    paddingLeft: '40px',
    maxHeight: '100%',
    overflow: 'scroll',
  },
}));
