import React, {Suspense, useState, useCallback} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
  IconButton,
  Box,
} from '@material-ui/core';
import {Close} from '@material-ui/icons';
import {Form, Formik, useFormikContext} from 'formik';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import * as yup from 'yup';
import {useHospitalUsers} from '@modules/hospital_users/hooks/useHospitalUsers';
import {HospitalProductInfo} from '@components/organisms/HospitalProductInfo/sp';
import {InspectionResultIndex} from '@modules/inspection_results/types';
import {InspectionRecord} from './InspectionComponent';
import {useAtom, useAtomValue} from 'jotai';
import {selectedInspectionAtom, selectedInspectorAtom} from './hooks';
import {useInspectionType} from '@Apps/InspectionV2/InspectionResultList/pc/InternalInspection/states/states';

type StartInspectionFormProps = {
  open: boolean;
  defaultUserHashId: string;
  inspectionResult: InspectionResultIndex;
  onClose: React.MouseEventHandler;
};

type StartInspectionFormValue = {
  inspector: string;
  inspectionHashId: string;
};

const StartInspectionForm: React.FC<StartInspectionFormProps> = (props) => {
  const {open, onClose, inspectionResult} = props;
  const classes = useStyles();
  const {submitForm} = useFormikContext<StartInspectionFormValue>();
  const {hospitalUsers} = useHospitalUsers();
  const title = inspectionResult.status === 'unplanned' ? '点検表と点検者の確認' : '点検者の確認';
  const [inspectionType] = useInspectionType();

  const selectedInspection = useAtomValue(selectedInspectionAtom);
  const handleSubmit = useCallback(() => {
    submitForm();
  }, [submitForm]);

  if (hospitalUsers.length === 0) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      PaperProps={{
        style: {
          margin: 0,
          maxHeight: '100%',
          height: '100%',
          width: '100%',
          borderRadius: 0,
        },
      }}>
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <IconButton aria-label="close" onClick={onClose}>
          <Close />
        </IconButton>
        <Box
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            position: 'fixed',
            pointerEvents: 'none',
          }}>
          <Typography className={classes.title} style={{fontSize: '20px', fontWeight: 'bold'}}>
            {title}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent className={classes.content} style={{flex: 'none'}}>
        <Box>
          <HospitalProductInfo hospitalProduct={inspectionResult.hospitalProduct} />
          <InspectionRecord
            inspectionResult={inspectionResult}
            wholeProductHashId={inspectionResult.hospitalProduct.wholeProductHashId}
            hospitalProductHashId={inspectionResult.hospitalProduct.hashId}
            inspectionType={inspectionType as never}
            showsSelectInspection={inspectionResult.status !== 'uncompleted'}
            defaultInspectionHashId={
              inspectionResult.status === 'uncompleted' ? inspectionResult.inspectionHashId : undefined
            }
            hospitalUsers={hospitalUsers}
          />
        </Box>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={!selectedInspection}
          style={{width: '100%'}}>
          点検を開始
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export type StartInspectionDialogProps = DialogProps & {
  defaultInspectorHashId: string;
  inspectionResult: InspectionResultIndex;
};

export type StartInspectionDialogResult = {
  inspector: string;
  inspectionHashId: string;
  inspectorHashId: string;
};

/**
 * 点検開始ダイアログコンポーネント。
 *
 * @param props コンポーネントのプロパティ
 * @param props.open ダイアログが開いているかどうかを示すブール値。
 * @param props.defaultInspectorHashId 検査員のハッシュIDを指定する文字列。デフォルトの検査員を選択するために使用される。
 * @param props.inspectionResult 点検結果のデータを含むオブジェクト。点検結果の詳細情報を表示するために使用される。
 */
export const StartInspectionDialog: React.FC<StartInspectionDialogProps> = (props) => {
  const {open, defaultInspectorHashId, inspectionResult} = props;

  const [selectedInspection, setSelectedInspection] = useAtom(selectedInspectionAtom);
  const [selectedInspector, setSelectedInspector] = useAtom(selectedInspectorAtom);
  const [validationSchema] = useState(() =>
    yup.object({
      inspector: yup.string().required(),
    })
  );

  const handleSubmit = useCallback(async () => {
    props.actions.resolve({
      inspectionHashId: selectedInspection?.hashId,
      inspectorHashId: selectedInspector?.hashId,
    });
    // 選択状態をクリア
    setTimeout(() => {
      setSelectedInspection(undefined);
      setSelectedInspector(undefined);
    }, 300);
  }, [
    props.actions,
    selectedInspection?.hashId,
    selectedInspector?.hashId,
    setSelectedInspection,
    setSelectedInspector,
  ]);

  const handleClose = useCallback(() => {
    // 選択状態をクリア
    setTimeout(() => {
      setSelectedInspection(undefined);
      setSelectedInspector(undefined);
    }, 300);
    props.actions.reject();
  }, [props.actions, setSelectedInspection, setSelectedInspector]);

  return (
    <Suspense fallback={null}>
      <Formik
        initialValues={{inspector: defaultInspectorHashId, inspectionHashId: ''}}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        isInitialValid={true}>
        <Form>
          <StartInspectionForm
            open={Boolean(open)}
            onClose={handleClose}
            defaultUserHashId={defaultInspectorHashId}
            inspectionResult={inspectionResult}
          />
        </Form>
      </Formik>
    </Suspense>
  );
};

const useStyles = makeStyles({
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px 0px',
  },
  title: {
    color: '#172B4D',
  },
  content: {
    width: '100%',
    padding: '0px 0px',
    overflow: 'hidden',
  },
  fields: {
    '& > :not(:first-child)': {
      marginTop: '24px',
    },
  },
  inputDate: {
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  actions: {
    padding: '32px 16px',
  },
});
