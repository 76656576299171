import {Atom, PrimitiveAtom, useAtomValue} from 'jotai';
import {selectAtom, useReducerAtom} from 'jotai/utils';
import {useCallback, useMemo} from 'react';
import {atomWithLocation} from 'jotai-location';
import {isNullish} from '@front-libs/helpers';

export type WholeProductPlanListState = {
  isDisplayAnnounceBar?: boolean;
};

export const initialState: WholeProductPlanListState = {
  isDisplayAnnounceBar: false,
};

export const WholeProductListStateAtom = atomWithLocation<WholeProductPlanListState>({
  getLocation: () => {
    const searchParams = new URLSearchParams(window.location.search);
    const isDisplayAnnounceBar = searchParams.get('isDisplayAnnounceBar');

    return {
      isDisplayAnnounceBar: isDisplayAnnounceBar === 'true' ? true : undefined,
    };
  },
  applyLocation: (state, options) => {
    const newState: Partial<WholeProductPlanListState> = {};

    if (!isNullish(state.isDisplayAnnounceBar)) newState.isDisplayAnnounceBar = state.isDisplayAnnounceBar;

    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    const newSearchParams = new URLSearchParams(newState as any);

    if (options?.replace) {
      window.history.replaceState(null, '', `?${newSearchParams.toString()}`);
    } else {
      window.history.pushState(null, '', `?${newSearchParams.toString()}`);
    }
  },
}) as unknown as PrimitiveAtom<WholeProductPlanListState>;

// ActionCreator
export const createChangeIsDisplayAnnounceBarAction = (isDisplayAnnounceBar: boolean) => ({
  type: 'CHANGE_IS_DISPLAY_ANNOUNCE_BAR' as const,
  isDisplayAnnounceBar,
});

// Action
export type Actions = ReturnType<typeof createChangeIsDisplayAnnounceBarAction>;

// Reducer
const WholeProductListStateReducer = (prev: WholeProductPlanListState, action: Actions): WholeProductPlanListState => {
  switch (action.type) {
    case 'CHANGE_IS_DISPLAY_ANNOUNCE_BAR':
      return {...prev, isDisplayAnnounceBar: action.isDisplayAnnounceBar};
    default:
      console.warn('undefined action is dispatched', action);
      return prev;
  }
};

// helper
export const useWholeProductListState = () => useReducerAtom(WholeProductListStateAtom, WholeProductListStateReducer);

const useMemoizedDispatch = () => {
  const [, dispatch] = useWholeProductListState();
  return useMemo(() => dispatch, [dispatch]);
};

const useSelectState = <T, U = T>(valueAtom: Atom<T>, actionCreator: (value: U) => Actions) => {
  const value = useAtomValue(valueAtom);
  const dispatch = useMemoizedDispatch();
  const update = useCallback((x: U) => dispatch(actionCreator(x)), [actionCreator, dispatch]);

  return [value, update] as const;
};

// hooks
const _isDisplayAnnounceBarAtom = selectAtom(WholeProductListStateAtom, (state) => state.isDisplayAnnounceBar);
export const useIsDisplayAnnounceBar = () =>
  useSelectState(_isDisplayAnnounceBarAtom, (isDisplayAnnounceBar: boolean) =>
    createChangeIsDisplayAnnounceBarAction(isDisplayAnnounceBar)
  );
