import {Box, Grid, makeStyles, styled} from '@material-ui/core';
import React, {memo, useCallback, useMemo} from 'react';
import {InspectionItem} from '@modules/inspections/api';
import {ItemTitle} from './ItemTitle';
import {ItemDescription} from './ItemDescription';
import {ItemInspectionPoint} from './ItemInspectionPoint';
import {FastField, FieldInputProps, FieldMetaProps, FormikProps, getIn} from 'formik';
import {
  EMPTY_REQUIRED_INSPECTION_ITEM_VALUE_ERROR,
  FormValue,
  INSPECTION_NUMERIC_ITEM_VALIDATION_ERROR,
  INSPECTION_SELECT_ITEM_VALIDATION_ERROR,
} from '../../../pc/common/types';
import {
  CheckboxField,
  DateField,
  InvalidValueError,
  MultiLineTextField,
  NumericField,
  PullDownField,
  RadioField,
  SingleLineTextField,
  TimeField,
} from '@components/molecules/InspectionTableFormItems';
import {FormError} from '../../../pc/EditInspectionResult/Item';
import _ from 'lodash';
import clsx from 'clsx';

export const Item = memo((props: ItemInnerProps) => {
  const id = props.item.id;

  return (
    <FastField name={`items[${id}]`}>
      {({field, form, meta}: ItemFieldProps) => <ItemInner {...props} field={field} meta={meta} form={form} />}
    </FastField>
  );
});

const ItemInner = (props: ItemInnerProps & ItemFieldProps) => {
  const {item, form, field, meta} = props;
  const classes = useStyles();
  const onChange = useCallback(
    (key: string, value: string | {[key: string]: boolean}) => {
      form.setFieldValue(`${field.name}.${key}`, value);
      form.setFieldTouched(field.name, true, false);
    },
    [field.name, form]
  );

  const value = useMemo(() => {
    switch (item.type) {
      case 'select':
      case 'numeric':
      case 'text':
      case 'multiline-text':
      case 'date':
      case 'time':
        return getIn(field.value, 'value');
      // multiple values
      case 'multi-select':
        return getIn(field.value, 'values');
      case null:
        return null;
    }
  }, [item.type, field.value]);

  const isRequiredError = useMemo(() => {
    if (item.type === null) {
      return false;
    }
    const rawError = item.type !== 'multi-select' ? getIn(meta.error, 'value') : getIn(meta.error, 'values');
    return rawError === EMPTY_REQUIRED_INSPECTION_ITEM_VALUE_ERROR;
  }, [item.type, meta.error]);

  const error = useMemo(() => {
    if (item.type === null || !meta.touched) {
      return null;
    }
    // EMPTY_REQUIRED_INSPECTION_ITEM_VALUE_ERRORの表現は、左のボーダーをつける形になるのでnullを返す
    if (isRequiredError) return null;

    const rawError = item.type !== 'multi-select' ? getIn(meta.error, 'value') : getIn(meta.error, 'values');

    switch (item.type) {
      case 'select':
      case 'multi-select':
        return rawError === INSPECTION_SELECT_ITEM_VALIDATION_ERROR ? (
          <InvalidValueError />
        ) : (
          <FormError error={rawError} />
        );

      case 'numeric': {
        if (_.isString(rawError) && rawError !== EMPTY_REQUIRED_INSPECTION_ITEM_VALUE_ERROR) {
          try {
            const {error: errorCode, customError} = JSON.parse(rawError);
            if (errorCode === INSPECTION_NUMERIC_ITEM_VALIDATION_ERROR) {
              const validationErrorText = (customError ?? '').length > 0 ? customError : undefined;

              return <NumericErrorGrid>{<InvalidValueError text={validationErrorText} />}</NumericErrorGrid>;
            }
          } catch (_e) {
            // empty
          }
        }
        return <FormError error={rawError} />;
      }

      case 'text':
      case 'multiline-text':
      case 'date':
      case 'time':
        return <FormError error={rawError} />;
    }
  }, [item.type, meta.touched, meta.error, isRequiredError]);

  if (item.type === null || item.type === 'section' || item.type === 'bool') {
    // should not reach here
    return null;
  }

  return (
    <RootBox className={isRequiredError ? clsx(classes.root, classes.requiredError) : classes.root}>
      <ItemTitle item={item} />
      {item.inspectionPoint && <ItemInspectionPoint inspectionPoint={item.inspectionPoint} />}
      {item.description && <ItemDescription description={item.description} />}
      {(() => {
        switch (item.type) {
          case 'select':
            if (item.view === 'radio') {
              return (
                <RadioField
                  options={item.options}
                  isEditable={true}
                  value={value}
                  error={error}
                  onChange={(v) => onChange('value', v)}
                />
              );
            } else if (item.view === 'pull-down') {
              return (
                <PullDownField
                  options={item.options}
                  isEditable={true}
                  value={value}
                  error={error}
                  onChange={(v) => onChange('value', v)}
                />
              );
            }
            break;
          case 'multi-select':
            return (
              <CheckboxField
                options={item.options}
                isEditable={true}
                values={value}
                error={error}
                onChange={(v) => onChange('values', v)}
              />
            );
          case 'numeric':
            return (
              <NumericField
                unit={item.unit}
                isEditable={true}
                showsError={item.required}
                value={value}
                error={error}
                onChange={(v) => onChange('value', v)}
                validator={item.validator ?? null}
              />
            );
          case 'text':
            return (
              <SingleLineTextField
                placeholder="回答を入力"
                isEditable={true}
                showsError={item.required}
                value={value}
                error={error}
                onChange={(v) => onChange('value', v)}
              />
            );
          case 'multiline-text':
            return (
              <MultiLineTextField
                placeholder="回答を入力"
                isEditable={true}
                showsError={item.required}
                value={value}
                error={error}
                onChange={(v) => onChange('value', v)}
              />
            );
          case 'date':
            return (
              <DateField
                isEditable={true}
                showsError={item.required}
                value={value}
                error={error}
                onChange={(v) => onChange('value', v)}
              />
            );
          case 'time':
            return (
              <TimeField
                isEditable={true}
                showsError={item.required}
                value={value}
                error={error}
                onChange={(v) => onChange('value', v)}
              />
            );
          default:
            return null;
        }
      })()}
    </RootBox>
  );
};

type ItemInnerProps = {
  item: InspectionItem & {id: string};
};

export type ItemFieldProps = {
  field: FieldInputProps<FormValue['items'][string]>;
  meta: FieldMetaProps<FormValue['items'][string]>;
  form: FormikProps<FormValue['items'][string]>;
};

const RootBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '14px',
    marginRight: '12px',
  },
  requiredError: {
    borderLeft: '2px solid #2A96E8',
    paddingLeft: '12px',
  },
}));

const NumericErrorGrid = styled(Grid)({
  height: '32px',
});
