import React, {useMemo, useCallback} from 'react';
import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  TextField,
  FormControlLabel,
  Typography,
  FormHelperText,
  IconButton,
} from '@material-ui/core';
import {Clear} from '@material-ui/icons';
import {PullDownField as PullDownFieldType, SelectorOption} from '@Apps/Inspection/types';
import {FormikErrors} from 'formik';
import Select from 'react-select';
import {theme} from '@atoms/theme';
import {FormError} from '../FormError';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    optionsContainer: {
      margin: '0px 16px',
    },
    inputOptionContainer: {
      height: '62px',
    },
    radioForm: {
      display: 'flex',
      alignItems: 'center',
      '&:not(:first-child)': {
        marginTop: '8px',
      },
    },
    option: {
      display: 'flex',
      alignItems: 'center',
    },
    radioLabel: {
      marginLeft: '24px',
    },
    clearIcon: {
      padding: '0px',
    },
    optionError: {
      marginTop: '0px',
      marginLeft: '28px',
    },
    newOption: {
      marginLeft: '6px',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    newOptionIndex: {
      color: 'rgba(0, 0, 0, 0.38)',
    },
    newOptionLabel: {
      color: '#7A869A',
      marginLeft: '24px',
    },
    viewSelector: {
      width: '200px',
    },
    summaryErrorContainer: {
      marginTop: '12px',
    },
  })
);

// PullDown
type PullDownFieldProps = {
  field: PullDownFieldType;
  errors: FormikErrors<PullDownFieldType> & {_options?: string};
  focused: boolean;
  placeholder: string;
  onChangeField: (fieldName: string | '', value: unknown, validate: boolean) => void;
};

export const PullDownField: React.FC<PullDownFieldProps> = (props) => {
  const {field, errors, focused, placeholder, onChangeField} = props;
  const classes = useStyles();

  const handleChangeOptionLabel = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, index: number) => {
      if (field.options[index]) {
        onChangeField(
          `options[${index}]`,
          {
            label: e.target.value,
            value: e.target.value,
          },
          true
        );
      }
    },
    [field.options, onChangeField]
  );

  const handleDeleteOption = useCallback(
    (e: React.MouseEvent, index: number) => {
      const newField = {
        ...field,
        options: [...field.options],
      };

      const deletedOption = newField.options[index];
      newField.options.splice(index, 1);

      if (!!newField?.validators?.values && deletedOption.value === field.validators.values[0]) {
        newField.validators.values = [];
      }

      onChangeField('', newField, true);
    },
    [field, onChangeField]
  );

  const handleClickNewOption = useCallback(
    (e: React.MouseEvent) => {
      const newIndex = field.options.length;
      onChangeField(
        `options[${newIndex}]`,
        {
          label: '',
          value: '',
        },
        false
      );
    },
    [field.options.length, onChangeField]
  );

  const handleChangeValidator = useCallback(
    (e: SelectorOption | null) => {
      if (!e) return;
      onChangeField(
        `validators`,
        {
          values: [e.value],
        },
        true
      );
    },
    [onChangeField]
  );

  const {options, validators} = field;

  const validatorValue = useMemo(
    () => field.options.find((o) => o.value === validators.values[0]) ?? null,
    [field.options, validators.values]
  );

  const optionInputErrors = useMemo(() => {
    return field.options
      .map(({label}, index) => (label === '' ? `選択肢${index + 1}が入力されていません` : null))
      .filter((msg): msg is string => msg !== null);
  }, [field.options]);

  return focused === true ? (
    <Grid container direction="column">
      <Grid container className={classes.optionsContainer} direction="column">
        {options.map(({label}, index) => {
          return (
            <Grid key={index} container direction="column" className={classes.inputOptionContainer}>
              <Grid container className={classes.option}>
                <FormControlLabel
                  key={index}
                  className={classes.radioForm}
                  control={<Typography>{`${index + 1}.`}</Typography>}
                  label={
                    <TextField
                      className={classes.radioLabel}
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      value={label}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeOptionLabel(e, index)}
                    />
                  }
                />
                <IconButton className={classes.clearIcon} onClick={(e) => handleDeleteOption(e, index)}>
                  <Clear />
                </IconButton>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
      <FormControlLabel
        className={classes.newOption}
        onClick={handleClickNewOption}
        control={<Typography className={classes.newOptionIndex}>{`${options.length + 1}.`}</Typography>}
        label={
          <Typography className={classes.newOptionLabel} variant="inherit">
            {placeholder}
          </Typography>
        }
      />
      {(optionInputErrors.length > 0 || errors._options) && (
        <Grid container direction="column" className={classes.summaryErrorContainer}>
          {optionInputErrors.map((message, index) => (
            <FormHelperText key={index} error={true}>
              {message}
            </FormHelperText>
          ))}
          <FormError error={errors._options} />
        </Grid>
      )}
      {field.settings.showsValidator && (
        <Grid container style={{marginLeft: '24px', marginTop: '16px'}}>
          <Grid item container alignItems="center">
            <Grid>エラー値の設定：次の値を含む</Grid>
            <Grid item style={{marginLeft: '16px', width: '150px'}}>
              <Select
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (provided) => ({...provided, zIndex: theme.zIndex.modal + 1}),
                }}
                name="validator.values[0]"
                value={validatorValue}
                options={field.options.filter((o) => !!o.label)}
                onChange={handleChangeValidator}
              />
            </Grid>
          </Grid>
          <FormError error={errors?.validators?.values as string | undefined} />
        </Grid>
      )}
    </Grid>
  ) : (
    <Grid container direction="column">
      <Select className={classes.viewSelector} native>
        <option aria-label="None" value="" />
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
      {(optionInputErrors.length > 0 || errors._options || errors?.validators?.values) && (
        <Grid container direction="column" className={classes.summaryErrorContainer}>
          {optionInputErrors.map((message, index) => (
            <FormHelperText key={index} error={true}>
              {message}
            </FormHelperText>
          ))}
          <FormError error={errors._options} />
          <FormError error={errors?.validators?.values as string | undefined} />
        </Grid>
      )}
    </Grid>
  );
};
