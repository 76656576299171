import React, {useCallback} from 'react';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import DateRangeIcon from '@mui/icons-material/DateRange';
import pmdaNotification from '@assets/pmda_notification.svg';
import {TimeLineTab} from '@organisms/MobileTimeline';
import {ProductFileTab} from '@organisms/ProductFile';
import {InspectionAndRepairTab} from './InspectionAndRepairTab';
import {HospitalProductIndex} from '@modules/hospital_products/types';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {InnerLoading} from '@molecules/Loading';
import {PmdaFileTab} from '@organisms/PmdaFile';
import {
  AppendixHeaderRoot,
  BtnLabelTypography,
  SelectedBtnLabelTypography,
  IconWrapper,
  SectionRootContainer,
  StyledButton,
  StyledIconButton,
  SelectedTabButton,
} from '@Apps/ProductDetail/styled';
import {useParams} from 'react-router-dom';
import {useFetchHospitalProductQuery} from '@modules/hospital_products/api';
import {useMediaQuery} from '@material-ui/core';
import {APPENDIX_WIDTH_BREAK_POINT} from '@Apps/ProductDetail/styled';
import {CSSObject} from '@mui/material';

const IconStyle: CSSObject = {width: '16px', height: '16px', margin: '0px 4px'};

type Props = {
  hospitalProduct: HospitalProductIndex;
};

type ProductRightSideNavHeaderProps = {
  handleTabChange: (newValue: string) => void;
  selectedTab: string;
};

/**
 * 機器詳細の補足情報
 * 構成要素：タイムライン/ファイル/添付文書/点検・修理予定
 */
export const ProductAppendix = ({hospitalProduct}: Props) => {
  const [tab, setTab] = React.useState('timeline');
  const {hashId} = useParams();
  const {myInfo} = useMyInfo();

  const {data, isLoading: isLoadingHospitalProduct} = useFetchHospitalProductQuery(myInfo.hospitalHashId, hashId ?? '');

  const handleTabChange = useCallback((newValue: string) => {
    setTab(newValue);
  }, []);

  if (isLoadingHospitalProduct || !data) {
    return <InnerLoading />;
  }

  return (
    <SectionRootContainer>
      <ProductAppendixHeader handleTabChange={handleTabChange} selectedTab={tab} />
      {tab === 'timeline' && <TimeLineTab hospitalProductDetail={data} />}
      {tab === 'file' && <ProductFileTab hospitalProduct={hospitalProduct} />}
      {tab === 'pmdaFile' && <PmdaFileTab hospitalProduct={hospitalProduct} />}
      {tab === 'inspectionAndRepair' && <InspectionAndRepairTab hospitalProduct={hospitalProduct} />}
    </SectionRootContainer>
  );
};

const ProductAppendixHeader = ({handleTabChange, selectedTab}: ProductRightSideNavHeaderProps) => {
  const isTablet = useMediaQuery(`(max-width:${APPENDIX_WIDTH_BREAK_POINT})`);
  const tabItems = [
    {
      label: 'timeline',
      text: 'タイムライン',
      icon: <AccessTimeIcon sx={IconStyle} />,
    },
    {
      label: 'file',
      text: 'ファイル',
      icon: <FolderOutlinedIcon sx={IconStyle} />,
    },
    {
      label: 'pmdaFile',
      text: '添付文書',
      icon: (
        <IconWrapper>
          <img
            src={pmdaNotification}
            alt="pmda_notification"
            style={
              selectedTab === 'pmdaFile'
                ? {
                    width: '16px',
                    filter: 'invert(10%) sepia(98%) saturate(7278%) hue-rotate(210deg) brightness(89%) contrast(102%)',
                  }
                : {width: '16px'}
            }
          />
        </IconWrapper>
      ),
    },
    {
      label: 'inspectionAndRepair',
      text: '点検・修理予定',
      icon: <DateRangeIcon sx={IconStyle} />,
    },
  ];

  return (
    <AppendixHeaderRoot>
      {tabItems.map((item, index) =>
        selectedTab === item.label ? (
          <SelectedTabButton
            disableElevation
            variant="contained"
            onClick={() => handleTabChange(item.label)}
            key={`tab-button-${index}`}>
            {item.icon}
            <SelectedBtnLabelTypography>{item.text}</SelectedBtnLabelTypography>
          </SelectedTabButton>
        ) : isTablet ? (
          <StyledIconButton
            disableElevation
            variant="contained"
            onClick={() => handleTabChange(item.label)}
            key={`tab-button-${index}`}>
            {item.icon}
          </StyledIconButton>
        ) : (
          <StyledButton
            disableElevation
            variant="contained"
            onClick={() => handleTabChange(item.label)}
            key={`tab-button-${index}`}>
            {item.icon}
            <BtnLabelTypography>{item.text}</BtnLabelTypography>
          </StyledButton>
        )
      )}
    </AppendixHeaderRoot>
  );
};
