import React, {useCallback, useMemo} from 'react';
import {FolderOpen, ShowChart, Apps} from '@material-ui/icons';
import {useNavigate} from 'react-router-dom';
import {MenuItemType, PopperMenuButton} from '@molecules/Buttons/PopperMenuButton';
import {AppMenu} from './AppMenu';
import {makeStyles, createStyles} from '@material-ui/core';
import {HospitalUserPermission} from '@modules/auth/consts';
import {useUserPermissions} from '@modules/auth/hooks';

const menus = [
  {icon: <FolderOpen />, label: 'ファイル一覧', value: 'fileList', permissions: []},
  {
    icon: <ShowChart />,
    label: '経営ダッシュボード',
    value: 'managementDashboard',
    permissions: [HospitalUserPermission.ReadManagementDashboard],
  },
  {icon: <Apps />, label: '病棟ユーザーメニュー', value: 'wardMenus', permissions: []},
] as const;

type MenuKeys = (typeof menus)[number]['value'];

const menuLinkMap: Record<MenuKeys, string> = {
  fileList: '/general_menu/file_list',
  managementDashboard: '/general_menu/management_dashboard',
  wardMenus: '/shared/reception_menu',
};

export const GeneralMenu: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {isPermitted} = useUserPermissions();

  const handleMenuClick = useCallback(
    (item: MenuItemType) => {
      const key = item.value as MenuKeys;
      const link = menuLinkMap[key];
      navigate(link);
    },
    [navigate]
  );

  const menuItemList = useMemo(
    () =>
      menus
        .filter((item) => item.permissions.length === 0 || isPermitted(item.permissions as unknown as string[]))
        .map((item) => ({
          label: (
            <>
              {item.icon}
              <span style={{paddingLeft: 8}}>{item.label}</span>
            </>
          ),
          value: item.value,
        })),
    [isPermitted]
  );

  return (
    <PopperMenuButton
      menuItemList={menuItemList}
      onMenuClick={handleMenuClick}
      hiddenArrow
      buttonProps={{variant: 'text', size: 'small', disableElevation: true, className: classes.generalMenuButton}}>
      <AppMenu />
    </PopperMenuButton>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    generalMenuButton: {
      color: '#46484B',
      '&:hover': {
        backgroundColor: 'transparent',
      },
      minWidth: '24px',
    },
  })
);
