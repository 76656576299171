import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  Grid,
  Typography,
  IconButton,
  CSSObject,
  TextField,
  Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {useFormikContext} from 'formik';
import Selector from '@molecules/Formik/fields/Selector';
import {RequiredLabel} from '@molecules/FormRequiredLabel';
import {CompanyIndex} from '@modules/companies/types';
import {FetchCompaniesParam, useFetchCompaniesQuery} from '@modules/companies/api';
import {useDescendantCategoryQuery} from '@modules/categories/api/categoryApi';
import {useCategoryQuery} from '@modules/categories/hooks/useCategories';
import {CategoryIndex} from '@modules/categories/types';
import CreatableSelector from '@components/molecules/Formik/fields/CreatableSelector';
import {ValueType} from 'react-select';
import {CategoryFormatter} from '@modules/categories/helpers';
import {formatApprovalNumber, formatJANCode} from '@modules/hospital_products/utls';
import {classNameOpts} from '@Apps/ProductRegistration/constants';
import {useDebounceCallback} from '@front-libs/core';
import {StrUtil} from '@front-libs/helpers';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const dialogTitleStyle: CSSObject = {
  '& h2': {
    color: '#172B4D',
    fontSize: '20px',
    fontWeight: 'bold',
  },
};

type WholeProductEditDialogBodyProps = {
  open: boolean;
  onClose: React.MouseEventHandler;
  title: string;
  content?: string | React.ReactNode;
  recommendMakerHashId?: string;
  recommendMakerName?: string;
  alertContent?: boolean;
  paperStyle?: React.CSSProperties;
};

export const WholeProductEditDialogBody = ({
  open,
  onClose,
  title,
  content,
  recommendMakerHashId,
  recommendMakerName,
  alertContent,
  paperStyle,
}: WholeProductEditDialogBodyProps) => {
  const {myInfo} = useMyInfo();
  const {isSubmitting, submitForm, isValid} = useFormikContext();
  const handleSubmit = useCallback(async () => {
    try {
      await submitForm();
    } catch (e) {
      console.error(e);
    }
  }, [submitForm]);
  const [fetchCompaniesQueryParam, setFetchCompaniesQueryParam] = useState<FetchCompaniesParam>({});
  const {data: companies} = useFetchCompaniesQuery(myInfo.hospitalHashId, fetchCompaniesQueryParam);
  const {rootCategoryQuery} = useCategoryQuery();

  const [selectedRootCategory, setSelectedRootCategory] = useState<string | null>(null);
  const descendantCategory = useDescendantCategoryQuery(selectedRootCategory);

  const handleSearchCompany = useDebounceCallback(
    (name: string) => {
      if (name === '') {
        setFetchCompaniesQueryParam({});
      } else {
        setFetchCompaniesQueryParam({
          ...fetchCompaniesQueryParam,
          name: name,
        });
      }
    },
    100,
    [fetchCompaniesQueryParam]
  );

  useEffect(() => {
    if (recommendMakerHashId && recommendMakerName) {
      setFetchCompaniesQueryParam({
        name: recommendMakerName,
      });
    }
  }, [recommendMakerHashId, recommendMakerName]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={true}
      PaperProps={{style: {minWidth: '650px', ...paperStyle}}}
      aria-labelledby="form-dialog-title">
      <DialogTitle sx={{padding: '24px 32px 24px'}}>
        <Grid container justifyContent="space-between" alignItems="center" sx={dialogTitleStyle}>
          <Typography variant="h2">{title}</Typography>
          <IconButton onClick={onClose} sx={{padding: '0px'}}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <Grid sx={{padding: '0 32px'}}>
        {alertContent && content ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              marginTop: '32px',
              backgroundColor: '#FF505F14',
              padding: '16px 0 16px 16px',
            }}>
            <InfoOutlinedIcon color="error" fontSize="small" sx={{marginRight: '8px'}} />
            <Typography sx={{color: '#C7243A', fontSize: '14px'}}>
              {typeof content === 'string' ? StrUtil.nl2br(content) : content}
            </Typography>
          </Box>
        ) : (
          content && (
            <Grid sx={{padding: '32px 0 0', fontSize: '14px'}}>
              {typeof content === 'string' ? StrUtil.nl2br(content) : content}
            </Grid>
          )
        )}
        <Grid sx={{padding: '32px 0 0'}}>
          <EditForm
            rootCategories={rootCategoryQuery.data ?? []}
            narrowCategories={descendantCategory.data ?? []}
            setSelectedRootCategory={setSelectedRootCategory}
            companies={companies?.data ?? []}
            onSearchCompany={handleSearchCompany}
            newMakerName={recommendMakerHashId ? undefined : recommendMakerName}
          />
        </Grid>
      </Grid>
      <DialogActions sx={{margin: '24px'}}>
        <Button
          disabled={!isSubmitting && !isValid}
          variant={'contained'}
          color="primary"
          onClick={handleSubmit}
          sx={{backgroundColor: '#0052CC'}}>
          機種を確定して次へ
        </Button>
        <Button onClick={onClose} sx={{color: '#0052CC'}}>
          閉じる
        </Button>
      </DialogActions>
    </Dialog>
  );
};

type EditFormProps = {
  rootCategories: CategoryIndex[];
  narrowCategories: CategoryIndex[];
  setSelectedRootCategory: React.Dispatch<React.SetStateAction<string | null>>;
  companies: CompanyIndex[];
  newMakerName?: string;
  onSearchCompany: (name: string) => void;
};

type EditFormValuesType = {
  rootCategoryHashId: string | null;
  displayName: string | null;
  name: string | null;
  approvalNumber: string | null;
  jmdnCode: number | null;
  janCode: string | null;
  isSpecificMaintain: boolean | null;
  className: string | null;
  catalogPrice: number | null;
};

const EditForm = ({
  rootCategories,
  narrowCategories,
  setSelectedRootCategory,
  companies,
  newMakerName,
  onSearchCompany,
}: EditFormProps) => {
  const {values, handleChange, setFieldValue} = useFormikContext<EditFormValuesType>();
  const [tmpMakerName, setTmpMakerName] = useState<string | undefined>(newMakerName);

  useEffect(() => {
    if (values.rootCategoryHashId) {
      setSelectedRootCategory(values.rootCategoryHashId);
    }
  }, [values.rootCategoryHashId, setSelectedRootCategory]);

  const companyOpts = useMemo(() => {
    const options = companies.map((c) => ({
      label: c.name,
      value: c.hashId,
      isDisabled: false,
    }));
    if (tmpMakerName) {
      options.push({
        label: tmpMakerName,
        value: tmpMakerName,
        isDisabled: true,
      });
    }
    return options;
  }, [companies, tmpMakerName]);

  useEffect(() => {
    if (newMakerName) {
      setFieldValue('newMakerName', newMakerName);
      setFieldValue('makerHashId', newMakerName);
    }
  }, [newMakerName, setFieldValue]);

  const rootCategoriesOpts = useMemo(() => {
    return CategoryFormatter.getOptions(rootCategories);
  }, [rootCategories]);
  const subCategoriesOpts = useMemo(() => {
    return CategoryFormatter.getOptions(narrowCategories);
  }, [narrowCategories]);

  const onInputChange = (name: string) => {
    onSearchCompany(name);
    setTmpMakerName(undefined);
  };

  return (
    <Grid
      container
      direction="column"
      sx={{
        width: '100%',
        marginBottom: '16px',
        '& > :not(:first-of-type)': {
          marginTop: '16px',
        },
      }}>
      <Grid item>
        <RequiredLabel>大分類</RequiredLabel>
        <Selector
          name="rootCategoryHashId"
          size={'small'}
          options={rootCategoriesOpts}
          onChange={(val: ValueType<{label: string; value: string}, true>) => {
            setFieldValue('rootCategoryHashId', val);
            setFieldValue('narrowCategoryHashId', null);
          }}
        />
      </Grid>
      <Grid item>
        <RequiredLabel>小分類</RequiredLabel>
        <Selector
          name="narrowCategoryHashId"
          size={'small'}
          options={subCategoriesOpts}
          onChange={(val: ValueType<{label: string; value: string}, true>) => {
            setFieldValue('narrowCategoryHashId', val);
          }}
        />
      </Grid>
      <Grid item>
        <RequiredLabel>機種名</RequiredLabel>
        <TextField
          fullWidth
          id="displayName"
          name="displayName"
          type="text"
          variant="outlined"
          size="small"
          value={values.displayName || ''}
          onChange={handleChange}
        />
      </Grid>
      <Grid item>
        <RequiredLabel>型式</RequiredLabel>
        <TextField
          fullWidth
          id="name"
          name="name"
          type="text"
          variant="outlined"
          size="small"
          value={values.name || ''}
          onChange={handleChange}
        />
      </Grid>
      <Grid item>
        <label>メーカー名</label>
        <CreatableSelector
          placeholder="テルモ"
          name="makerHashId"
          size="small"
          options={companyOpts}
          onChange={(val: ValueType<{label: string; value: string}, false>, isNew: boolean) => {
            if (isNew) {
              setFieldValue('newMakerName', val?.value);
              setFieldValue('makerHashId', null);
            } else {
              setFieldValue('newMakerName', null);
              setFieldValue('makerHashId', val);
            }
          }}
          onInputChange={onInputChange}
          filterOption={(option: {label: string; value: string; data: {isDisabled: boolean}}, _inputValue: string) =>
            !option.data.isDisabled
          }
        />
      </Grid>
      <Grid item>
        <label>承認番号</label>
        <TextField
          fullWidth
          id="approvalNumber"
          name="approvalNumber"
          type="text"
          variant="outlined"
          size="small"
          value={values.approvalNumber || ''}
          onBlur={() => {
            if (values.approvalNumber) {
              setFieldValue('approvalNumber', formatApprovalNumber(values.approvalNumber));
            }
          }}
          onChange={handleChange}
        />
      </Grid>
      <Grid item>
        <label>JMDNコード</label>
        <TextField
          fullWidth
          id="jmdnCode"
          name="jmdnCode"
          type="text"
          variant="outlined"
          size="small"
          value={values.jmdnCode || ''}
          onChange={handleChange}
          onBlur={() => {
            if (values.jmdnCode) {
              setFieldValue('jmdnCode', values.jmdnCode);
            }
          }}
        />
      </Grid>
      <Grid item>
        <label>JANコード</label>
        <TextField
          fullWidth
          id="janCode"
          name="janCode"
          type="text"
          variant="outlined"
          size="small"
          value={values.janCode || ''}
          onChange={handleChange}
          onBlur={() => {
            if (values.janCode) {
              setFieldValue('janCode', formatJANCode(values.janCode));
            }
          }}
        />
      </Grid>
      <Grid item>
        <label>特定保守製品</label>
        <Selector
          name="isSpecificMaintain"
          size="small"
          options={[
            {label: '該当', value: true},
            {label: '非該当', value: false},
          ]}
          placeholder="選択…"
          onChange={(val: ValueType<{label: string; value: boolean}, true>) => {
            setFieldValue('isSpecificMaintain', val);
          }}
        />
      </Grid>
      <Grid item>
        <label>クラス分類</label>
        <Selector
          name="className"
          size="small"
          options={classNameOpts}
          placeholder="選択…"
          onChange={(val: ValueType<{label: string; value: string}, true>) => {
            setFieldValue('className', val);
          }}
        />
      </Grid>
      <Grid item>
        <label>定価</label>
        <TextField
          fullWidth
          id="catalogPrice"
          name="catalogPrice"
          type="number"
          variant="outlined"
          size="small"
          value={values.catalogPrice || ''}
          onChange={handleChange}
          onBlur={() => {
            if (values.catalogPrice) {
              setFieldValue('catalogPrice', values.catalogPrice);
            }
          }}
        />
      </Grid>
    </Grid>
  );
};
