import React from 'react';
import {useCallback, useMemo, useState} from 'react';
import ExcelJs from 'exceljs';
import {BulkUpdateProductListElement, BulkUpdateProductListParam} from './types';
import {isNullish} from '@front-libs/helpers';
import {openSnackBar} from '@molecules/SnackBar';
import {BULK_UPDATE_READ_SHEET_NAME} from './constants';
import {AlertDialog} from '@molecules/Dialogs/AlertDialog';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {uploadFile as uploadFileApi} from '@modules/files/api';
import {requestBulkUpdateTask} from '@modules/hospital_products/api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {
  WaysOfPurchaseMap,
  baseUnitMap,
  formatDate,
  isMaintenanceContractMap,
  permanentlyAssignedMap,
  purchasedNationalExpenseMap,
  taxIncludedMap,
} from '../ProductImport/hooks';
import {validateAndBind} from './validation';
import {useHospitalProductNoteSettings} from '@modules/hospital_products/hooks/useHospitalProductNoteSettings';

const hospitalProductsPerPage = 20;

export const useBulkUpdateHospitalProductsExcel = () => {
  const wb = useMemo(() => new ExcelJs.Workbook(), []);
  const reader = useMemo(() => new FileReader(), []);
  const {myInfo} = useMyInfo();
  const {data: noteData, isLoading: isLoadingNoteSettings} = useHospitalProductNoteSettings(myInfo.hospitalHashId);

  const [hospitalProducts, setHospitalProducts] = useState<BulkUpdateProductListElement[]>([]);
  const [isValidFile, setIsValidFile] = useState(false);
  const [hospitalProductsPage, setHospitalProductsPage] = useState<number>(0);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);

  const reset = useCallback(() => {
    setHospitalProducts([]);
    setIsValidFile(false);
    setHospitalProductsPage(0);
    setUploadedFile(null);
  }, []);

  const uploadFile = useCallback(
    async (acceptedFile: File) => {
      reader.readAsArrayBuffer(acceptedFile);
      reader.onload = async () => {
        const buffer = reader.result;

        if (isNullish(buffer)) return;
        openSnackBar('アップロードされたファイルを読み込んでいます', 'left', 'bottom', 'info');

        const workbook = await wb.xlsx.load(buffer as ArrayBuffer);
        const dataSheet = workbook.getWorksheet(BULK_UPDATE_READ_SHEET_NAME);
        if (isNullish(dataSheet)) return;
        const {isValid, hospitalProductTableData, errorMessages} = await validateAndBind({
          dataSheet,
        });

        if (!isValid) {
          openSnackBar('アップロードされたファイルの形式が異なります。', 'left', 'bottom', 'error');
          await dialogHandler.open(AlertDialog, {
            title: 'アップロードされたファイルにエラーが含まれます',
            content: (
              <>
                <p>
                  アップロードされたファイルでは登録処理を開始できません。
                  <br />
                  下記の異常についてファイルを修正の上、再度アップロードしてください。
                </p>
                {errorMessages.slice(0, 15).map((message, index) => (
                  <span key={`message${index}`}>
                    {`・管理番号 ${message.managementId}：${message.message}`}
                    <br />
                  </span>
                ))}
                {errorMessages.length > 15 && <p>他{errorMessages.length - 15}件のエラーが検出されました。</p>}
              </>
            ),
            positiveButtonLabel: '閉じる',
            negativeButtonLabel: '',
          });

          return;
        }

        setHospitalProducts(hospitalProductTableData);
        setIsValidFile(true);
        setUploadedFile(acceptedFile);
        openSnackBar('ファイルの読み込みが完了しました。登録内容を確認してください。');
      };
    },
    [reader, wb]
  );

  const displayHospitalProducts = useMemo(() => {
    // ファイルがインプットされてない or WholeProductが確認されてないならReturn
    if (!isValidFile || hospitalProducts.length === 0) return [];

    // for 1-based index and ignore header row
    const startRowNumber = hospitalProductsPerPage * hospitalProductsPage;
    return hospitalProducts.slice(startRowNumber, startRowNumber + hospitalProductsPerPage);
  }, [hospitalProducts, hospitalProductsPage, isValidFile]);

  const submitHospitalProductData = useCallback(async () => {
    if (isNullish(uploadedFile)) return;
    const res = await uploadFileApi({
      file: uploadedFile,
      fileName: uploadedFile.name,
      category: 'hospital_product_bulk_update_file',
    });

    try {
      const updateHospitalProducts: BulkUpdateProductListParam[] = hospitalProducts.map((hospitalProduct) => {
        return {
          ...hospitalProduct,
          permanentlyAssigned: permanentlyAssignedMap[hospitalProduct.permanentlyAssigned],
          isBaseUnit: baseUnitMap[hospitalProduct.isBaseUnit],
          dateOfPurchase: formatDate(hospitalProduct.dateOfPurchase),
          waysOfPurchase: WaysOfPurchaseMap[hospitalProduct.waysOfPurchase],
          purchasedNationalExpense: purchasedNationalExpenseMap[hospitalProduct.purchasedNationalExpense],
          deliveryPrice: hospitalProduct.deliveryPrice === '' ? undefined : Number(hospitalProduct.deliveryPrice),
          taxIncluded: taxIncludedMap[hospitalProduct.taxIncluded],
          taxRate: hospitalProduct.taxRate === '' ? undefined : Number(hospitalProduct.taxRate),
          legalDurableYear:
            hospitalProduct.legalDurableYear === '' ? undefined : Number(hospitalProduct.legalDurableYear),
          dateOfDisposal: formatDate(hospitalProduct.dateOfDisposal),
          leaseFee: hospitalProduct.leaseFee === '' ? undefined : Number(hospitalProduct.leaseFee),
          leaseStartDate: formatDate(hospitalProduct.leaseStartDate),
          leaseDueDate: formatDate(hospitalProduct.leaseDueDate),
          leaseReturnDate: formatDate(hospitalProduct.leaseReturnDate),
          rentalFee: hospitalProduct.rentalFee === '' ? undefined : Number(hospitalProduct.rentalFee),
          rentalStartDate: formatDate(hospitalProduct.rentalStartDate),
          rentalDueDate: formatDate(hospitalProduct.rentalDueDate),
          rentalReturnDate: formatDate(hospitalProduct.rentalReturnDate),
          demonstrationStartDate: formatDate(hospitalProduct.demonstrationStartDate),
          demonstrationEndDate: formatDate(hospitalProduct.demonstrationEndDate),
          isMaintenanceContract: isMaintenanceContractMap[hospitalProduct.isMaintenanceContract],
        };
      });

      await requestBulkUpdateTask(myInfo.hospitalHashId, updateHospitalProducts, res.data.hashId);
      openSnackBar('機器台帳一括更新を開始します。', 'left', 'bottom');
      reset();
    } catch (e) {
      openSnackBar('機器台帳一括更新用に失敗しました。', 'left', 'bottom', 'error');
    }
  }, [hospitalProducts, myInfo.hospitalHashId, reset, uploadedFile]);

  return {
    uploadFile,
    hospitalProducts,
    displayHospitalProducts,
    hospitalProductsPage,
    isValidFile,
    setHospitalProductsPage,
    submitHospitalProductData,
    noteData,
    isLoadingNoteSettings,
  };
};
